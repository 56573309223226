
import { Form, Input, Row, Col, Space, Button, Typography } from 'antd';
import { runes } from 'runes2';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import theme from '../../config/theme/themeVariable';


const FoodQuantitySection = ({ quantityData = [], prices = [] }) => {
    const quantityOptions = []

    const firstPriceRow = prices ? [prices[0]] : [];
    const restPriceRow = prices ? prices.slice(1).map(({ id, price, ...rest }) => ({...rest, price: price.toString()}) ) : [];

    if(quantityData && quantityData.length !== 0){
        quantityData.forEach( (cuisine) => {
            quantityOptions.push({ label: cuisine.name, value: cuisine.name})
        })
    }

    // const quantityProps = {
    //     style: {
    //       height: '49px',
    //     },
    //     options: quantityOptions,
    //     onChange: (value) => {
    //         console.log(value)
    //     },
    //     placeholder: 'Quantity',
    // };

    const isNumber = (rule, value) => {
        console.log("isNumber",String((parseFloat(value)*100).toFixed()/100), value);
        return new Promise((resolve, reject) => {
          if (isNaN(value)) {
            reject('Please enter a valid price');
          } else if (parseFloat(value) < 0) {
            reject('Price cannot be negative!');
          } else if (parseFloat(value) > 9999) {
            reject('Please enter a valid price between 1 and 9999');
          } else if (parseFloat(value) < 1) {
            reject('Please enter a valid price between 1 and 9999');
          }else if (  String((parseFloat(value)*100).toFixed()/100) !== String(parseFloat(value)) ) {
            reject('Please enter a valid price with only 2 precision value');
          } else {
            resolve();
          }
        });
    };

    return(
        <>
            <Typography 
                className='font-normal' 
                variant="p" 
                style={{ 
                    fontFamily: 'OnestRegular', 
                    fontSize: '12px',
                    color: theme.textSecondaryColor,
                    fontStyle: 'italic',
                    marginBottom: '10px'
                }}
            >
                (Add available quantity and it's price. Ex: 500gm = 100 Rs.)
            </Typography>
            <Row>
                <Col className='w-8/12 pr-1.5'>
                    <Form.Item
                        name="dish_quantity"
                        initialValue={prices.length !== 0 ? firstPriceRow[0]?.quantity : []}
                        rules={[
                            {
                              required: true,
                              message: 'Please select quantity',
                            },
                        ]}
                    >
                        {/* <Select {...quantityProps} /> */}
                        <Input  
                            style={{ height: '48px'}}
                            count={{
                                show: true,
                                max: 20,
                                strategy: (txt) => runes(txt).length,
                                exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                            }}
                            placeholder='Quantity' />
                    </Form.Item> 
                </Col>
                <Col className='w-4/12 pr-1.5'>
                    <Form.Item
                        name="dish_price"
                        initialValue={prices.length !== 0 ? firstPriceRow[0]?.price : ''}
                        rules={[
                            {
                              required: true,
                              message: 'Price is required',
                            },
                            { validator: isNumber }
                        ]}
                    >
                        <Input 
                            className='update-profile-form-input' 
                            placeholder='Price' />
                    </Form.Item> 
                </Col>
            </Row> 

            <Form.List initialValue={prices.length !== 0 ? restPriceRow : []} name="quantity_price_additional_data">
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }) => (
                    <Space
                        key={key}
                        className='quantity_price__additional_input_container'
                        style={{
                            display: 'flex',
                            marginBottom: 8,
                        }}
                        align="baseline"
                    >
                        <Row className='w-full'>
                            <Col className='w-8/12 pr-1.5'>
                                <Form.Item
                                    name={[name, 'quantity']}
                                    rules={[
                                        {
                                          required: true,
                                          message: 'Please select quantity',
                                        },
                                    ]}
                                >
                                    {/* <Select {...quantityProps} /> */}
                                    <Input  
                                        style={{ height: '48px'}}
                                        count={{
                                            show: true,
                                            max: 20,
                                            strategy: (txt) => runes(txt).length,
                                            exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                                        }}
                                        placeholder='Quantity' />
                                </Form.Item> 
                            </Col>
                            <Col className='w-4/12 pr-1.5'>
                                <Form.Item
                                    name={[name, 'price']}
                                    rules={[
                                        {
                                          required: true,
                                          message: 'Price is required',
                                        },
                                        { validator: isNumber }
                                    ]}
                                >
                                    <Input 
                                        className='update-profile-form-input' 
                                        placeholder='Price' />
                                </Form.Item> 
                            </Col>
                        </Row> 
                        <Row className='w-full'>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                        </Row>
                    </Space>
                ))}
                <Form.Item>
                    <Button className='quantity_price__add_field_btn' type="default" onClick={() => add()} icon={<PlusOutlined />}>
                    Add More
                    </Button>
                </Form.Item>
                </>
            )}
            </Form.List>
        </>
    );
}
export default FoodQuantitySection;