import { Row, Col, Image } from 'antd';

import theme from '../../../config/theme/themeVariable';

const TopBannerSection = () => {
    return (
        <Row className='user-login-container w-full flex justify-center items-center'>
            <Col className='w-full h-full flex justify-center items-end '>
                <Image className="py-2.5" src={theme.chefLogoWhite} width={180} preview={false} />
            </Col>
        </Row>
    )
}

export default TopBannerSection;