import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card } from 'antd';
import { Typography, Rating } from '@mui/material';

import { getChefRestaurantId, getChefReviews, setInitialLoadedChefReviewsCount } from '../../redux/slices/profileSlice';
import layout from "../Layout/layout";
import theme from '../../config/theme/themeVariable';
import { LeftOutlined } from '@ant-design/icons';
import SubmitSpinner from '../../components/SubmitSpinner';


const Review = ({ review }) => {
    return(
        <>
            <Row className='flex flex-col w-full bg-gray-100 py-4 px-5 my-2' style={{ borderRadius: '5px'}}>
                <Col>
                    <Rating name="half-rating" readOnly defaultValue={0} value={review.rating} precision={0.5} />
                </Col>
                <Col>
                    <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}>
                        {review.review}
                    </Typography>  
                </Col>
                <Col>
                    <Typography variant='p' fontSize={14} fontFamily={'OnestRegular'} style={{ color: '#858585'}}>
                        {review.user_name}
                    </Typography> 
                </Col>
            </Row>
        </>
    );
}

const Reviews = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loading = useSelector(state => state.profile.loading);
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const initialLoadedChefReviewsCount = useSelector(state => state.profile.initialLoadedChefReviewsCount);
    const chefReviewsResponse = useSelector(state => state.profile.chefReviewsResponse);
    const chefReviews = useSelector(state => state.profile.chefReviews);

    useEffect(() => {
        dispatch(getChefRestaurantId());
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(restaurantId !== null){
            dispatch(getChefReviews(restaurantId));
        }
        // eslint-disable-next-line
    },[restaurantId, initialLoadedChefReviewsCount]);

    const handleGoBack = () => {
        navigate(-1);
    }

    const handleLoadMore = () => {
        dispatch(setInitialLoadedChefReviewsCount(1))
    }

    return (
        <>
            <Row className='w-full h-full sm:min-h-full sm:bg-neutral-100 flex justify-center py-2'>
                <Col className='bg-white w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5' style={{    minHeight: "100vh"}}>
                    <Row className='w-full justify-start items-center my-3.5 gap-x-3'>
                        <Col>
                        <Button 
                            icon={<LeftOutlined />} 
                            onClick={handleGoBack}
                            style={{ color: theme.textColor, boxShadow: 'rgb(0 0 0 / 12%) 1px 3px 10px', borderRadius: '14px', border: 'none' }}
                            size="large" />
                        </Col>
                        <Col>
                            <Typography className='font-bold' variant="h6" style={{ fontFamily: 'OnestRegular'}}>
                                Reviews
                            </Typography>
                        </Col>
                    </Row>

                    <Row className='w-full justify-start items-center my-3.5 gap-x-3'>
                        {
                            chefReviews && chefReviews.length !== 0 ? 
                                chefReviews.map(item => {
                                    return(
                                        <>
                                            <Review key={item.id} review={item} />
                                        </>
                                    )
                                })
                            :
                                (<>No Reviews Found...</>)
                        }

                        {
                            ( chefReviews.length > 0 && chefReviews.length < chefReviewsResponse?.total_records ) ? 
                                (
                                    <>
                                        <Col className='w-full py-2'>
                                            <Card
                                                title=""
                                                onClick={handleLoadMore}
                                                style={{
                                                    textAlign: 'center',
                                                    boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                { loading ? <SubmitSpinner /> : <p>Load More</p>}
                                                
                                            </Card>
                                        </Col>
                                    </>
                                )
                            :
                                (<></>)
                        }


                        {/* <Review />
                        <Review /> */}

                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default layout(Reviews);