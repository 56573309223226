import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Button, Input, Card } from 'antd';
import { Typography, Rating } from '@mui/material';

import theme from '../../../config/theme/themeVariable';
import ChefReviews from '../../../components/ChefReviews';
import { setInitialLoadedChefReviewsCount } from '../../../redux/slices/profileSlice';


const { TextArea } = Input;

const ChefReviewTab = () => {
    const dispatch = useDispatch();

    const chefReviews = useSelector(state => state.profile.chefReviews);
    // const initialLoadedChefReviewsCount = useSelector(state => state.profile.initialLoadedChefReviewsCount);
    const chefReviewsResponse = useSelector(state => state.profile.chefReviewsResponse);
    const [form] = Form.useForm();

    console.log('all chef reviews----------', chefReviews);


    const handlePostReview = (values) => {
        console.log('post review', values)
    } 

    const handleLoadMore = () => {
        dispatch(setInitialLoadedChefReviewsCount(1))
    }

    return (
        <>
            <Row className='w-full my-4 py-2'>
                <Row className='w-full my-4'>
                    {
                        chefReviews.length !== 0 ? 
                            chefReviews.map(item => {
                                return(
                                    <>
                                        <ChefReviews key={item.id} review={item} />
                                    </>
                                )
                            })
                            // (<><ChefReviews /></>)
                        :
                            (
                                <>
                                    <Row className='w-full bg-gray-100 py-4 px-5 mt-2 mb-2.5' style={{ borderRadius: '5px'}}>
                                        No Reviews Found
                                    </Row>
                                </>
                            )
                    }

                        {
                            (chefReviews.length > 0 && chefReviews.length < chefReviewsResponse?.total_records) ? 
                                (
                                    <>
                                        <Col className='w-full py-2'>
                                            <Card
                                                title=""
                                                onClick={handleLoadMore}
                                                style={{
                                                    textAlign: 'center',
                                                    boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <p>Load More</p>
                                            </Card>
                                        </Col>
                                    </>
                                )
                            :
                                (<></>)
                        }
                    
                    {/* <ChefReviews /> */}
                </Row>

                <Row style={{ display: 'none'}} className='w-full flex-col items-center justify-center my-4 gap-y-3'>
                    <Col>
                        <Typography
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            Write your review
                        </Typography>   
                    </Col>

                    <Col>
                        <Rating name="half-rating" size="large" defaultValue={2.5} precision={0.5} sx={{ fontSize: 40 }} />
                    </Col>

                    <Col className='w-11/12'>
                        <Form
                            name="create_dish_form_1"
                            form={form}
                            className='auth-form'
                                                
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handlePostReview}
                            autoComplete="off"
                        >
                            <Form.Item
                                    className=''
                                    name="chef_review"
                                >
                                <TextArea className='onboard-form-input' placeholder='Write you review' rows={3} />
                            </Form.Item>

                            <Form.Item>
                                <Button 
                                    block
                                    htmlType="submit" 
                                    key="submit" 
                                    style={{ backgroundColor: theme.primaryColor, fontFamily: 'OnestRegular'}} 
                                    type="primary" 
                                    size='large'
                                >
                                    Post Review
                                </Button>             
                            </Form.Item> 
                        </Form>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default ChefReviewTab;