import { useDispatch, useSelector } from 'react-redux';
import { Form, Collapse, Button, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import theme from '../../config/theme/themeVariable';
import FoodPhotosUploadSection from './FoodPhotosUploadSection';
import { setFoodCreateStep, setFoodPhotosFormData, setIsDishImageEmpty } from '../../redux/slices/foodSlice';


const FoodCreatePhotosForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const formFoodImages = useSelector(state => state.food.formFoodImages);
    // const isDishImageEmpty = useSelector(state => state.food.isDishImageEmpty);

    const handleFoodPhotosSubmit = () => {
        if(formFoodImages.length === 0){
            dispatch(setIsDishImageEmpty(true))
        }else{
            console.log(formFoodImages)
            dispatch(setFoodPhotosFormData(formFoodImages));
            dispatch(setFoodCreateStep(2));
        }

    };

    // form.setFieldsValue({
    // });

    const panelStyle = {
        marginBottom: 20,
        background: '#fff',
        border: 'none',
    };

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Food Pictures',
            children: <FoodPhotosUploadSection />,
            style: panelStyle,
        },
    ];

    const handleBackBtn = () => {
        dispatch(setFoodCreateStep(0));
    }

  return (
    <>
        <Form
            name="create_dish_form_2"
            form={form}
            className='auth-form'
            style={{ height: '410px'}}          
            initialValues={{
                remember: true,
            }}
            onFinish={handleFoodPhotosSubmit}
            autoComplete="off"
        >

            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={getItems(panelStyle)}
            />

            <Form.Item style={{ marginBottom: '15px' }}>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            size='large'
                            onClick={handleBackBtn}
                        >
                           Go Back
                        </Button>
                    </Col>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                        >
                            Next
                        </Button>
                    </Col>
                </Row> 
            </Form.Item>    
        </Form>            
    </>
  )
}

export default FoodCreatePhotosForm;