import { useSelector } from 'react-redux';
import { Col } from 'antd';

// import FoodCategoryList from './FoodCategoryList';
import FilterList from './FilterList';
import DishesList from './DishesList';
import LoadingSpinner from '../../../components/LoadingSpinner';


const DishTabSection = () => {
    const loading = useSelector(state => state.userSearch.loading);
    const searchedResultDataResponse = useSelector(state => state.userSearch.searchedResultDataResponse);

    return (
        <>
            {/* <Col className='w-full flex flex-col items-center mt-4 mb-3'>    
                <FoodCategoryList />
            </Col>  */}

            <Col className='w-full flex flex-col items-center mt-4 mb-3'>
                <FilterList />
            </Col>  

            <Col className='w-full flex flex-col items-center mt-4 mb-3'>   
                {
                    (loading && searchedResultDataResponse === null) ? 
                        (<><LoadingSpinner /></>)
                    :
                        (<><DishesList /></>)
                } 
            </Col> 
        </>
    )
}

export default DishTabSection;