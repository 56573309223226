import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Image }from 'antd';
import { Typography, Button  } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { logout, chefActivation } from '../../../redux/slices/authSlice';
import { updateUserRole } from '../../../redux/slices/userSlice';
import theme from '../../../config/theme/themeVariable'

import userProfileEdit from '../../../assets/imgs/icons/user_profile_edit.png';
import userProfileReviews from '../../../assets/imgs/icons/user_profile_reviews.png';
import userProfileManageChef from '../../../assets/imgs/icons/user_profile_manage_chef.png';

const UserProfileSettingSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.auth.user);

    const handleManageVenture = () => {

        if(user?.is_chef && user?.step === 3){
            navigate('/food');
        }else{

            dispatch(updateUserRole({
                requestData: {
                    is_chef: true,
                },
                chefOnboardCallback: () => navigate('/onboard'),
                foodCallback: () => navigate('/food')
            }));
        }
        // if(getItem('access_token') !== null){

        //     if(getItem('onboard_step') === 3){
        //         navigate('/food');
        //     }else{

        //         // TODO: is_chef value change api call
        //         setItem('is_chef', true);
        //         navigate('/onboard');
        //     }
        // }else{
        //     navigate('/');
        // }
    };

    const handleReactivateVenture = () => {
        dispatch(chefActivation({
            reqData: {is_active: true},
            callback: () => navigate('/profile')
        }));
    }

    const handleEditUserProfile = () => {
        navigate('/user-profile-edit');
    }

    const handleUserReviews = () => {
        navigate('/user-reviews');
    }

    const handleLogout = () => {
        dispatch(logout(() => {navigate('/')}));
    };

    return (
        <>
            <Row className='w-full justify-center items-center my-3.5'>

                <Card onClick={handleEditUserProfile} bordered={false} className='profile_edit_card w-full'>
                    <Row className='flex items-center py-2.5'>
                        <Col span={4} className='flex justify-center items-center'>
                            <Image src={userProfileEdit} width={35} alt='img' preview={false} />
                        </Col>
                        <Col span={18}>
                            <Typography className='font-normal text-lg' variant="p" style={{ fontFamily: 'OnestRegular'}}>
                                Edit Profile
                            </Typography>
                        </Col>
                        <Col span={2}>
                            <ChevronRightIcon />
                        </Col>
                    </Row>
                </Card>


                <Card bordered={false} onClick={handleUserReviews} className='profile_edit_card w-full'>
                    <Row  className='flex items-center py-2.5'>
                        <Col span={4} className='flex justify-center items-center'>
                            <Image src={userProfileReviews} width={35} alt='img' preview={false} />
                        </Col>
                        <Col span={18}>
                            <Typography className='font-normal text-lg' variant="p" style={{ fontFamily: 'OnestRegular'}}>
                                My Reviews
                            </Typography>
                        </Col>
                        <Col span={2}>
                            <ChevronRightIcon />
                        </Col>
                    </Row>
                </Card>

                {
                    user && !user?.is_chef && user?.is_completed ?
                        (
                            <Card bordered={false} onClick={handleReactivateVenture} className='profile_edit_card w-full'>
                                <Row  className='flex items-center py-2.5'>
                                    <Col span={4} className='flex justify-center items-center'>
                                        <Image src={userProfileManageChef} width={35} alt='img' preview={false} />
                                    </Col>
                                    <Col span={18} className='flex flex-col' >
                                        <Typography className='font-normal text-lg' variant="p" style={{ fontFamily: 'OnestRegular'}}>
                                            Reactivate Your Venture
                                        </Typography>
                                    </Col>
                                    <Col span={2}>
                                        <ChevronRightIcon />
                                    </Col>
                                </Row>
                            </Card>                            
                        )
                    :
                        (
                            <Card bordered={false} onClick={handleManageVenture} className='profile_edit_card w-full'>
                                <Row  className='flex items-center py-2.5'>
                                    <Col span={4} className='flex justify-center items-center'>
                                        <Image src={userProfileManageChef} width={35} alt='img' preview={false} />
                                    </Col>
                                    <Col span={18} className='flex flex-col' >
                                        <Typography className='font-normal text-lg' variant="p" style={{ fontFamily: 'OnestRegular'}}>
                                            Manage Your Venture
                                        </Typography>
                                        <Typography 
                                            className='font-normal' 
                                            variant="p" 
                                            style={{ 
                                                fontFamily: 'OnestRegular', 
                                                fontSize: '11px',
                                                color: theme.textSecondaryColor,
                                                fontStyle: 'italic'
                                            }}
                                            >
                                            (Click if you are chef)
                                        </Typography>
                                    </Col>
                                    <Col span={2}>
                                        <ChevronRightIcon />
                                    </Col>
                                </Row>
                            </Card>                            
                        )    
                }

                {/* <Card bordered={false} onClick={handleManageVenture} className='profile_edit_card w-full'>
                    <Row  className='flex items-center py-2.5'>
                        <Col span={4} className='flex justify-center items-center'>
                            <Image src={userProfileManageChef} width={35} alt='img' preview={false} />
                        </Col>
                        <Col span={18} className='flex flex-col' >
                            <Typography className='font-normal text-lg' variant="p" style={{ fontFamily: 'OnestRegular'}}>
                                Manage Your Venture
                            </Typography>
                            <Typography 
                                className='font-normal' 
                                variant="p" 
                                style={{ 
                                    fontFamily: 'OnestRegular', 
                                    fontSize: '11px',
                                    color: theme.textSecondaryColor,
                                    fontStyle: 'italic'
                                }}
                                >
                                (Click if you are chef)
                            </Typography>
                        </Col>
                        <Col span={2}>
                            <ChevronRightIcon />
                        </Col>
                    </Row>
                </Card> */}
            </Row>

            <Row className='w-full justify-center items-center my-5'>
                <Button 
                    className='logout_btn' 
                    variant="contained" startIcon={<LogoutIcon />} 
                    onClick={handleLogout}
                    sx={{ 
                        background: '#fff', 
                        color: '#000', 
                        textTransform: 'capitalize', 
                        fontSize: '16px' ,
                        boxShadow: 'rgb(0 0 0 / 12%) 1px 3px 10px', 
                        borderRadius: '5px', 
                        border: 'none' ,
                        fontFamily: 'OnestRegular',
                        fontWeight: '500'
                    }} 
                >
                    Logout
                </Button>
            </Row>


            {/* <Row className='w-full justify-center items-center my-5'>
                <Button 
                    className='logout_btn' 
                    variant="contained" startIcon={<LogoutIcon />} 
                    onClick={handleChatInit1}
                    sx={{ background: '#fff', color: '#000', textTransform: 'capitalize', fontSize: '16px' }} 
                >
                    Chat Chef 7177128316
                </Button>
            </Row>

            <Row className='w-full justify-center items-center my-5'>
                <Button 
                    className='logout_btn' 
                    variant="contained" startIcon={<LogoutIcon />} 
                    onClick={handleChatInit2}
                    sx={{ background: '#fff', color: '#000', textTransform: 'capitalize', fontSize: '16px' }} 
                >
                    Chat Chef 7177128312
                </Button>
            </Row> */}
        </>
    )
}

export default UserProfileSettingSection;