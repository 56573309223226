/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button, Upload, Image, message } from 'antd';

import theme from '../../config/theme/themeVariable';
import { 
    uploadUserProfilePic, 
    setUserProfileImage, 
    postUserProfilePic , 
    getProfileImage, 
    setIsUserProfileImageEmpty
} from '../../redux/slices/userSlice';
import img_upload_icon from '../../assets/imgs/img_upload_icon.png';

const { Dragger } = Upload;

const validateImage = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    // console.log(file.file.type)

    return acceptedImageTypes.includes(file.file.type);
};

const UserProfileImageForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const userImage = useSelector(state => state.user.userImage);
    const userImageData = useSelector(state => state.user.userImageData);
    const userProfileImage = useSelector(state => state.user.userProfileImage);
    const isUserProfileImageEmpty = useSelector(state => state.user.isUserProfileImageEmpty);


    const handleCancelBtn = () => {
        dispatch(setIsUserProfileImageEmpty(false));
        navigate('/user-profile');
    }
    useEffect(() => {
        dispatch(getProfileImage());
    },[])
    useEffect(()=>{
        // console.log("userImage,userProfileInage useEffect",-1===userProfileImage.findIndex((upi)=>{return upi.id===userImageData.id}));

        if (userImageData && userImageData.id && ( userProfileImage.findIndex((upi)=>{return upi===null})>-1 || -1===userProfileImage.findIndex((upi)=>{return upi.id===userImageData.id}))){
            dispatch(setUserProfileImage([userImageData]))

        }
    },[userImageData])

    const customUpload = async (file) => {
        if (!validateImage(file)) {
            message.error('Please upload only image files (JPEG, PNG, GPG)');
            throw new Error('Please upload only image files (JPEG, PNG, GPG)');
        }else{
            try {
                console.log("file",file);
                await dispatch(uploadUserProfilePic(file));
                message.success('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading Image:', error);
                message.error('Files upload failed.');
            }
        }
    };

    const uploadProps = {
        customRequest: (filedata) => {
           const { onSuccess, onError } = filedata;
           customUpload(filedata)
            .then(() => onSuccess())
            .catch((error) => {
              console.error('Custom upload error:', error);
              onError(error);
            });
        },
        onChange(info) {
            // console.log('onchange= ',info)
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
        },
        onRemove(file){
            console.log('onremove= ',file, userProfileImage)
            const restData = userProfileImage.filter(item => (item?.originalName || item?.image?.name) !== file.name);
            // console.log('-------',restData);
            dispatch(setUserProfileImage(restData));
        }
    };

    const handleProfileImageSubmit = () => {
        if(userProfileImage.length > 0){
            dispatch(postUserProfilePic({userProfilePic: userProfileImage, callback: () => navigate('/user-profile')}));
        }else{
            dispatch(setIsUserProfileImageEmpty(true));
        }
        // console.log('profile img--------',userProfileImage);
    }

    console.log(                        [{ 
        id: userProfileImage[userProfileImage.length - 1]?.image?.id,
        name: userProfileImage[userProfileImage.length  - 1]?.image?.name,
        url: userProfileImage[userProfileImage.length  - 1]?.profile_pic_url, 
        thumbUrl:  userProfileImage[ userProfileImage.length  - 1 ]?.profile_pic_url,
        status: 'done'
    }])

    return (
        <Form
            name="onboard_form_1"
            form={form}
            className='auth-form'
            layout='vertical'        
            initialValues={{
                remember: true,
            }}
            onFinish={handleProfileImageSubmit}
            autoComplete="off"
        >
            <Form.Item
                name="feed_picture"
                className='post_picture_input'
                label="Picture"
                // style={{ border: isUserProfileImageEmpty ? "1px solid red" : ''}}
                validateStatus={isUserProfileImageEmpty ? 'error' : 'success'}
                help={isUserProfileImageEmpty? 'Please upload profile image.' : ''}
            > 
                <Dragger 
                    defaultFileList={userProfileImage && userProfileImage.length !== 0 ? 
                        [{ 
                            id: userProfileImage[userProfileImage.length  - 1]?.image?.id || userProfileImage[userProfileImage.length  - 1]?.id,
                            name: userProfileImage[userProfileImage.length  - 1]?.image?.name || userProfileImage[userProfileImage.length  - 1]?.originalName,
                            url: userProfileImage[userProfileImage.length  - 1]?.profile_pic_url || userProfileImage[userProfileImage.length  - 1]?.url, 
                            thumbUrl:  userProfileImage[ userProfileImage.length  - 1 ]?.profile_pic_url || userProfileImage[userProfileImage.length  - 1]?.url,
                            status: 'done'
                        }] 
                        : []} 
                    className='image-drag-box' 
                    listType='picture' 
                    maxCount={1} 
                    accept='image/png, image/jpeg, image/jpg' 
                    {...uploadProps} >
                    {/* <Row className='flex flex-col justify-center items-center'> */}
                        {
                            userProfileImage && userProfileImage.length !== 0 ? 
                                (
                                    <>
                                        <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                            <Image preview={false} className="w-full" src={userProfileImage[userProfileImage.length - 1]?.profile_pic_url || userProfileImage[userProfileImage.length - 1]?.image_url || userProfileImage[userProfileImage.length - 1]?.url} alt='img' />
                                        </Row>
                                    </>
                                )
                            :
                                (
                                    <>
                                        <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                            <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' />
                                            <p className="ant-upload-text">Click to Upload</p>
                                        </Row>
                                    </>
                                )

                        }
                    {/* </Row> */}
                </Dragger>
            </Form.Item>

            <Form.Item>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            size='large'
                            onClick={handleCancelBtn}
                        >
                           Cancel
                        </Button>
                    </Col>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form.Item>  
        </Form>
    )
}

export default UserProfileImageForm;