/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';


import { 
    // setActiveOnboardForm, 
    createChefType, 
    updateChefType, 
    getRestaurantTypeInfo,
    setCuisinesList,
    setTagList,
    setDeliveryTypeData,
    setDietaryPreferenceList,
    setBusinessTypeInput,
    setBusinessTypeOthers
} from '../../redux/slices/profileSlice';  
import { setActiveOnboardForm } from '../../redux/slices/authSlice'; 
import { setCurrentChefStep } from '../../redux/slices/profileSlice'; 
import { getItem } from '../../utility/localStorageControl';
import theme from '../../config/theme/themeVariable';
import SubmitSpinner from '../SubmitSpinner';

import ChefServiceType from './ChefServiceType';
import ChefCuisinesTag from './ChefCuisinesTag';


const OnboardChefTypeForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.profile.loading);
    const step = useSelector(state => state.auth.user.step);
    const currentChefStep = useSelector(state => state.profile.currentChefStep);
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const cuisinesData = useSelector(state => state.food.cuisinesData);
    const tagData = useSelector(state => state.food.tagData);
    const dietaryPreferenceData = useSelector(state => state.food.dietaryPreferenceData);
    const deliveryTypeData = useSelector(state => state.food.deliveryTypeData);
    const restaurantTypeData = useSelector(state => state.profile.restaurantTypeData);

    const cuisinesList = useSelector(state => state.profile.cuisinesList);
    const tagList = useSelector(state => state.profile.tagList);
    const dietaryPreferenceList = useSelector(state => state.profile.dietaryPreferenceList);
    const deliveryTypeList = useSelector(state => state.profile.deliveryTypeList);
    const businessTypeInput = useSelector(state => state.profile.businessTypeInput);
    const businessTypeOthers = useSelector(state => state.profile.businessTypeOthers);


    if(businessTypeInput && businessTypeInput.length !== 0){
        form.setFieldsValue({
            business_type : businessTypeInput
        });
    }
    
    if(businessTypeOthers ){
        form.setFieldsValue({
            business_type_others : businessTypeOthers
        });
    }

    const updateBusinessTypeInputData = (value) => {
        dispatch(setBusinessTypeInput(value));
    }

    const updateCuisinesList = (value) => {
        dispatch(setCuisinesList(value));
    }

    const updateTagsList = (value) => {
        dispatch(setTagList(value));
    }

    const updateDeliveryList = (value) => {
        dispatch(setDeliveryTypeData(value));
    }

    const updateDietaryPreferenceList = (value) => {
        dispatch(setDietaryPreferenceList(value));
    }

    const updateBusinessTypeOthers = (value) => {
        dispatch(setBusinessTypeOthers(value));
    }


    const handleProfileSubmit = (values) => {
        // console.log({...values, service_days: serviceDaysList, cuisines: cuisinesList, restaurant_id: restaurantId });
        console.log("step",step,currentChefStep);
        if(step === 1){
            dispatch(createChefType({
                ...values, 
                cuisines: cuisinesList, 
                restaurant_id: restaurantId, 
                tags: tagList, 
                dietaryPereference: dietaryPreferenceList,
                deliveryType: deliveryTypeList,
            }));
            // console.log({...values, service_days: serviceDaysList, cuisines: cuisinesList, restaurant_id: restaurantId });
        }else{

            // console.log('here= ',{
            //     ...values, 
            //     cuisines: cuisinesList , 
            //     restaurant_id: restaurantId,
            //     tags: tagList, 
            //     dietaryPereference: dietaryPreferenceList,
            //     deliveryType: deliveryTypeList,
            // });

            dispatch(updateChefType({
                ...values, 
                cuisines: cuisinesList , 
                restaurant_id: restaurantId,
                tags: tagList, 
                dietaryPereference: dietaryPreferenceList ,
                deliveryType: deliveryTypeList,
            }));
        }
    }

    const handleBack = () => {
        dispatch(setCurrentChefStep(0))
    }

    useEffect(() => {
        if(step > 1){
            console.log('call updated data')
            dispatch(getRestaurantTypeInfo(restaurantId));
        }
        
        // eslint-disable-next-line
    },[]);

    if(restaurantTypeData !== null){
        // console.log('updated type data = ', restaurantTypeData);
        const cuisineUpdatedData = restaurantTypeData.cuisines.map(item => item.id);
        const dietaryUpdatedData = restaurantTypeData.dietary_preferences.map(item => item.id);
        const tagsUpdatedData = restaurantTypeData.tags.map(item => item.id);
        const deliveryUpdatedData = restaurantTypeData.delivery_type.map(item => item.id);

        const formattedServiceType = [];
        const serviceUpdatedData = Object.keys(restaurantTypeData.kitchen_type).filter(key => restaurantTypeData.kitchen_type[key] === true);
        serviceUpdatedData.forEach(item => {
            if(item === 'delivery_only'){
                formattedServiceType.push('delivery');
            }else{
                formattedServiceType.push(item)
            }
        });


        // form.setFieldsValue({
        //     cuisine_select: cuisineUpdatedData,
        //     dietary_preference_select: dietaryUpdatedData,
        //     dietary_type_select: tagsUpdatedData,
        //     delivery_select: deliveryUpdatedData,
        //     business_type_others : restaurantTypeData?.kitchen_type?.other !== '' || restaurantTypeData?.kitchen_type?.other !== 'none' ? restaurantTypeData?.kitchen_type?.other : '',
        //     business_type: formattedServiceType
        // })
    }


    const panelStyle = {
      marginBottom: 20,
      background: '#fff',
      border: 'none',
    };

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Culinary Details',
            children: 
            <>
                {
                    (step === 1 || restaurantTypeData !== null) ? 
                        (<>
                            <ChefCuisinesTag restaurantTypeData={restaurantTypeData} cuisinesData={cuisinesData} tagData={tagData} dietaryPreferenceData={dietaryPreferenceData} deliveryTypeData={deliveryTypeData} updateCuisinesList={updateCuisinesList} updateTagsList={updateTagsList} updateDietaryPreferenceList={updateDietaryPreferenceList} updateDeliveryList={updateDeliveryList} />
                        </>)
                    :   
                        (<></>)
                }
                
            </>,
            style: panelStyle,
        },
        {
            key: '2',
            label: 'Service Provided',
            children:
            <>
                {
                    (step === 1 || restaurantTypeData !== null) ? 
                        (<>
                             <ChefServiceType restaurantTypeData={restaurantTypeData} updateBusinessTypeInputData={updateBusinessTypeInputData} updateBusinessTypeOthers={updateBusinessTypeOthers} />
                        </>)
                    :   
                        (<></>)
                }
                
            </>,
            style: panelStyle,
        }
    ];

    return(
        <>
            <Form
                name="onboard_form_2"
                form={form}
                className='auth-form'
                                    
                initialValues={{
                    remember: true,
                }}
                onFinish={handleProfileSubmit}
                autoComplete="off"
            >

                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    items={getItems(panelStyle)}
                />

                <Form.Item>
                    <Row className='w-full flex'>
                        <Col className='px-4' span={12} style={{ display: step > 1 ? 'none' : 'block'}}>
                            <Button 
                                block
                                size='large'
                                onClick={handleBack}
                                disabled={loading ? true : false}
                            >
                               Go Back
                            </Button>
                        </Col>
                        <Col className='px-4' span={step > 1  ? 24 : 12}>
                            <Button 
                                block
                                htmlType="submit" 
                                key="submit" 
                                style={{ backgroundColor: theme.primaryColor}} 
                                type="primary" 
                                size='large'
                            >
                                { loading ? <SubmitSpinner /> : step ? 'Update': 'Next'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>    
            </Form>            
        </>
    );
}

export default OnboardChefTypeForm;
