import { Form, Input } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ContactForm = () => {
    const location = useLocation();
    // Custom validation for mobile number
    const validateMobileNumber = (_, value) => {
        const regex = /^[0-9]{10}$/; // Simple validation for a 10-digit mobile number
        if (!regex.test(value)) {
        return Promise.reject('Please enter a valid 10-digit mobile number');
        }
        return Promise.resolve();
    };
    const user = useSelector(state=>state.auth.user);

    return(
        <>
            <Form.Item
                name="phone"
                className=''
                // initialValue={restaurantDetailsData?.restaurant_owner?.owner_mobile_number.slice(3)}
                initialValue={user['phone_number'] ? user['phone_number'].slice(3) : ''}
                rules={[{
                        required: true,
                        message: '',
                    },
                    {
                        validator: validateMobileNumber
                    }
                ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='Phone Number' 
                    disabled
                />
            </Form.Item> 

            <Form.Item
                name="email"
                className=''
                rules={[
                    {
                        required: true,
                        message: 'Email is Required',
                    },
                    {
                        type: 'email',
                        message: 'Please enter a valid email address',
                    },
                ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='Email' />
            </Form.Item> 

            <Form.Item
                name="alternate_phone"
                style={{ display: (location.pathname.includes('user-profile-edit') || location.pathname.includes('user-onboard')) ? 'none': 'block'}}
                className=''
                rules={[
                    { pattern:  /^[0-9]{10}$/ , message: 'Please enter a valid 10-digit mobile number'}, 
                ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='Alternate Phone Number' 
                />
            </Form.Item> 
        </>
    );
}
export default ContactForm;