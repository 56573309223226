/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import{ useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row,Col, message } from 'antd';

import titleHeadWithBackLayout from '../UserLayout/titleHeadWithBackLayout';
import DishItemDetails from './sections/DishItemDetails';
import { getFoodReviews, getDishById, setFoodError } from '../../redux/slices/foodSlice';
import { getAboutRestaurant } from '../../redux/slices/profileSlice';


const UserFoodDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [messageApi, contextHolder] = message.useMessage();

    const { selectedDishData } = location.state;
    const selectedDishReviewsData = useSelector(state => state.food.selectedDishReviewsData);
    const initialSelectedDishReviewsCount = useSelector(state => state.food.initialSelectedDishReviewsCount);
    const selectedItemForView = useSelector(state => state.food.selectedItemForView);
    const error = useSelector(state => state.food.error);

    console.log('selected dish data----------', selectedDishData, selectedDishReviewsData);

    const errorToast = (text) => {
        messageApi.open({
          type: 'error',
          content: text,
        });
    };

    useEffect(() => {
        dispatch(getFoodReviews(selectedDishData?.id));
    }, [initialSelectedDishReviewsCount])


    useEffect(() => {
        if(selectedItemForView){
            dispatch(getAboutRestaurant(selectedItemForView?.restaurant_id))
        }
    },[selectedItemForView])

    useEffect(() => {
        if(selectedDishData && selectedDishData?.id){
            dispatch(getDishById(selectedDishData?.id))
        }
    }, [selectedDishReviewsData.length])

    useEffect(() => {
        if(error !== null){
            errorToast(error);
            dispatch(setFoodError(null));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error])

    return (
        <>
            {contextHolder}
            <Row className='w-full 2xs:h-full sm:h-auto sm:min-h-screen sm:bg-neutral-100 flex flex-col justify-start sm:items-center'>
                <Col 
                    className="bg-white w-full xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 2xl:w-3/12 h-full p-5" 
                    style={{minHeight:'100vh'}} >
                    <DishItemDetails selectedDishData={selectedItemForView || selectedDishData} dishReviews={selectedDishReviewsData} />
                </Col>
            </Row>
        </>
    )
}

export default titleHeadWithBackLayout(UserFoodDetails);