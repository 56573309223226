/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, message } from 'antd';

import OnboardChefInfoForm from '../../../components/forms/OnboardChefInfoForm';
import OnboardChefTypeForm from '../../../components/forms/OnboardChefTypeForm';
import OnboardChefPhotosForm from '../../../components/forms/OnboardChefPhotosForm';
import {setProfileError, getChefRestaurantId } from '../../../redux/slices/profileSlice';
import { getCuisines, getTag, getDietaryPreference, getDeliveryType } from '../../../redux/slices/foodSlice';
import { getUserProfile } from '../../../redux/slices/userSlice';

const OnboardForm = () => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    // const onboardFormStep =  useSelector(state => state.auth.user?.step);
    // const currentChefStep = useSelector(state => state.auth.user.step);
    const currentChefStep = useSelector(state => state.profile.currentChefStep);
    const errorMsg = useSelector(state => state.profile.error);
    const restaurantId = useSelector(state => state.profile.restaurantId);

    const errorMessageAlert = (errMsg) => {
        messageApi.open({
          type: 'error',
          content: errMsg,
        });
    };

    useEffect(() => {
        if(errorMsg !== null){
            errorMessageAlert(errorMsg);
            dispatch(setProfileError(null));
        }
    },[errorMsg]);

    useEffect(()=> {
        dispatch(getUserProfile());
    },[]);

    useEffect(() => {
        if(currentChefStep === 1){
            dispatch(getCuisines());
            dispatch(getDeliveryType());
            dispatch(getDietaryPreference());
            dispatch(getTag());
        }
    },[currentChefStep]);

    useEffect(() => {
        if(restaurantId === null && currentChefStep !== 0){
            dispatch(getChefRestaurantId());
        }
        // eslint-disable-next-line
    },[restaurantId, currentChefStep])

    // console.log( currentChefStep)

    return(
        <>
            {contextHolder}
            <Col className='w-full my-3 px-4'>
                {
                    (currentChefStep === 0) ? 
                        (<><OnboardChefInfoForm /></>)
                    :
                    (currentChefStep === 1) ?
                        (<><OnboardChefTypeForm /></>)
                    :
                    (currentChefStep === 2) ?
                        (<><OnboardChefPhotosForm /></>)
                    :
                        (<></>)    
                }
                
            </Col>
        </>
    );
}

export default OnboardForm;
