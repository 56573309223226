import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { Typography } from '@mui/material';

import theme from '../../../config/theme/themeVariable';
import ChefBasicDetails from './ChefBasicDetails';
import { LeftOutlined } from '@ant-design/icons';

const ChefPublicView = ({ aboutRestaurantInfo, chefImage }) => {
    const navigate = useNavigate();

    const handleBackBtn = () => {
        navigate(-1);
    };

    return (
        <Col className='w-full my-3.5'>
            <Row className='w-full flex justify-between'>
                <Col className='w-full flex justify-start items-center gap-x-3'>
                    <Col>
                        <Button 
                            icon={<LeftOutlined />} 
                            onClick={handleBackBtn}
                            style={{ color: theme.textColor, boxShadow: 'rgb(0 0 0 / 12%) 1px 3px 10px', borderRadius: '14px', border: 'none' }}
                            size="large" />
                    </Col>
                    <Col>
                        <Typography className='font-bold' variant="h6" style={{ fontFamily: 'OnestRegular'}}>
                            Public Profile
                        </Typography>
                    </Col>
                </Col>
            </Row>

            <ChefBasicDetails aboutRestaurantInfo={aboutRestaurantInfo} chefImage={chefImage} />
        </Col>    
    )
}

export default ChefPublicView;
