import axios from 'axios';
import store from '../redux/store';
import { setServerError , updateAccessToken,logout} from '../redux/slices/authSlice';
// import { getItem, setItem, removeItem } from '../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_URL}`;

const tokenSupplier = () => store.getState()?.auth?.user?.access_token;
const refreshTokenSupplier = () => store.getState()?.auth?.user?.refresh_token;

const authHeader = () => {
  const access_token = tokenSupplier();
  if (access_token && null !== access_token  && "undefined" !== access_token && "null" !== access_token && "" !== access_token){
    return {
    
      Authorization: `Bearer ${access_token}`,
    }
  }else{
    return {};
  }
};


const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});


class ApiService {
    static get(path = '' ) {
      return client({
        method: 'GET',
        url: path,
        headers: { ...authHeader() },
      });
    }
  
    static post(path = '', data = {}, optionalHeader = {}) {
      return client({
        method: 'POST',
        url: path,
        data,
        headers: { ...authHeader(), ...optionalHeader },
      });
    }
  
    static patch(path = '', data = {} ) {
      return client({
        method: 'PATCH',
        url: path,
        data: JSON.stringify(data),
        headers: { ...authHeader() },
      });
    }
  
    static put(path = '', data = {}) {
      return client({
        method: 'PUT',
        url: path,
        data: JSON.stringify(data),
        headers: { ...authHeader() },
      });
    }

    static delete(path = '', data = {}) {
      return client({
        method: 'DELETE',
        url: path,
        data: JSON.stringify(data),
        headers: { ...authHeader() },
      });
    }
}


client.interceptors.request.use((config) => {

    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    const access_token = tokenSupplier();
    if (access_token && null !== access_token  && "undefined" !== access_token && "null" !== access_token && "" !== access_token){

      requestConfig.headers = { ...headers, Authorization: `Bearer ${access_token}` };
    }
  
    return requestConfig;
});


client.interceptors.response.use(
    (response) => response,
    async (error) => {
      /**
       * Do something in case the response returns an error code [3**, 4**, 5**] etc
       * For example, on token expiration retrieve a new access token, retry a failed request etc
       */
      const { response } = error;
      const originalRequest = error.config;
      // console.log("response interceptors",response.status, response,originalRequest, originalRequest._retry);
  
      
      if (response) {
        if (response.status === 500 || response.status === 502 || response.status === 503) {
          // do something here
          // console.log('error= ',response);
          // console.log('erro1r= ',error);
          store.dispatch(setServerError('Server error occurred.'));
          return Promise.reject(error);

        } else if(response.status === 401){
          // originalRequest._retry = true;
  
          const apiConfig  = {
            url: `${API_ENDPOINT}/refresh`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${refreshTokenSupplier()}`
            }
          }
      
          try {
            const res = await axios.request(apiConfig);
            // setItem('access_token', res.data.access_token);
            console.log("refresh",res);
            store.dispatch(updateAccessToken(res.data.access_token));
            originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
  
            return client(originalRequest);
          } catch (err) {
            if(err.response && err.response.status===401){
              console.log("should logout");
              delete originalRequest.headers.Authorization;
              store.dispatch(updateAccessToken(null));
              console.log("should logout");
            }
            console.log("refresh err",err.response.status,err)
            // removeItem('access_token');
            // removeItem('refresh_token');


            // logout function to call
            store.dispatch(logout());
            return Promise.reject(err);
          }
        } else {
          return response;
        }
      }
      return Promise.reject(error);
    },
);

export { ApiService };