import { Col, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import theme from '../../../config/theme/themeVariable';
import foodEmpty from '../../../assets/imgs/food_empty.png';

const EmptyFoodList = () => {
  const navigate = useNavigate();

  const handleAddFood = () => {
    navigate('/create-food');
  };

  return (
    <>
      <Col className='bg-white flex flex-col justify-center items-center gap-y-3 w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5' style={{ minHeight: "100vh" }} >
        <Image preview={false} src={foodEmpty} alt='img' width={190} height={190} />

        <Typography variant="p" style={{ fontFamily: 'OnestRegular'}}>
            Your food list is currently empty !
        </Typography>

        <Button 
          style={{ backgroundColor: theme.primaryColor, textTransform: 'none', fontSize: '16px'}} 
          variant="contained" 
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleAddFood}
        >
          Add Food
        </Button>
        
      </Col>
    </>
  )
}

export default EmptyFoodList