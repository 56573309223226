import { Row, Col } from 'antd';

import logoHeadOnlyLayout from '../UserLayout/logoHeadOnlyLayout';
import UserProfileImageForm from '../../components/forms/UserProfileImageForm';

const EditUserProfileImage = () => {
    return (
        <Row className=' w-full sm:min-h-full sm:h-auto sm:flex-col  sm:items-center bg-neutral-100'>
            <Col className='w-full bg-white rounded-md xl:w-4/12 lg:w-5/12 md:w-6/12 sm:w-7/12  sm:px-3 sm:my-4'>

                <Row className='w-full flex justify-center my-4 ml-2 px-4'>
                    <h1 className='text-xl font-medium' style={{ fontFamily: 'OnestSemibold'}}>Edit Your Photo</h1>
                </Row>

                <Col className='w-full my-3 px-4'>
                    <UserProfileImageForm />
                </Col>
                
            </Col>
        </Row>
    )
}

export default logoHeadOnlyLayout(EditUserProfileImage);