import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Image } from "antd";
import { Typography } from "@mui/material";
import { isIOS , isMobile } from 'react-device-detect';

import theme from "../../../config/theme/themeVariable";
import { LeftOutlined } from "@ant-design/icons";
import bellImg from "../../../assets/imgs/bell.png";
import { setSelectedItemForView } from "../../../redux/slices/foodSlice";

const TopTitleHeadWithBack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { notificationHistoryData, formattedNotificationData } = useSelector(
    (state) => state.notification
  );
  
  const [heading, setHeading] = useState("Menu");

  useEffect(() => {
    if (location.pathname.includes("chef-details")) {
      setHeading("Menu");
    }
    if (location.pathname.includes("food-details")) {
      setHeading("Food Details");
    }
    if (location.pathname.includes("user-reviews")) {
      setHeading("My Reviews");
    }
  }, [location.pathname]);

  const handleBackBtn = () => {
    if (location.pathname.includes("food-details")) {
      dispatch(setSelectedItemForView(null))
    }
    // console.log('back')
    navigate(-1);
  };

  return (
    <>
      <Row className="border-b-gray-300 sm:border-neutral-100 border-2 w-full flex items-center justify-center py-3.5 sm:py-0"
        style={{ boxShadow: "0px 2px 10px rgb(0 0 0 / 0.1)","paddingTop":(process.env.REACT_APP_BUILDFOR==='bundle' && isIOS && isMobile )?'30px':'0px' }}
      >
        <Row className="w-full 2xs:hidden sm:flex py-1 px-5">
          <Col className="w-1/5 flex justify-start px-4">
            {/* <h1 className='text-black mr-auto sm:text-2xl 2xs:text-4xl font-bold'>Chef App</h1> */}
            <Image
              className="chef-logo-desktop"
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>
          <Col
            className="w-1/5 ml-auto flex items-center justify-end px-4"
            onClick={() => navigate("/notifications")}
          >
            <Image width={25} height={25} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-3 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="w-full flex justify-center items-center sm:hidden 2xs:flex py-2.5">
          <Col span={4} className="flex justify-center items-center">
            <Button
              icon={<LeftOutlined />}
              onClick={handleBackBtn}
              style={{
                color: theme.textColor,
                boxShadow: "rgb(0 0 0 / 12%) 1px 3px 10px",
                borderRadius: "14px",
                border: "none",
              }}
              size="large"
            />
          </Col>
          <Col span={16} className="flex justify-center items-center">
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={600}
              fontFamily={"OnestRegular"}
            >
              {heading || "NA"}
            </Typography>
          </Col>
          <Col span={4}></Col>
        </Row>
      </Row>
    </>
  );
};

export default TopTitleHeadWithBack;
