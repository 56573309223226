/* eslint-disable array-callback-return */
import {useEffect,useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { Typography } from '@mui/material';

import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
// import theme from '../../../config/theme/themeVariable';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DishListCard from '../../../components/cards/DishListCard';
import { setPublicViewFilterValue } from '../../../redux/slices/userSlice';
import theme from '../../../config/theme/themeVariable';



const ChefDishTab = () => {
    const dispatch = useDispatch();
    const cuisinesData = useSelector(state => state.food.cuisinesData);
    const chefAllDishData = useSelector(state => state.user.chefAllDishData);
    const dietaryPreferenceData = useSelector(state => state.food.dietaryPreferenceData);

    const [cuisineWiseDish,setCuisineWiseDish] = useState([]);
    const [dietaryPreferenceOptions,setDietaryPreferenceOptions] = useState([]);

    useEffect(()=>{
        let dietaryPreferenceOptions = []
        if(dietaryPreferenceData !== null){
            dietaryPreferenceData.forEach( (item) => {
                if(null !== item){
                    dietaryPreferenceOptions.push(<Option key={item.id} value={item.name}>{item.name}</Option>)
                }
            })

            dietaryPreferenceOptions.push(<Option key={dietaryPreferenceData.length + 1} value='All'>All</Option>)
        };
        setDietaryPreferenceOptions(dietaryPreferenceOptions)
    },[dietaryPreferenceData])

    const handleFilter = (e,value) => {
        e.preventDefault();
        
        if(value === 'All'){
            dispatch(setPublicViewFilterValue(null));
        }else{
            dispatch(setPublicViewFilterValue(value));
        }
        // dispatch(setPublicViewFilterValue(value));
        // console.log('filter--------', value);
    }
    useEffect(()=>{
        let localcuiseneWiseDish = [];
        if (cuisinesData && chefAllDishData){
            for (let c  of cuisinesData){
                if (c){
                    let localcuiseneWiseDishItem=Object.assign({},c,{"dishes":[]})
                    for (let d of chefAllDishData){
                        if (d && d.culinary_categories && d.culinary_categories.some(category => category.id === c.id)){
                            localcuiseneWiseDishItem.dishes.push(d)
                        }
                    }
                    localcuiseneWiseDish.push(localcuiseneWiseDishItem)
                }
            }
        }
        setCuisineWiseDish(localcuiseneWiseDish)
    },[cuisinesData,chefAllDishData])

    return (
        <>
            <Row className='w-full my-4 py-2'>

                {/* Filters  */}
                <Row className='w-full my-3' >  
                    <Col className='w-full flex items-center justify-between'>
                        <Typography variant='h6' fontSize={18} fontWeight={500} fontFamily={'OnestSemibold'}>
                            Filter by
                        </Typography> 
                    </Col>

                    <Col className='search-filters flex items-center justify-between gap-x-3 my-2'>
                        <Select
                            
                            placeholder="All"
                            defaultValue="All"
                            indicator={<KeyboardArrowDown />}
                            onChange={handleFilter}
                            sx={{ borderRadius: '15px', minWidth: 'auto' }}
                        >
                            {dietaryPreferenceOptions}
                            {/* <Option value="all">All</Option> */}
                        </Select>
                    </Col>
                </Row> 

                <Row className='w-full my-3' style={{ display: chefAllDishData && chefAllDishData.length === 0 ? 'block' : 'none'}}>
                    No Dishes Found
                </Row>

                {/* Dish List  */}
                <Row className='w-full my-3'>

                    {
                        cuisineWiseDish ? 
                            cuisineWiseDish.map(item => {
                                return(
                                    <>
                                        <Accordion 
                                            key={item.id} 
                                            data-dishcount={item.dishes.length} 
                                            className='public-chef-dish-accordian w-full' 
                                            defaultExpanded
                                            sx={{  boxShadow: 'none' }}>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls={`panel${item.id}-content`}
                                                sx={{ padding: '0 8px'}}
                                            >
                                                <Typography
                                                    variant='h6' 
                                                    fontSize={18} 
                                                    fontWeight={500} 
                                                    fontFamily={'OnestSemibold'} 
                                                    color={theme.textSecondaryColor}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails sx={{ padding: '8px'}}>
                                                {
                                                    item.dishes.map(i => {
                                                        return(<><DishListCard key={i.id} food={i} /></>)
                                                        
                                                    })
                                                }
                                                
                                                
                                            </AccordionDetails>

                                        </Accordion>
                                    </>
                                )
                            })
                        :
                            (<></>)
                    }

                    {/* <Accordion className='public-chef-dish-accordian w-full' sx={{  boxShadow: 'none' }}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                        >
                            <Typography
                                variant='h6' 
                                fontSize={18} 
                                fontWeight={500} 
                                fontFamily={'OnestSemibold'} 
                                color={theme.textColor}
                            >
                                Category 1
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <DishListCard />
                            <DishListCard />
                        </AccordionDetails>

                    </Accordion>

                    <Accordion className='public-chef-dish-accordian w-full' sx={{  boxShadow: 'none' }}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                        >
                            <Typography
                                variant='h6' 
                                fontSize={18} 
                                fontWeight={500} 
                                fontFamily={'OnestSemibold'} 
                                color={theme.textColor}
                            >
                                Category 2
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <DishListCard />
                            <DishListCard />
                        </AccordionDetails>
                        
                    </Accordion> */}
                </Row>

            </Row>
        </>
    )
}

export default ChefDishTab;