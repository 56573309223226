import { Row, Col } from 'antd';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import SafeArea from 'react-safe-area-component'

import TopLocationHead from './sections/TopLocationHead';
import BottomNav from './sections/BottomNav';


const locationHeadLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();
    const [ bottomPadding, setBottomPadding ] = useState(0);

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    useEffect(()=>{
        const footer = document.querySelector('.footer-container');
        if(footer){
            setBottomPadding(footer.clientHeight)
            // console.log(document.querySelector('.footer-container').clientHeight);
        }
    },[]);

  return (
    <>
        <SafeArea>
            <Row className="flex  d-block flex-col">
                <Col className='flex flex-col min-h-screen' style={{paddingBottom:"56px"}} >
                    <Row className='header w-full' style={{ flex: '0 0 auto'}}>
                        <TopLocationHead />
                    </Row>
                    <Row className='main-content w-full sm:bg-neutral-100' style={{ flex: '1', overflowY: 'auto'}}>
                        <Col span={24} sm={{span:10}} lg={{span:8}} xxl={{span:6}} className=" checking ml-auto mr-auto bg-white" style={{minHeight:"100vh"}} >
                            <ChildComponent {...props} style={{ paddingBottom: (bottomPadding - 30) }} />
                        </Col>
                    </Row>
                    <Row className='bg-white footer w-full' style={{ flex: '0 0 auto'}}>
                        <BottomNav />
                    </Row>
                </Col>
            </Row>
        </SafeArea>
    </>
  )
}

export default locationHeadLayout;