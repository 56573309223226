import { useSelector,useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { Typography } from '@mui/material';
import { Select, Option } from '@mui/joy';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { setDietaryPreferenseFilterData, setInitialLoadedSearchCountReset } from '../../../redux/slices/userSearchSlice';


const FilterList = () => {
    const dispatch = useDispatch();
    const dietaryPreferenceData = useSelector(state => state.food.dietaryPreferenceData);
    const dietaryPreferenseFilterData = useSelector(state => state.userSearch.dietaryPreferenseFilterData);

    const dietaryPreferenceOptions = [];
    if(dietaryPreferenceData !== null){
        dietaryPreferenceData.forEach( (item) => {
            dietaryPreferenceOptions.push(<Option key={item.id} value={item.name}>{item.name}</Option>)
        })

        dietaryPreferenceOptions.push(<Option key={dietaryPreferenceData.length + 1} value='All'>All</Option>)
    }

    const handleDietaryPreferenceFilter = (_, data) => {

        if(data === 'All'){
            // dispatch(setSearchedKeyword(null))
            dispatch(setDietaryPreferenseFilterData(null));
        }else{
            dispatch(setDietaryPreferenseFilterData(data));
        }
        dispatch(setInitialLoadedSearchCountReset())
        // console.log('filer-----',  data)

    }

    return (
        <>
            <Row className='w-11/12'>  
                <Col className='w-full flex items-center justify-between'>
                    <Typography variant='h6' fontSize={18} fontWeight={500} fontFamily={'OnestSemibold'}>
                        Filter by
                    </Typography> 
                </Col>

                <Col className='search-filters flex items-center justify-between gap-x-3 my-2'>
                    <Select
                        placeholder="All"
                        indicator={<KeyboardArrowDown />}
                        sx={{ borderRadius: '15px', minWidth: 'auto' }}
                        value={dietaryPreferenseFilterData}
                        defaultValue='All'
                        onChange={handleDietaryPreferenceFilter}
                        className="px-3"
                    >
                        {dietaryPreferenceOptions}
                    </Select>

                    {/* <Select
                        placeholder="Spice Level"
                        indicator={<KeyboardArrowDown />}
                        sx={{ borderRadius: '15px', minWidth: 'auto'}}
                    >
                        <Option value="veg">More Spicy</Option>
                        <Option value="nonveg">Less Spicy</Option>
                    </Select>

                    <Select
                        placeholder="Eco Friendly Packaging"
                        indicator={<KeyboardArrowDown />}
                        sx={{ borderRadius: '15px', minWidth: 'auto'}}
                    >
                        <Option value="veg">No Plastic</Option>
                        <Option value="nonveg">Paper Wraping</Option>
                    </Select>

                    <Select
                        placeholder="Cuisines"
                        indicator={<KeyboardArrowDown />}
                        sx={{ borderRadius: '15px', minWidth: 'auto'}}
                    >
                        <Option value="veg">Inian</Option>
                        <Option value="nonveg">Italian</Option>
                    </Select> */}

                </Col>

            </Row>  
        </>
    )
}

export default FilterList;