/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, lazy, Suspense  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Row } from 'antd';

import layout from "../Layout/layout";
import { getAllFeeds } from '../../redux/slices/feedSlice';
import { getAllDish } from '../../redux/slices/foodSlice';
import { getChefRestaurantId, getRestaurantImageInfo } from '../../redux/slices/profileSlice'; 
// import EmptyFeedList from './sections/EmptyFeedList';
// import FeedList from './sections/FeedList';
import theme from '../../config/theme/themeVariable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoadingSpinner from '../../components/LoadingSpinner';

const FeedList = lazy(() => import('./sections/FeedList'));
const EmptyFeedList = lazy(() => import('./sections/EmptyFeedList'));


const Feed = ({style}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allFeedData = useSelector(state => state.feed.allFeedData);
    const initialFeedLoadedCount = useSelector(state => state.feed.initialFeedLoadedCount);
    const chefImage = useSelector(state => state.profile.chefImage);
    const [ footerDivHeight, setFooterDivHeight ] = useState(null);
    const [ bottomPadding, setBottomPadding ] = useState(0);


    useEffect(() => {
        dispatch(getAllDish());
        const footerDiv = document.querySelector('.footer');
        if(footerDiv){
            setFooterDivHeight(footerDiv.offsetHeight);
        }
        dispatch(getChefRestaurantId());
    },[]);

    useEffect(()=>{
        const footer = document.querySelector('.footer-container');
        if(footer){
            setBottomPadding(footer.clientHeight)
            // console.log(document.querySelector('.footer-container').clientHeight);
        }
    },[]);

    useEffect(() => {
        dispatch(getAllFeeds());
    },[initialFeedLoadedCount])

    useEffect(() => {
        if(chefImage.length === 0){
            dispatch(getRestaurantImageInfo());
        }
    },[chefImage])

    const handleAddFeed = () => {
        navigate('/create-feed');
    };

    return (
        <Suspense
            fallback={
                <LoadingSpinner />
            }
        >
            <Row className='feed-main-container w-full 2xs:h-full sm:h-auto sm:min-h-full sm:bg-neutral-100 flex justify-center py-2' style={style}>
                {
                    allFeedData.length !== 0 ? 
                        (<FeedList />)
                    :
                        (<EmptyFeedList />)
                }
                
                <Button 
                    style={{ 
                        display: (footerDivHeight && allFeedData.length !== 0) ? 'inline-flex' : 'none', 
                        backgroundColor: theme.primaryColor, 
                        position: 'fixed', 
                        bottom: `${bottomPadding+20}px` ,
                        textTransform: 'capitalize',
                        fontSize: '16px',
                        zIndex: 1
                    }} 
                    variant="contained" 
                    onClick={handleAddFeed}
                    endIcon={<AddCircleOutlineIcon />}
                >
                    Add Post
                </Button>
            </Row>
        </Suspense>
    )
}

export default layout(Feed);