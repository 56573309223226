/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Button } from "antd";
import { Typography } from "@mui/material";
import { format } from 'timeago.js';
// import TopNav from "../Layout/sections/TopNav";
import layout from "../Layout/layout";
import {
  getNotificationHistory,
  // addUserNotificationHistory,
  updateNotificationHistory,
  setUpdateHistorySuccess,
  clearAllNotifications,
  setFormattedNotificationData
} from "../../redux/slices/notificationSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import theme from "../../config/theme/themeVariable";
import { LeftOutlined, FileTextFilled } from "@ant-design/icons";

const NotificationCard = ({ message, filteredChatArr = [] }) => {
  // console.log('----------msg---------', filteredChatArr)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleRead = async (message) => {
  //   await dispatch(updateNotificationHistory({ id: message.id }));
  //   await dispatch(getNotificationHistory())
  //   // navigate(message.call_to_action)
  // };

  const handleReadWithRedirect = async (message) => {
    // console.log('---message----', message, filteredChatArr)
    if(message?.call_to_action === '/user-chat' && filteredChatArr.length > 1){
      filteredChatArr.forEach(async (item) => {
        await dispatch(updateNotificationHistory({ id: item.id }));
      })
      
      await dispatch(getNotificationHistory())
      navigate(message.call_to_action === "/user-chat" ? "/chat" : message.call_to_action)
    }else{
      await dispatch(updateNotificationHistory({ id: message.id }));
      await dispatch(getNotificationHistory())
      navigate(message.call_to_action === "/user-chat" ? "/chat" : message.call_to_action)
    }
    // await dispatch(updateNotificationHistory({ id: message.id }));
    // await dispatch(getNotificationHistory())
    // navigate(message.call_to_action)
  };

  // const formatDate = (date) => {
  //   const formattedDate = moment(date).format("hh:mm A");
  //   return formattedDate;
  // };


  return (
    <>
      <Row
        className="w-full flex bg-gray-50 py-4 px-5 my-2"
        style={{ borderRadius: "5px" }}
        onClick={handleReadWithRedirect.bind(this, message)}
      >
        <Col span={3}>
          <FileTextFilled
            style={{
              fontSize: "20px",
              color: theme.colorShade4,
              padding: "5px",
              borderRadius: "5px",
            }}
          />
        </Col>
        <Col span={20}>
          <Col>
            <Typography
              variant="p"
              fontWeight="bold"
              fontSize={16}
              fontFamily={"OnestRegular"}
            >
              {message.title}
            </Typography>
          </Col>
          <Col>
            <Typography variant="p" fontSize={14} fontFamily={"OnestRegular"}>
              {message.description}
            </Typography>
          </Col>
          <Col>
            <Typography
              variant="p"
              fontWeight="bold"
              fontSize={12}
              fontFamily={"OnestRegular"}
            >
              {format(message.created_at)}
            </Typography>
          </Col>
        </Col>
        <Col
          className="w-full flex justify-center"
          span={1}
          
          // style={{ backgroundColor: "#E23E3E" }}
        >
          <div className="mt-2" style={{ height: '8px', width: '8px', backgroundColor: "#E23E3E", borderRadius: '50%'}}></div>
        </Col>
      </Row>
    </>
  );
};

const Notifications = () => {
  const navigate = useNavigate();
  // const [ formattedNotificationData, setFormattedNotificationData ] = useState([]);
  const [ filteredChatArr, setFilteredChatArr ] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const formattedNotificationData = useSelector((state) => state.notification.formattedNotificationData);

  if (!user) {
    navigate(-1);
  }

 
  const dispatch = useDispatch();
  const { notificationHistoryData, updateHistorySuccess } = useSelector(
    (state) => state.notification
  );

 
  // Log messages whenever it changes
  useEffect(() => {
    dispatch(getNotificationHistory());
  }, []);
  useEffect(() => {
    if (updateHistorySuccess) {
      dispatch(setUpdateHistorySuccess());
    }
    const timeout = setTimeout(() => {
      dispatch(getNotificationHistory());
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [updateHistorySuccess]);

  useEffect(() => {
    if(notificationHistoryData.length > 0){
      let filteredChatData = notificationHistoryData.filter(item => item.call_to_action === "/user-chat");
      let filterWithoutChatData = notificationHistoryData.filter(item => item.call_to_action !== "/user-chat");

      setFilteredChatArr(filteredChatData);
      dispatch(setFormattedNotificationData([...filterWithoutChatData, {...filteredChatData[0], description: 'You have got a new message'}]));

    }
  },[notificationHistoryData]);

  

  window.Notification && window.Notification.requestPermission && window.Notification.requestPermission().then((result) => {
    console.log("browser notification notification",result);
  });

  const handleClearAll = () => {
    // console.log('------clear notification--------');
    dispatch(clearAllNotifications());
  }

  console.log('------notf data-------', formattedNotificationData,notificationHistoryData)
  

  return (
    <>
      {/* <TopNav /> */}

      <Row className="w-full h-full sm:min-h-screen sm:bg-neutral-100 flex justify-center py-2">
        <Col className="bg-white w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5" style={{    minHeight: "100vh"}}>
          <Row className="w-full flex justify-between items-center my-3.5 gap-x-3">
            <Row className="w-4/6 justify-start items-center gap-x-2">

            <Col>
              <Button
                icon={<LeftOutlined />}
                style={{
                  color: theme.textColor,
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                }}
                size="large"
                onClick={() => navigate(-1)}
              />
            </Col>
            <Col>
              <Typography
                className="font-bold"
                variant="h6"
                style={{ fontFamily: "OnestRegular" }}
              >
                Notifications
              </Typography>
            </Col>
            </Row>

            <Row className="w-1/6 justify-end items-center">
              {
                notificationHistoryData.length > 0 ? 
                  (
                    <>
                      <Typography
                        className="font-bold"
                        variant="p"
                        style={{ fontFamily: "OnestRegular", cursor: 'pointer', color: theme.colorShade2 }}
                        onClick={handleClearAll}
                      >
                        Clear all
                      </Typography>
                    </>
                  )
                :
                  <></>
              }

            </Row>
          </Row>

          <Row className="w-full justify-start items-center my-3.5 gap-x-3">
            {
              (notificationHistoryData.length > 0 && formattedNotificationData.length > 0) ? 
            
              formattedNotificationData.filter(obj => ('id' in obj)).map((message, index) => (
                <Col className="my-2 w-full">
                  <NotificationCard key={message.id} message={message} filteredChatArr={filteredChatArr} />
                </Col>
              ))

              : 

              <Col className="w-10/12 mt-2">
                <>You are up to date. No new notifications !</>
              </Col>
              
            
            }
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default layout(Notifications);
