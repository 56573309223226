import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getItem, setItem } from '../../utility/localStorageControl';
import { setActiveOnboardForm } from './authSlice';

import { ApiService } from '../../config/apiService';


export const initialState = {
    profileData: null,
    cuisinesData: null,
    chefDetailsResData: null,
    profileImage: ["https://hello.com"],
    restaurantId: null, 
    chefName: null,
    aboutRestaurantInfo: null,
    chefTypeResData: null,
    chefPhotosResData: null,
    daysData: null,

    // currentChefStep: getItem('onboard_step') || 0,
    currentChefStep: 0,
    currentUserStep: 0,

    isChefImageEmpty: false,
    bannerImage: [],
    chefImage: [],
    foodImages: [],
    restaurantDetailsData: null,
    restaurantTypeData: null,
    restaurantImagesData: null,

    chefReviewsResponse: null,
    chefReviews: [],
    initialLoadedChefReviewsCount: 1,

    cuisinesList: null,
    tagList: null,
    dietaryPreferenceList: null,
    deliveryTypeList: null,
    businessTypeInput: null,
    businessTypeOthers: null,

    loading: false,
    error: null,
    status: 'idle',
};


export const getProfile = createAsyncThunk('profile/getProfile', async (data, { rejectWithValue }) => {
  
    try {

      const result = await ApiService.get('/profile', data);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 

      return result.data.data;
      
    } catch (err) {
      return rejectWithValue(err);
    }
  
});

export const createProfile = createAsyncThunk('profile/createProfile', async (data, { rejectWithValue }) => {
  
    try {

      const result = await ApiService.post('/profile', data);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
  
      // return data; // return data response as api response returns success msg only
      return result.data;
      
    } catch (err) {
      return rejectWithValue(err);
    }
  
});

export const updateProfile = createAsyncThunk('profile/updateProfile', async (data, { rejectWithValue }) => {

    // console.log('update profile= ',data)

    const reqData = {
      full_name: data.full_name,
      email: data.email,
      description: data.description,
      image_url: data.profile_image_url
    }
  
    try {

      const result = await ApiService.put('/profile', reqData);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
  
      return result.data;
      
    } catch (err) {
      return rejectWithValue(err);
    }
  
});

export const uploadBannerImage = createAsyncThunk('profile/uploadBannerImage', async (data, { rejectWithValue }) => {
  
  // const formdata = new FormData();
  // formdata.append('file', data.file);
  
  // console.log('img= ',data)

  try {
    const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // console.log({...result.data, originalName: data.file.name});
    return {...result.data, originalName: data.file.name};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const uploadChefImage = createAsyncThunk('profile/uploadChefImage', async (data, { rejectWithValue, dispatch }) => {
  
    // const formdata = new FormData();
    // formdata.append('file', data.file);
    
    // console.log('img= ',data)

    try {
      const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
      // console.log({...result.data, originalName: data.file.name});
      dispatch(setIsChefImageEmpty(false))
      return {...result.data, originalName: data.file.name};
      
    } catch (err) {
      return rejectWithValue(err);
    }
  
});

export const foodImagesUpload = createAsyncThunk('profile/foodImagesUpload', async (data, { rejectWithValue }) => {
  
  // const formdata = new FormData();
  // formdata.append('file', data);

  try {
    const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // console.log(result.data);
    return {...result.data, originalName: data.file.name};
    // return result.data.url;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const createChefDetails = createAsyncThunk('profile/createChefDetails', async (data, { rejectWithValue ,dispatch}) => {

  console.log('create info call= ', data.reqData);

  const regex = /\+91/;
  const checkNumber = (number) => {
    if(regex.test(data.alternate_phone)){
      return data.alternate_phone ;
    }else{
      return `+91${data.alternate_phone}`;
    }
  }
  
  const reqData = {
    restaurant_name: data.chef_name,
    // restaurant_description: data.chef_description,
    description: data.chef_description,
    restaurant_address: data.address,
    restaurant_mobile_number: regex.test(data.phone) ? data.phone :  `+91${data.phone}`,
    restaurant_email: data.email,
    restaurant_owner: {
        owner_name: data.chef_name,
        owner_email: data.email,
        owner_mobile_number: data.alternate_phone ? checkNumber(data.alternate_phone) : `+91${data.phone}`,
    },
    restaurant_location:{
        lat: `${data.location_info.lat}`,
        long: `${data.location_info.lng}`,
        place_name: data.location_info.address,
        country: data.country,
        zipcode: data.pincode,
        city: data.city,
        state: data.state,
        house_number: data.house_number,
        area: data.area
    }
  }

  console.log("create final= ", reqData);
  try {

    const result = await ApiService.post('/restaurant-information', JSON.stringify(reqData));

    if (result.status!==200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(1));
      dispatch(setIsChefImageEmpty(false));
    }

    // setItem('restaurant_id', result.data.data?.restaurant_id);
    // dispatch(setActiveOnboardForm(1));
    // setItem('onboard_step', 1);

    console.log("res chef details= ", result.data.data);
    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateChefDetails = createAsyncThunk('profile/updateChefDetails', async (data, { rejectWithValue ,dispatch}) => {
  
  console.log(data);
  const regex = /\+91/;
  const checkNumber = (number) => {
    if(regex.test(data.alternate_phone)){
      return data.alternate_phone ;
    }else{
      return `+91${data.alternate_phone}`;
    }
  }
  const reqData = {
    restaurant_id: data.restaurant_id,
    restaurant_name: data.chef_name,
    // restaurant_description: data.chef_description,
    description: data.chef_description,
    restaurant_address: data.address,
    restaurant_mobile_number: regex.test(data.phone) ? data.phone :  `+91${data.phone}`,
    restaurant_email: data.email,
    restaurant_owner: {
        owner_name: data.chef_name,
        owner_email: data.email,
        owner_mobile_number: data.alternate_phone ? checkNumber(data.alternate_phone) : `+91${data.phone}`,
    },
    restaurant_location:{
        lat: `${data.location_info.lat}`,
        long: `${data.location_info.lng}`,
        place_name: `${data.location_info.address}`,
        country: data.country,
        zipcode: data.pincode,
        city: data.city,
        state: data.state,
        house_number: data.house_number,
        area: data.area
    }
  }

  console.log("update final---------------------- ", reqData);
  try {

    const result = await ApiService.put('/restaurant-information', reqData);

    if (result.status!==200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(1));
    } 

    // if(getItem('onboard_step') !== 3){
    //   if(getItem('onboard_step') === 2){
    //     setItem('onboard_step', 2);
    //   }
    //   if(getItem('onboard_step') === 1){
    //     setItem('onboard_step', 1);
    //   }
    // }
    // dispatch(setActiveOnboardForm(result.data.step));

    console.log("res chef details= ", result.data.data);
    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const createChefType = createAsyncThunk('profile/createChefType', async (data, { rejectWithValue,dispatch }) => {
  // const stateData = getState();
  console.log("data== ", data);

  const reqData = {
    restaurant_id: data.restaurant_id,
    // restaurant_tagline: 'test tag',
    kitchen_type: {
      // dine_in_only: data.business_type.includes('dine'),
      // delivery_only: data.business_type.includes('delivery'),
      // catering: data.business_type.includes('catering'),
      // workshop: data.business_type.includes('workshop'),

      home_delivery: data.business_type.includes('home_delivery'),
      party_catering: data.business_type.includes('party_catering'),
      cooking_workshop: data.business_type.includes('cooking_workshop'),
      meal_subscription: data.business_type.includes('meal_subscription'),
      tiffin_service: data.business_type.includes('tiffin_service'),

      other: (data.business_type_others && data.business_type_others !== '') ? data.business_type_others : 'none',
    },
    // opening_hours: null,
    cuisines: data.cuisines || [],
    outlets:[],
    packaging:[],
    tags: data.tags || [],
    dietary_preferences: data.dietaryPereference || [],
    delivery_type: data.deliveryType ? [data.deliveryType] : []
  }

  console.log("final= ", reqData);
  try {

    const result = await ApiService.post('/type-and-time', reqData);

    if (result.status>299|| result.state<200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(2));
    }

    // setItem('onboard_step', 2);
    // dispatch(setActiveOnboardForm(2));
    console.log("res chef details= ", result.data);
    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateChefType = createAsyncThunk('profile/updateChefType', async (data, { rejectWithValue ,dispatch}) => {
  // const stateData = getState();
  console.log("data= ", data);

  const reqData = {
    restaurant_id: data.restaurant_id,
    // restaurant_tagline: 'test tag',
    kitchen_type: {
      // dine_in_only: data.business_type.includes('dine'),
      // delivery_only: data.business_type.includes('delivery'),
      // catering: data.business_type.includes('catering'),
      // workshop: data.business_type.includes('workshop'),

      home_delivery: data.business_type.includes('home_delivery'),
      party_catering: data.business_type.includes('party_catering'),
      cooking_workshop: data.business_type.includes('cooking_workshop'),
      meal_subscription: data.business_type.includes('meal_subscription'),
      tiffin_service: data.business_type.includes('tiffin_service'),
      
      other: (data.business_type_others && data.business_type_others !== '') ? data.business_type_others : 'none',
    },
    // opening_hours: null,
    cuisines: data.cuisines || data.cuisine_select,
    outlets:[],
    packaging:[],
    tags: data.tags || data.dietary_preference_select,
    dietary_preferences: data.dietaryPereference || data.dietary_type_select,
    delivery_type: data.deliveryType ? [data.deliveryType] : data.delivery_select
  }

  console.log("final= ", reqData);
  try {

    const result = await ApiService.put('/type-and-time', reqData);

    if (result.status>299|| result.state<200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(2));
    }

    // if(getItem('onboard_step') !== 3){
    //   setItem('onboard_step', 2);
    // }
    // dispatch(setActiveOnboardForm(2));

    console.log("res chef details= ", result.data);
    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const onboardImagesSubmit = createAsyncThunk('profile/onboardImagesSubmit', async (data, { rejectWithValue,dispatch }) => {
  
  const finalData = {
    restaurant_id: data.reqData.restaurant_id,
    menu: data.reqData.menu || [],
    food: data.reqData.food || [],
    restaurant: data.reqData.restaurant || [],
    banner: data.reqData.banner || [],
    profile: data.reqData.profile || []
  }

  console.log("final= ", finalData);

  try {

    const result = await ApiService.post('/restaurant-food-image', finalData);

    if (result.status>299|| result.state<200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(3));
    }

    // console.log("uploadimage= ", result.data);
    // setItem('onboard_step', 3);
    // dispatch(setActiveOnboardForm(3));
    return {resData: result.data, callback: data};
    // return {resData: result.data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const onboardImagesUpdate = createAsyncThunk('profile/onboardImagesUpdate', async (data, { rejectWithValue,dispatch }) => {
  
  const finalData = {
    restaurant_id: data.reqData.restaurant_id,
    menu: data.reqData.menu || [],
    food: data.reqData.food || [],
    restaurant: data.reqData.restaurant || [],
    banner: data.reqData.banner || [],
    profile: data.reqData.profile || []
  }

  console.log("final= ", finalData);

  try {

    const result = await ApiService.put('/restaurant-food-image', finalData);

    if (result.status>299|| result.state<200 || result.data.error) {
      return rejectWithValue(result.data.message);
    }else{
      dispatch(setActiveOnboardForm(3));
    }

    // console.log("uploadimage= ", result.data);
    // setItem('onboard_step', 3);
    // dispatch(setActiveOnboardForm(3));
    // return {resData: result.data, callback: data};
    return {resData: result.data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getChefRestaurantId = createAsyncThunk('profile/getChefRestaurantId', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/chef-restaurant');
    if (result.status !== 200  || result.data.error) {

      return rejectWithValue(result.data.message);
    } 
    return result.data.data;
    
  } catch (err) {
    console.log("getChefRestaurantId err",err)
    return rejectWithValue(err);
  }

});

export const CreateCuisine = createAsyncThunk('profile/CreateCuisine', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.post('/cuisine', data.reqData);

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // return {resData: result.data.data, callback: data};
    return {resData: result.data.data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getRestaurantInfo = createAsyncThunk('profile/getRestaurantInfo', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/restaurant-information/${data}`);

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getRestaurantDetailsInfo = createAsyncThunk('profile/getRestaurantDetailsInfo', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/restaurant-information/${data}`);

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getRestaurantTypeInfo = createAsyncThunk('profile/getRestaurantTypeInfo', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/type-and-time/${data}`);

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getRestaurantImageInfo = createAsyncThunk('profile/getRestaurantImageInfo', async (data, { rejectWithValue }) => {
  const fetchedRestaurantImg = [];
  const fetchedBannerImg = [];

  try {

    const result = await ApiService.get('/restaurant-food-image');

    if (result.status !== 200 ||  result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    if(result.data.data){
      result.data.data.forEach(item => {
          if(item.image_type === 'restaurant'){
              fetchedRestaurantImg.push({ 
                id: item.image_id, 
                uid: item.image_id, 
                name: item.name, 
                url: item.image_url, 
                thumbUrl: item.image_url, 
                status: 'done' 
              });
          }
          if(item.image_type === 'banner'){
              fetchedBannerImg.push({ 
                id: item.image_id, 
                uid: item.image_id, 
                name: item.name, 
                url: item.image_url, 
                thumbUrl: item.image_url, 
                status: 'done' 
              });
          }
      })
  }

    return {fullData: result.data.data, chefImgData: fetchedRestaurantImg, bannerImgData: fetchedBannerImg };
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getAboutRestaurant = createAsyncThunk('profile/getAboutRestaurant', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/about-restaurant/${data}`);

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateChefNotification = createAsyncThunk('profile/updateChefNotification', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.put('/restaurant-notification', data);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateChefAvailability = createAsyncThunk('profile/updateChefAvailability', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.put('/restaurant-availability', data);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getChefReviews = createAsyncThunk('profile/getChefReviews', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/restaurant/${data}/reviews?page=${state.profile.initialLoadedChefReviewsCount}&per_page=5`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const postChefReview = createAsyncThunk('profile/postChefReview', async (data, { rejectWithValue }) => {

  console.log('chef review data---------------',data);
  const reqData = {
    review: data.review && data.review !== undefined ? data.review : '',
    rating: parseFloat(data.rating)
  }
  
  try {
    const result = await ApiService.post(`/restaurant/${data.chefId}/ratings`, reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return {resData : result.data.data, callback: data };
    
  } catch (err) {
    return rejectWithValue(err);
  }

});


const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    
    setProfileError: (state, action) => {
      state.error = action.payload;
    },
    setBannerImage: (state, action) => {
      state.bannerImage = action.payload;
    },
    setChefImage: (state, action) => {
      state.chefImage = action.payload;
    },
    setFoodImages: (state, action) => {
      state.foodImages = action.payload;
    },
    setCurrentChefStep: (state,action) => {
      state.currentChefStep = action.payload;
    },
    setInitialLoadedChefReviewsCount: (state, action) => {
      state.initialLoadedChefReviewsCount += action.payload;
    },
    setInitialLoadedChefReviewsCountReset: (state) => {
      state.initialLoadedChefReviewsCount = 1;
    },
    setChefReviews: (state, action) => {
      state.chefReviews = action.payload;
    },
    setCurrentUserStep: (state,action) => {
      state.currentUserStep = action.payload;
    },
    setChefName: (state, action) => {
      state.chefName = action.payload;
    },

    setAboutRestaurantInfo: (state, action) => {
      state.aboutRestaurantInfo = action.payload;
    },

    setLogoutOpsProfile: () => initialState,

    // setLogoutOpsProfile: (state) => {
    //   state.chefName = null;
    //   state.aboutRestaurantInfo = null;
    //   state.bannerImage = [];
    //   state.chefImage = [];
    //   state.restaurantImagesData = null;
    //   state.restaurantId = null;
    //   state.restaurantDetailsData =  null;
    //   state.restaurantTypeData = null;
    //   state.chefDetailsResData = null;
    //   state.chefTypeResData = null;
    //   state.chefPhotosResData = null;
    //   state.chefReviewsResponse = null;
    //   state.chefReviews = [];
    //   state.businessTypeInput = null;
    // },

    setCuisinesList: (state, action) => {
      state.cuisinesList = action.payload;
    },
    setTagList: (state, action) => {
      state.tagList = action.payload;
    },
    setDeliveryTypeData: (state, action) => {
      state.deliveryTypeList = action.payload;
    },
    setDietaryPreferenceList: (state, action) => {
      state.dietaryPreferenceList = action.payload;
    },
    setBusinessTypeInput: (state, action) => {
      state.businessTypeInput = action.payload;
    },
    setBusinessTypeOthers: (state, action) => {
      state.businessTypeOthers = action.payload;
    },
    setIsChefImageEmpty: (state, action) => {
      state.isChefImageEmpty = action.payload;
    },

    setChefReviewsResponseReset: (state, action) => {
      state.chefReviewsResponse = null;
      state.chefReviews = [];
    }

  },
  extraReducers: (builder) => {
    builder
        .addCase(getProfile.pending, (state) => {
            state.loading = true;
        })
        .addCase(getProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profileData = action.payload;
        })
        .addCase(getProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(createProfile.pending, (state) => {
            state.loading = true;
        })
        .addCase(createProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profileData = action.payload;
        })
        .addCase(createProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(updateProfile.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profileData = action.payload;
        })
        .addCase(updateProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(createChefDetails.pending, (state) => {
            state.loading = true;
        })
        .addCase(createChefDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.chefDetailsResData = action.payload;
            state.restaurantDetailsData = action.payload;
            state.restaurantId = action.payload.restaurant_id;
            state.currentChefStep = 1;
        })
        .addCase(createChefDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(updateChefDetails.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateChefDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.chefDetailsResData = action.payload;
            state.restaurantDetailsData = action.payload;
            // state.restaurantId = action.payload.restaurant_id;
            state.currentChefStep = 1;
            // state.currentChefStep = state.currentChefStep === 1 ? 1 : state.currentChefStep === 2 ? 2 : state.currentChefStep === 3 ? 1 : 3;
        })
        .addCase(updateChefDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(createChefType.pending, (state) => {
          state.loading = true;
        })
        .addCase(createChefType.fulfilled, (state, action) => {
            state.loading = false;
            state.chefTypeResData = action.payload;
            state.currentChefStep = 2;
            state.cuisinesList = null;
            state.tagList = null;
            state.dietaryPreferenceList = null;
            state.deliveryTypeList = null;
            state.businessTypeInput = null;
            state.businessTypeOthers = null;
        })
        .addCase(createChefType.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(updateChefType.pending, (state) => {
          state.loading = true;
        })
        .addCase(updateChefType.fulfilled, (state, action) => {
            state.loading = false;
            state.chefTypeResData = action.payload;
            state.restaurantTypeData = action.payload.data;
            state.currentChefStep = 2;
            // state.currentChefStep = 2;
        })
        .addCase(updateChefType.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getChefRestaurantId.pending, (state) => {
          state.loading = true;
        })
        .addCase(getChefRestaurantId.fulfilled, (state, action) => {
            state.loading = false;
            console.log("getChefRestaurantId",action)
            state.restaurantId = action.payload.id;
            state.chefName = action.payload.name
        })
        .addCase(getChefRestaurantId.rejected, (state, action) => {
            state.loading = false;
            console.log("getChefRestaurantId.rejected",action);
            state.error = action.payload
        })

        .addCase(uploadBannerImage.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        })
        .addCase(uploadBannerImage.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'done';
            state.bannerImage = [action.payload];
        })
        .addCase(uploadBannerImage.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload
        })

        .addCase(uploadChefImage.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        })
        .addCase(uploadChefImage.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'done';
            state.chefImage = [action.payload];
        })
        .addCase(uploadChefImage.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload
        })

        .addCase(foodImagesUpload.pending, (state) => {
          state.loading = true;
          state.status = 'loading';
        })
        .addCase(foodImagesUpload.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'done';
            state.foodImages = [...state.foodImages, action.payload];
        })
        .addCase(foodImagesUpload.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload
        })

        .addCase(getRestaurantDetailsInfo.pending, (state) => {
            state.loading = true;
        })
        .addCase(getRestaurantDetailsInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.restaurantDetailsData = action.payload;
        })
        .addCase(getRestaurantDetailsInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getRestaurantTypeInfo.pending, (state) => {
            state.loading = true;
        })
        .addCase(getRestaurantTypeInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.restaurantTypeData = action.payload;
        })
        .addCase(getRestaurantTypeInfo.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
        })

        .addCase(onboardImagesSubmit.pending, (state) => {
          state.loading = true;
        })
        .addCase(onboardImagesSubmit.fulfilled, (state, action) => {
            state.loading = false;
            state.currentChefStep = 3;
            
            if (action.payload.callback) {
              action.payload.callback.callback();
            }

        })
        .addCase(onboardImagesSubmit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(onboardImagesUpdate.pending, (state) => {
          state.loading = true;
        })
        .addCase(onboardImagesUpdate.fulfilled, (state, action) => {
            state.loading = false;
            // console.log("onboardImagesUpdate.fulfilled",action);
            state.chefPhotosResData = action.payload.resData;
            state.currentChefStep = 3;
            // if (action.payload.callback) {
            //   action.payload.callback.callback();
            // }
        })
        .addCase(onboardImagesUpdate.rejected, (state, action) => {
            state.loading = false;
            // console.log("onboardImagesUpdate.rejected",action);
            state.error = action.payload
        })

        .addCase(CreateCuisine.pending, (state) => {
          state.loading = true;
        })
        .addCase(CreateCuisine.fulfilled, (state, action) => {
            state.loading = false;
            state.cuisinesData = [...state.cuisinesData, action.payload.resData];
            // if (action.payload.callback) {
            //   action.payload.callback.callback();
            // }
        })
        .addCase(CreateCuisine.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        

        .addCase(getRestaurantImageInfo.pending, (state) => {
          state.loading = true;
        })
        .addCase(getRestaurantImageInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.restaurantImagesData = action.payload.fullData;
            state.chefImage = action.payload.chefImgData;
            state.bannerImage = action.payload.bannerImgData;
        })
        .addCase(getRestaurantImageInfo.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
        })

        .addCase(getAboutRestaurant.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAboutRestaurant.fulfilled, (state, action) => {
            state.loading = false;
            state.aboutRestaurantInfo = action.payload;
        })
        .addCase(getAboutRestaurant.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(updateChefNotification.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateChefNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.aboutRestaurantInfo = action.payload;
        })
        .addCase(updateChefNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(updateChefAvailability.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateChefAvailability.fulfilled, (state, action) => {
            state.loading = false;
            state.aboutRestaurantInfo = action.payload;
        })
        .addCase(updateChefAvailability.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getChefReviews.pending, (state) => {
            state.loading = true;
        })
        .addCase(getChefReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.chefReviewsResponse = action.payload;
            state.chefReviews = state.initialLoadedChefReviewsCount > 1 ? [...state.chefReviews, ...action.payload.data] : action.payload.data;
        })
        .addCase(getChefReviews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        .addCase(postChefReview.pending, (state) => {
            state.loading = true;
        })
        .addCase(postChefReview.fulfilled, (state, action) => {
            state.loading = false;
            state.chefReviews = [action.payload.resData, ...state.chefReviews];
            if (action.payload.callback && action.payload.callback?.callback) {
              action.payload.callback.callback();
            }
        })
        .addCase(postChefReview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
  }
});

export const { 
  setProfileError, 
  setBannerImage, 
  setChefImage, 
  setFoodImages, 
  setCurrentChefStep,
  setInitialLoadedChefReviewsCount ,
  setCurrentUserStep,
  setChefName,
  setLogoutOpsProfile,
  setCuisinesList,
  setTagList,
  setDeliveryTypeData,
  setDietaryPreferenceList,
  setBusinessTypeInput,
  setBusinessTypeOthers,
  setInitialLoadedChefReviewsCountReset,
  setChefReviews,
  setAboutRestaurantInfo,
  setIsChefImageEmpty,
  setChefReviewsResponseReset
} = profileSlice.actions;

export default profileSlice.reducer;
