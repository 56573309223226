/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import usePubNub from "../../hooks/pubNubNotification";
import { getNotificationHistory, setFormattedNotificationData } from "../../redux/slices/notificationSlice";
import {
  setUserLocation,
  setLocationFetchLoader,
  setUserAddress,
} from "../../redux/slices/userSlice";
import {
  getBestRatedChefs,
  getTopRatedDishes,
} from "../../redux/slices/foodSlice";
import {
  getNearbyPosts,
  getNearbyUserPosts,
  setNearByPostResponseReset,
  setInitialNearbyPostsCountReset
} from "../../redux/slices/feedSlice";
import locationHeadLayout from "../UserLayout/locationHeadLayout";
import { setItem, getItem } from "../../utility/localStorageControl";
import LoadingSpinner from "../../components/LoadingSpinner";

const SearchInputSection = lazy(() => import("./sections/SearchInputSection"));
const BestSellingDishSection = lazy(() =>
  import("./sections/BestSellingDishSection")
);
const ChefSections = lazy(() => import("./sections/ChefSections"));
const PostsSections = lazy(() => import("./sections/PostsSections"));

const UserHome = ({ style }) => {
  window.Notification && window.Notification.requestPermission && window.Notification.requestPermission().then((result) => {
    console.log("browser notification home",result);
  });
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);
  const locationFetchLoader = useSelector(
    (state) => state.user.locationFetchLoader
  );
  const initialNearbyPostsCount = useSelector(
    (state) => state.feed.initialNearbyPostsCount
  );
  const initialLoadedTopRatedDishCount = useSelector(
    (state) => state.food.initialLoadedTopRatedDishCount
  );
  const initialLoadedStartRatedChefCount = useSelector(
    (state) => state.food.initialLoadedStartRatedChefCount
  );
  const user = useSelector((state) => state.auth.user);
  const { notificationHistoryData } = useSelector(
    (state) => state.notification
  );
  const iOS = useSelector((state) => state.user.iOS);

  const identity = useSelector((state) => state?.auth?.user?.identity);
  const mypubnub = usePubNub(identity);
  const options = {
    enableHighAccuracy: true,
    timeout: 50000,
    maximumAge: 0,
  };

  const getFormattedUserLocation = (inputArr) => {
    let outputArr = [];
    const searchData = ["locality"];

    searchData.forEach((item) => {
      let result = inputArr.find((obj) => obj.types.includes(item));
      outputArr.push(result);
    });
    return outputArr;
  };

  function success(pos) {
    const crd = pos.coords;

    dispatch(setUserLocation({ lat: crd.latitude, lng: crd.longitude }));
    setItem(
      "userLoc",
      JSON.stringify({ lat: crd.latitude, lng: crd.longitude })
    );
    dispatch(setLocationFetchLoader(false));

    const geocoder = new google.maps.Geocoder();
    const loc = getItem("userLoc");
    // console.log('loc-----------', loc, typeof loc)
    const latLng = new google.maps.LatLng(loc.lat, loc.lng);

    geocoder.geocode({ latLng: latLng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        // const address = results[0]?.formatted_address;
        // console.log('geo address-------------', results)

        const formattedAddr = getFormattedUserLocation(results);
        console.log("formattedAddr",formattedAddr)
        // console.log('formatted geo address-------------', formattedAddr)

        setItem("userAddress", formattedAddr[0]?.address_components[0]?.long_name);
        dispatch(setUserAddress(formattedAddr[0]?.address_components[0]?.long_name));

        // const userLocAddressElm = document.getElementsByClassName('user-location-address');

        // if (userLocAddressElm.length > 0) {
        //   console.log('reverse addrs--------', address);

        //   userLocAddressElm[0].textContent = address;
        // }
      }
    });
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    dispatch(setLocationFetchLoader(false));

    if (!userLocation) {
      dispatch(setUserLocation({ lat: 13.019144, lng: 77.646454 }));
      setItem("userLoc", JSON.stringify({ lat: 13.019144, lng: 77.646454 }));
      dispatch(setLocationFetchLoader(false));

      const geocoder = new google.maps.Geocoder();
      const loc = getItem("userLoc");
      // console.log('loc-----------', loc, typeof loc)
      const latLng = new google.maps.LatLng(loc.lat, loc.lng);

      geocoder.geocode({ latLng: latLng }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          // const address = results[0]?.formatted_address;

          const formattedAddr = getFormattedUserLocation(results);
          console.log("formattedAddr1",formattedAddr)
          // setItem('userAddress', address);
          // dispatch(setUserAddress(address));
          setItem("userAddress", formattedAddr[0]?.address_components[0]?.long_name);
          dispatch(setUserAddress(formattedAddr[0]?.address_components[0]?.long_name));
        }
      });
    }
  }

  function locationPrompt() {
    // navigator.permissions
    //   .query({ name: "geolocation" })
    //   .then(function (result) {
    //     if (result.state === "granted") {
    //       //If granted then you can directly call your function here
    //       navigator.geolocation.getCurrentPosition(success, errors, options);
    //     } else if (result.state === "prompt") {
    //       dispatch(setLocationFetchLoader(true));
    //       //If prompt then the user will be asked to give permission
    //       navigator.geolocation.getCurrentPosition(success, errors, options);
    //     } else if (result.state === "denied") {
    //       //If denied then you have to show instructions to enable location
    //       navigator.geolocation.getCurrentPosition(success, errors, options);
    //       // if(!userLocation){
    //       //     console.log('here----------------------')
    //       //     dispatch(setUserLocation({lat : 13.019144, lng : 77.646454}));
    //       //     setItem('userLoc', JSON.stringify({lat : 13.019144, lng : 77.646454}));
    //       //     dispatch(setLocationFetchLoader(false));
    //       // }
    //     }
    //   });
    if(!userLocation){
              console.log('here----------------------')
              dispatch(setUserLocation({lat : 13.019144, lng : 77.646454}));
              setItem('userLoc', JSON.stringify({lat : 13.019144, lng : 77.646454}));
              dispatch(setLocationFetchLoader(false));
          }
  }

  useEffect(() => {
    dispatch(setNearByPostResponseReset());
    dispatch(setInitialNearbyPostsCountReset());
  },[])

  useEffect(() => {
    if(user){
      dispatch(getNotificationHistory());
    }
  },[user]);

  useEffect(() => {
    if(notificationHistoryData.length > 0){
      let filteredChatData = notificationHistoryData.filter(item => item.call_to_action === "/user-chat");
      let filterWithoutChatData = notificationHistoryData.filter(item => item.call_to_action !== "/user-chat");

      dispatch(setFormattedNotificationData([...filterWithoutChatData, {...filteredChatData[0], description: 'You have got a new message'}]));

    }
  },[notificationHistoryData]);

  useEffect(() => {
    if (!userLocation) {
      if (navigator.geolocation) {
        locationPrompt();
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
    console.log("isiOS",iOS());
  }, []);

  useEffect(() => {
    if (userLocation !== null) {
      // dispatch(getBestRatedChefs(userLocation));
      dispatch(getTopRatedDishes(userLocation));
    }
  }, [userLocation, initialLoadedTopRatedDishCount]);

  useEffect(() => {
    if (userLocation !== null) {
      dispatch(getBestRatedChefs(userLocation));
      // dispatch(getTopRatedDishes(userLocation));
    }
  }, [userLocation, initialLoadedStartRatedChefCount]);

  useEffect(() => {
    if (userLocation !== null) {
      if (user?.access_token) {
        dispatch(getNearbyUserPosts(userLocation));
      } else {
        dispatch(getNearbyPosts(userLocation));
      }
    }
  }, [userLocation, initialNearbyPostsCount]);

  // useEffect(() => {
  //   if (userLocation) {
  //     const geocoder = new google.maps.Geocoder();
  //     // console.log('loc-----------', loc, typeof loc)
  //     const latLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);

  //     geocoder.geocode({ latLng: latLng }, function (results, status) {
  //       if (status === google.maps.GeocoderStatus.OK) {
  //         // const address = results[0]?.formatted_address;

  //         const formattedAddr = getFormattedUserLocation(results);
  //         console.log("formattedAddr3",formattedAddr)
  //         // setItem('userAddress', address);
  //         // dispatch(setUserAddress(address));
  //         setItem("userAddress", formattedAddr[0]?.address_components[0]?.long_name);
  //         dispatch(setUserAddress(formattedAddr[0]?.address_components[0]?.long_name));
  //       }
  //     });
  //   }
  // }, [userLocation?.lat, userLocation?.lng]);

  return (
    <>
      <Suspense  fallback={<LoadingSpinner />}>
        <Row
          className="w-full sm:bg-neutral-100 flex flex-col justify-start pt-2"
          style={style}
        >
          {locationFetchLoader ? (
            <>
              <LoadingSpinner />
            </>
          ) :  (
            <>
                <Col className="w-full flex flex-col justify-start bg-white">
                  <Row className="w-full flex flex-col items-center mt-1.5 mb-2.5">
                    <SearchInputSection />
                  </Row>

                  <Row className="w-full flex flex-col items-center">
                    <BestSellingDishSection />
                  </Row>

                  <Row className="w-full flex flex-col items-center">
                    <ChefSections />
                  </Row>

                  <Row className="w-full flex flex-col items-center">
                    <PostsSections />
                  </Row>
                </Col>
            </>
          ) }
        </Row>
      </Suspense>
    </>
  );
};

export default locationHeadLayout(UserHome);
