import { Row, Col } from 'antd';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import SafeArea from 'react-safe-area-component'

import TopTitleHeadWithBack from './sections/TopTitleHeadWithBack';
import BottomNav from './sections/BottomNav';


const titleHeadWithBackLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return (
        <>
            <SafeArea>
                <Row className='flex flex-col min-h-screen' style={{paddingBottom:"56px"}} >
                    <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                        <TopTitleHeadWithBack />
                    </Col>
                    <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto'}}>
                        <ChildComponent {...props} />
                    </Col>
                    <Col className='bg-white footer w-full' style={{ flex: '0 0 auto'}}>
                        <BottomNav />
                    </Col>
                </Row>
            </SafeArea>
        </>
    )
}

export default titleHeadWithBackLayout;