/* eslint-disable no-undef */
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Row, Col } from 'antd';
import PropTypes from 'prop-types';
// import GoogleMapReact  from 'google-map-react'
import {MdLocationPin } from 'react-icons/md'
// import countryList from 'react-select-country-list'
// import Autocomplete from "react-google-autocomplete";
import { setItem } from '../../utility/localStorageControl';
import { setUserAddress } from '../../redux/slices/userSlice';
// import { UilCrosshair } from '@iconscout/react-unicons'

const { TextArea } = Input;

const getFormattedUserLocation = (inputArr) => {
    let outputArr = [];
    const searchData = ["postal_code", "country", "administrative_area_level_1", "locality"];

    searchData.forEach(item => {
        let result = inputArr.find(obj => obj.types.includes(item));
        if(result){
            // outputArr.push(item : result.long_name)
            outputArr.push({...result, [item === 'administrative_area_level_1' ? 'state' : item]: result.long_name})
        }
    })
    return outputArr;
}

const getFormattedUserAddr = (inputArr) => {
    let outputArr = [];
    const searchData = ["locality"];

    searchData.forEach(item => {
        let result = inputArr.find(obj => obj.types.includes(item));
        outputArr.push(result)
    })
    return outputArr;
}


const LocationForm = ({ locationAddressData, location,  updateLocation}) => {
    const dispatch = useDispatch();

    const [cityListOptions, setCityListOptions ] = useState([]);
    const [stateListOptions, setStateListOptions ] = useState([]);

    const cityList = useSelector(state => state.user.cityList);
    const stateList = useSelector(state => state.user.stateList);
    
    // const options = useMemo(() => [...countryList().getData().map(d=>{d.value=d.label;return d}) ], []);
    const options = [{"value":"India","label":"India"}]
    // const LocationPin = ({ text }) => (
    //     <div className="pin">
    //         <MdLocationPin className='pin-icon' />
    //         <p className="pin-text">{text}</p>
    //     </div>
    // );

    useEffect(() => {
        if(cityList !== null){
            let cityData = [];
            cityList.forEach( (city) => {
                cityData.push({ id: city.id, label: city.name, value: city.name})
            })

            setCityListOptions(cityData);
        }
    },[cityList])

    useEffect(() => {
        if(stateList !== null){
            let stateData = [];
            stateList.forEach( (state) => {
                stateData.push({ id: state.id, label: state.name, value: state.name})
            })

            setStateListOptions(stateData);
        }
    },[stateList])

    // console.log('received location info--------- ',cityListOptions, stateListOptions)

    // const geoLocOptions = {
    //     enableHighAccuracy: true,
    //     timeout: 10000,
    //     maximumAge: 0,
    // };

    // function success(pos) {
    //     const crd = pos.coords;
    //     console.log('pos= ',pos)
    //     // updateLocation(crd);
    //     updateLocation({ address:'', lat: crd.latitude, lng: crd.longitude })
    //     // console.log("Your current position is:", crd);
    //     // console.log(`Latitude : ${crd.latitude}`);
    //     // console.log(`Longitude: ${crd.longitude}`);
    //     // console.log(`More or less ${crd.accuracy} meters.`);
    // }
    
    // function errors(err) {
    //     console.warn(`ERROR(${err.code}): ${err.message}`);
    // }

    // const handleUserLocation = () => {
    //     if (navigator.geolocation) {
    //         navigator.permissions
    //             .query({ name: "geolocation" })
    //             .then(function (result) {
    //                 console.log(result);
    //                 if (result.state === "granted") {
    //                     //If granted then you can directly call your function here
    //                     navigator.geolocation.getCurrentPosition(success, errors, geoLocOptions);
    //                 } else if (result.state === "prompt") {
    //                     //If prompt then the user will be asked to give permission
    //                     navigator.geolocation.getCurrentPosition(success, errors, geoLocOptions);
    //                 } else if (result.state === "denied") {
    //                     //If denied then you have to show instructions to enable location
    //                 }
    //             });
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    // }

    const validatePincode = (_, value) => {
        const regex = /^[0-9]{6}$/; // Simple validation for a 10-digit mobile number
        if (!regex.test(value)) {
        return Promise.reject('Please enter a valid Pincode');
        }
        return Promise.resolve();
    };  


    // function reverseGeoCode(lat, lng) {

        // const geocoder = new google.maps.Geocoder();
        // const latLng = new google.maps.LatLng(lat, lng);
    
    
        // geocoder.geocode({'latLng': latLng}, function(results, status) {
        //   if (status === google.maps.GeocoderStatus.OK) {
        //     // const address = results[0].formatted_address;
        //     console.log('geo address-------------', results)
    
        //     const formattedAddr = getFormattedUserAddr(results);
    
        //     console.log('formatted geo address-------------', formattedAddr)
    
        //     setItem('userAddress', formattedAddr[0].formatted_address);
        //     dispatch(setUserAddress(formattedAddr[0].formatted_address));
    
        //   }
        // })
    // }
    
    
    // const handleApiLoaded = (map, maps)=>{
    //     console.log('maps',map,maps);
    // }
    return(
        <>
            {/*<Form.Item
                name="search"
                className=''
                // rules={[
                //     {
                //         required: true,
                //         message: 'Location is required',
                //     },
                // ]}
            >
                <Row>
                    <Col className='w-full px-0.5'>
                        <Autocomplete
                            className='onboard-form-input ant-input autocomplete'
                            defaultValue={locationAddressData ? locationAddressData?.place_name : ''}
                            apiKey={process.env.REACT_APP_MAPS_KEY} 
                            componentRestrictions={{ country: "in" }}
                            options={{types: ["geocode", "establishment"],}}
                            onPlaceSelected={(place) => {
                                console.log('place',place)
                                const resultData = getFormattedUserLocation(place.address_components)
                                // console.log([  place.formatted_address, place.geometry.location.lat(),place.geometry.location.lng()]);

                                reverseGeoCode(place.geometry.location.lat(), place.geometry.location.lng());
                                updateLocation(
                                    { address: place.formatted_address, lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
                                    resultData
                                    )
                            }}
                            placeholder='Enter Location'
                        />
                    </Col>
                </Row>
            </Form.Item> */}

            {/*<Row className='w-full h-80 py-4'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
                    defaultCenter={{lat: location.lat, lng: location.lng}}
                    defaultZoom={17}
                    center={locationAddressData ? { lat: locationAddressData?.latitude, lng:locationAddressData?.longitude, address: locationAddressData?.place_name } : location}
                    // yesIWantToUseGoogleMapApiInternals
                    // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}

                >
                    <LocationPin
                        lat={locationAddressData?.latitude || location.lat}
                        lng={locationAddressData?.longitude || location.lng}
                        text={location.address}
                    />
                </GoogleMapReact>
            </Row>
*/}
            <Form.Item
                    className=''
                    name="address"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Address is required',
                    //     },
                    // ]}
                >
                <TextArea className='onboard-form-input' placeholder='Address' rows={1} />
            </Form.Item>

            <Form.Item
                name="house_number"
                className=''
                // rules={[
                //     {
                //         required: true,
                //         message: 'House Number required',
                //     },
                // ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='House No./Plot No.' />
            </Form.Item> 

            <Form.Item
                name="area"
                className=''
                rules={[
                    {
                        required: true,
                        message: 'Area is required',
                    },
                ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='Area' />
            </Form.Item> 

            <Row>
                <Col className='w-1/2 pr-1.5'>
                    <Form.Item
                        name="city"
                        className=''
                        initialValue='Bangalore'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'City is required',
                        //     },
                        // ]}
                    >
                        {/* <Input className='onboard-form-input' placeholder='City'  /> */}
                        <Select disabled className='onboard-form-select' placeholder="City" defaultValue="Bangalore" options={cityListOptions} />
                    </Form.Item> 
                </Col>
                <Col className='w-1/2 pl-1.5'>
                    <Form.Item
                        name="state"
                        className=''
                        initialValue='Karnataka'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'State is required',
                        //     },
                        // ]}
                    >
                        {/* <Input className='onboard-form-input'placeholder='State'  /> */}
                        <Select disabled className='onboard-form-select' placeholder="State" defaultValue="Karnataka" options={stateListOptions} />
                    </Form.Item>
                </Col>
            </Row>
            
            <Row>
                <Col className='w-1/2 pr-1.5'>
                    <Form.Item
                        name="country"
                        className=''
                        initialValue='India'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Country is required',
                        //     },
                        // ]}
                    >
                       <Select disabled className='onboard-form-select' placeholder="Country" defaultValue="India" options={options} />
                    </Form.Item> 
                </Col>
                <Col className='w-1/2 pl-1.5'>
                    <Form.Item
                        name="pincode"
                        className=''
                        rules={[{
                            required: true,
                            message: '',
                        },
                        {
                            validator: validatePincode
                        }
                    ]}
                    >
                        {/* <InputNumber 
                            className='onboard-form-input' 
                            min={6}
                            max={6}
                            placeholder='Pincode' /> */}
                        <Input 
                            className='onboard-form-input' 
                            placeholder='Pincode'  />
                    </Form.Item>
                </Col>
            </Row>

        </>
    );
}

LocationForm.propTypes = {
    location: PropTypes.exact({
        address: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    updateLocation: PropTypes.func,
}

export default LocationForm;