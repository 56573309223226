import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import { AspectRatio, Card, CardContent, Chip, Typography } from '@mui/joy';
import onion_crossed from '../../assets/imgs/onion_crossed.png'

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import theme from '../../config/theme/themeVariable';

const DishSuggestionWithChefCard = ({ dish }) => {
    console.log('search result dish----------', dish)
    const navigate = useNavigate();
    const [ foodSymbol, setFoodSymbol ] = useState([]);
    
    useEffect(() => {
    
        if(dish && dish?.dietary_types.length > 0){
            let foodSymbols = [];

            dish?.dietary_types.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                }  else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 

            })

            setFoodSymbol(foodSymbols)
        }
    },[dish])

    const handleFoodClick = () => {
        navigate('/search/food-details', { state: { selectedDishData: dish }});
    }

    const handleChefClick = () => {
        navigate(`/search/chef-details/${dish?.restaurant?.restaurant_id}`, { state: { selectedChefData: dish?.restaurant }});
    }

    return (
        <>
            <Col className='w-full py-4 my-2' style={{cursor: "pointer"}}>
                <Card
                    variant="plain"
                    orientation="horizontal"
                    onClick={handleChefClick}
                    sx={{
                        width: '100%',
                        padding: '16px 5px',
                        backgroundColor: '#fff'
                    }}
                >
                    <AspectRatio ratio="1" sx={{ width: 90, borderRadius: '10px', backgroundColor: '#fff' }}>
                        <img
                            src={dish?.restaurant?.profile_image_url}
                            srcSet={dish?.restaurant?.profile_image_url}
                            loading="lazy"
                            alt=""
                        />
                    </AspectRatio>

                    <CardContent>
                        <Row className='w-full pl-5 flex flex-row justify-between' style={{ flexFlow: 'row'}}>
                            <Col className='flex flex-col gap-y-0.5'>
                                <Typography 
                                    variant='h6' 
                                    fontSize={18} 
                                    fontWeight={500} 
                                    fontFamily={'OnestSemibold'} 
                                    color={theme.textColor}
                                    sx={{ textTransform: 'capitalize'}}
                                >
                                    {dish?.restaurant?.restaurant_name || ''}'s Menu
                                </Typography>

                                <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                    {(dish?.restaurant?.tags.length !== 0 && dish?.restaurant?.tags.map(item => item.name).join(" | ")) || ''}
                                </Typography>

                                <Row className='flex gap-x-2 gap-y-1.5'>

                                    <Chip
                                        variant="solid"
                                        size="sm"
                                        sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5 }}
                                        className="px-1 mx-1"

                                    >
                                        <StarIcon fontSize='sm' /> {dish?.restaurant?.rating ? parseFloat(dish?.restaurant?.rating).toFixed(1) : '0.0'}
                                    </Chip>

                                    <Chip
                                        variant="solid"
                                        size="sm"
                                        sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5 }}
                                        className="px-1 mx-1"

                                    >
                                        <ChatIcon fontSize='sm' /> {dish?.restaurant?.reviews || '0'} {dish?.restaurant?.reviews === 1 ? 'Review' : 'Reviews'}
                                    </Chip>
                                </Row>
                            </Col>
                            <Col>
                                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                                    <EastOutlinedIcon />
                                </Typography>
                            </Col>
                        </Row>
                        
                    </CardContent>
                </Card> 

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    onClick={handleFoodClick}
                    sx={{
                        width: '100%',
                        flexDirection: 'row-reverse',
                    }}
                >
                    <AspectRatio ratio="1" sx={{ width: 140, borderRadius: '10px' }}>
                        <img
                            src={dish?.image}
                            srcSet={dish?.image}
                            loading="lazy"
                            alt="img"
                        />
                    </AspectRatio>
                    <CardContent sx={{ rowGap: 0 }}>

                        <Row className='gap-x-1'>
                            {foodSymbol}
                        </Row>
                        
                        {/* {
                            dish?.dietary_types.some(type => type.name === 'Non-Vegetarian') ? 
                                <AspectRatio ratio="1" sx={{ width: 20, borderRadius: '10px' }}>
                                    <img
                                        src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                        loading="lazy"
                                        width="48" 
                                        height="48"
                                        alt="non-veg-symbol"
                                    />
                                </AspectRatio>

                            :

                            dish?.dietary_types.some(type => type.name === 'Vegetarian') ?

                                <AspectRatio ratio="1" sx={{ width: 20, borderRadius: '10px' }}>
                                    <img
                                        src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                        loading="lazy"
                                        width="48" 
                                        height="48"
                                        alt="veg-symbol"
                                    />
                                </AspectRatio>

                            : 

                                <AspectRatio ratio="1" sx={{ width: 20, borderRadius: '10px', display: 'none' }}>
                                    <img
                                        src=""
                                        loading="lazy"
                                        width="48" 
                                        height="48"
                                        alt=""
                                    />
                                </AspectRatio>

                        } */}

                        {/* <AspectRatio ratio="1" sx={{ width: 20, borderRadius: '10px' }}>
                            <img
                                src=""
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio> */}

                        <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                            sx={{ textTransform: 'capitalize'}}
                        >
                            {(dish.name.length < 16 ? dish.name : `${dish.name.slice(0, 14)}...`) || ''}
                        </Typography>

                        <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            sx={{ color: theme.colorShade3 }}
                        >
                            ₹ {dish?.prices.length !== 0 ? dish.prices[0].price : ''}
                        </Typography>

                        <Typography
                            variant='p' 
                            fontSize={14} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            <StarIcon fontSize='sm' /> {dish?.average_rating ? parseFloat(dish?.average_rating).toFixed(1) :  '0.0'}
                            <span style={{ color: theme.textSecondaryColor }}>({dish?.total_reviews})</span> 
                        </Typography>

                        <Typography
                            variant='p' 
                            fontSize={14} 
                            fontWeight={400} 
                            fontFamily={'OnestRegular'} 
                            sx={{ color: theme.textSecondaryColor}}
                        >
                            {dish?.description.length < 21 ? dish?.description : `${dish.description.slice(0, 18)}...`}
                        </Typography>
                    </CardContent>
                </Card>

            </Col>           
        </>
    )
}

export default DishSuggestionWithChefCard;