/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

// import TopNav from '../Layout/sections/TopNav';
import FoodCreateForm from '../FoodCreate/sections/FoodCreateForm';
import formLayout from '../Layout/formLayout';


const FoodEdit = () => {
    const navigate = useNavigate();
    const foodCreateStep = useSelector(state => state.food.foodCreateStep);
    const selectedItemForEdit = useSelector(state => state.food.selectedItemForEdit);

    useEffect(() => {
        if(!selectedItemForEdit){
            navigate(-1);
        }
    }, [selectedItemForEdit])

    const steps = [
        'Basic Information',
        'Photos & Images',
        'Dietary Information',
    ];

    return(
        <>
            {/* <TopNav /> */}
            <Row className=' w-full min-h-full h-auto sm:flex-col sm:items-center bg-neutral-100'>
                <Col className='w-full bg-white rounded-md 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12  sm:px-3 sm:my-4 sm:pb-1' style={{    minHeight: "100vh"}} >
                    
                    <Row className='w-full flex justify-center my-4 ml-2 px-4'>
                        <h1 className='text-xl font-medium' style={{ fontFamily: 'OnestSemibold'}}>Edit Food Item</h1>
                    </Row>

                    <Row className='sm:w-full my-3 py-2 ml-2 px-4' style={{ margin: '0 auto'}}>
                        <Box sx={{ width: '100%' }}>
                        <Stepper 
                            activeStep={foodCreateStep} 
                            alternativeLabel>

                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        </Box>
                    </Row>
                    <FoodCreateForm />
                </Col>
            </Row>
        </>
    );
}

export default formLayout(FoodEdit);