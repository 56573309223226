/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Form, Select } from 'antd';


// const dummyAvlRes = [
//     { name: 'Bulk' },
//     { name: 'Seasonal' },
// ]


const FoodAvailabilitySection = ({ availabilityData = [], availability = "" , availabilityList = [], updateAvailabilityList }) => {
    // const [ availabilityList, setAvailabilityList ] = useState([]);
    // console.log('-----avl---->', availability, availabilityList)

    const availabilityOptions = [];

    if(availabilityData && availabilityData.length !== 0){
        availabilityData.forEach( (item) => {
            availabilityOptions.push({ label: item.name, value: item.name})
        })
    }

    const availabilityProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          height: '55px',
        },
        options: availabilityOptions,
        onChange: (value) => {
            console.log('---availability',value)
            updateAvailabilityList(value);
        },
        placeholder: 'Please Availability Types',
    };

    return(
        <>
            <Form.Item
                name="availability"
                initialValue={ availability ? availability.replace(/{|}|"/g, '').split(',') : [] }
                rules={[
                    {
                      required: true,
                      message: 'Please select availability',
                    },
                ]}
            >
                <Select {...availabilityProps} />
            </Form.Item>
        </>
    );
}
export default FoodAvailabilitySection;