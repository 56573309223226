import { Row, Col } from "antd";
import { Typography } from "@mui/joy";
import theme from "../../config/theme/themeVariable";

const MyMessage = (props) => {
    console.log("props",props.time,props?.message?.message?.text)
    return (
        <>
            <Row className="w-full flex justify-end">
                <Col className="w-3/6 flex flex-col">
                    <Row className="flex justify-end">
                        <Col className="flex flex-col items-end">
                            <Row
                                className="w-full flex py-2.5 px-5"
                                style={{
                                    backgroundColor: theme.colorShade2,
                                    borderRadius: "20px 0 20px 20px",
                            }}
                            >
                            <Typography
                                sx={{ color: "#fff" }}
                                variant="h6"
                                fontSize={14}
                                fontWeight={500}
                                fontFamily={"OnestRegular"}
                            >
                                {props?.message?.message?.text}
                            </Typography>
                            </Row>
        
                            <Typography
                                variant="p"
                                fontSize={12}
                                fontWeight={500}
                                fontFamily={"OnestRegular"}
                            >
                                {props?.time}
                            </Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>        
        </>
    )
}

export default MyMessage;