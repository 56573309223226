/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Collapse, Modal } from 'antd';
import { Typography, Button as MuiButton } from '@mui/material';
import { AspectRatio, Card, } from '@mui/joy';
import onion_crossed from '../../../assets/imgs/onion_crossed.png'

import theme from '../../../config/theme/themeVariable';
import { setSelectedItemForEditWithImage, DeleteDish, getFoodReviews, getDishById, setSelectedItemForView } from '../../../redux/slices/foodSlice'; 
import FoodItemsReviews from './FoodItemsReviews';

import StarIcon from '@mui/icons-material/Star';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LeftOutlined, CaretRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const FoodItemDetails = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [ isMoreActive, setIsMoreActive ] = useState(false);
    const [ foodSymbol, setFoodSymbol ] = useState([]);

    const foodItemData  = location?.state?.foodItemData;
    const selectedDishReviewsData = useSelector(state => state.food.selectedDishReviewsData);
    const selectedItemForView = useSelector(state => state.food.selectedItemForView);
    // console.log('food id--',  foodItemData, selectedItemForView);

    useEffect(() => {
        dispatch(getDishById(params.id))
        dispatch(getFoodReviews(params.id));
        // console.log('param id---------', params.id);
    },[])


    useEffect(() => {

        if(selectedItemForView && selectedItemForView?.dietary_types.length > 0){
            let foodSymbols = [];

            selectedItemForView?.dietary_types.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"   key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 
            })

            setFoodSymbol(foodSymbols)
        }

    },[selectedItemForView])

    const panelStyle = {
        marginBottom: 20,
        background: '#fff',
        border: 'none',
    };

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Reviews',
          children: <FoodItemsReviews selectedDishReviews={selectedDishReviewsData} />,
          style: panelStyle,
        },
    ];

    const handleBackBtn = () => {
        // dispatch(setSelectedItemForView(null));
        navigate(-1);
    }

    const handleFoodEdit = () => {
        dispatch(setSelectedItemForEditWithImage(selectedItemForView || foodItemData));
        navigate('/edit-food');
        // console.log('food edit btn clicked')
    }

    const showDeleteConfirm = () => {
        confirm({
          title: 'Delete this dish?',
          icon: <ExclamationCircleFilled />,
          content: 'Confirm to delete the post. After that, it will be permanently deleted.',
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          className: 'post-delete-modal',
          centered: true,
          onOk() {
            console.log('OK');
            dispatch(DeleteDish({ reqData: foodItemData, callback: () => navigate('/food') }))
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    };

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }

    const additionalDescription = ()=>{
        if(selectedItemForView && selectedItemForView.additional_information){
            if(selectedItemForView.additional_information.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor, textAlign: 'justify'}} >
                    {selectedItemForView.additional_information}
                </Typography>);
            }else{
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: 'break-all'}} >
                    {selectedItemForView.additional_information}
                </Typography>);
            }
        }else if(foodItemData && foodItemData.additional_information){
            if(foodItemData.additional_information.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor, textAlign: 'justify'}} >
                    {foodItemData.additional_information}
                </Typography>);
            }else{
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: 'break-all'}} >
                    {foodItemData.additional_information}
                </Typography>);
            }
        }else{
            return null
        }
    }

    const description = ()=>{
        if(selectedItemForView && selectedItemForView.description){
            let isLongText=false
            if(selectedItemForView.description.slice(0, 30).indexOf(" ") < 0){
                isLongText=true;
            }
            return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: isLongText?'break-all':'none' }}>
                        {selectedItemForView.description.length > 150 ? ( !isMoreActive ? selectedItemForView.description.slice(0, 150) : selectedItemForView.description ) : selectedItemForView.description }

                        <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { selectedItemForView.description?.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                    </Typography>);
        }else if(foodItemData && foodItemData.description){
            let isLongText=false
            if(foodItemData.description.slice(0, 30).indexOf(" ") < 0){
                isLongText=true;
            }
            return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: isLongText?'break-all':'none' }}>
                        {foodItemData.description.length > 150 ? ( !isMoreActive ? foodItemData.description.slice(0, 150) : foodItemData.description ) : foodItemData.description }

                        <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { foodItemData.description?.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                    </Typography>);
        }else{
            return ""
        }
    }

    const dishname = ()=>{
        if(selectedItemForView && selectedItemForView.name){
            if(selectedItemForView.name.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                    {selectedItemForView.name}
                </Typography>);
            }else{
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}} style={{textAlign: 'left', wordBreak: 'break-all'}}>
                    {selectedItemForView.name}
                </Typography>);
            }
        }else if(foodItemData && foodItemData.name){
            if(foodItemData.name.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                    {foodItemData.name}
                </Typography>);
            }else{
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}} style={{textAlign: 'left', wordBreak: 'break-all'}}>
                    {foodItemData.name}
                </Typography>);
            }
        }else{
            return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                    NA
                </Typography>);
        }
    }


    return (
        <>
            <Col className='bg-white h-full w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5' style={{minHeight:"100vh"}} >
                <Row className='w-full flex justify-between py-2'>
                    <Col span={16} className='w-full flex justify-start items-center gap-x-3'>
                        <Col>
                            <Button 
                                icon={<LeftOutlined />} 
                                onClick={handleBackBtn}
                                style={{ color: theme.textColor, boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)' }}
                                size="large" />
                        </Col>
                        <Col>
                            <Typography className='font-bold' variant="h6" style={{ fontFamily: 'OnestRegular'}}>
                                Food Details
                            </Typography>
                        </Col>
                    </Col>

                    <Col span={8} className='w-full flex justify-end gap-x-3'>
                        <MuiButton 
                            sx={{ 
                                color: theme.colorShade4, 
                                minWidth: '40px',
                                padding: '5px 10px',
                                border: `1px solid ${theme.colorShade4}`, 
                                borderRadius: '6px',
                                '&:hover': { border: `1px solid ${theme.colorShade4}`} 
                            }} 
                            onClick={handleFoodEdit}
                            variant="outlined" 
                        >
                            <EditOutlinedIcon />
                        </MuiButton>
                        <MuiButton 
                            sx={{ 
                                color: theme.deleteColor, 
                                minWidth: '40px',
                                padding: '5px 10px',
                                border: `1px solid ${theme.deleteColor}`, 
                                borderRadius: '6px',
                                '&:hover': { border: `1px solid ${theme.deleteColor}`} 
                            }} 
                            onClick={showDeleteConfirm}
                            variant="outlined" 
                         >
                            <DeleteOutlinedIcon />
                        </MuiButton>
                    </Col>
                </Row>
                <Row className='w-full flex-col'>
                    <Card variant="plain" sx={{ width: '100%', padding: '15px 0' }}>
                        <AspectRatio minHeight="120px" maxHeight="200px" sx={{ borderRadius: '7px'}}>
                            <img
                                src={selectedItemForView?.main_image?.image_url || foodItemData?.main_image?.image_url}
                                srcSet={selectedItemForView?.main_image?.image_url || foodItemData?.main_image?.image_url}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </Card>

                    <Col className='flex flex-col gap-y-2.5 break-words'>
                        <Row  className='flex items-center'>
                            <StarIcon style={{ color: '#ffd12b'}} />
                            <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                <span className='font-bold' style={{ color: '#000'}}>
                                    { 
                                        selectedItemForView?.avg_rating || selectedItemForView?.average_rating || foodItemData?.average_rating ? 
                                        parseFloat(selectedItemForView?.avg_rating).toFixed(1) || parseFloat(selectedItemForView?.average_rating).toFixed(1) || parseFloat(foodItemData?.average_rating).toFixed(1) : '0 '} 
                                </span>
                                ( {selectedItemForView?.total_reviews || foodItemData?.total_reviews} {selectedItemForView?.total_reviews === 1 ? 'Review' : 'Reviews'} )
                            </Typography>
                        </Row>

                        <Row className='w-full flex gap-x-1 mt-1'>
                            {foodSymbol}
                        </Row>

                        {dishname()}

                        <Typography fontSize="sm" level="body-xs" mb={0.5} fontFamily={'OnestRegular'} sx={{ color: '#000'}}>
                            {(selectedItemForView?.culinary_preferences !== 0 && selectedItemForView?.culinary_preferences.map(item => item.name).join(" | ")) || ''}
                        </Typography>

                        {/*<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify', hyphens: 'auto' }}>
                            {selectedItemForView?.description?.length > 150 ? ( !isMoreActive ? selectedItemForView?.description.slice(0, 150) || foodItemData?.description.slice(0, 150) : selectedItemForView?.description || foodItemData?.description ) : selectedItemForView?.description }

                            <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { selectedItemForView?.description?.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                        </Typography>*/}
                        {description()}

                        <Row className='flex flex-col'>
                            <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}  >
                                Availability:
                            </Typography>
                        
                            {/* <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ textTransform: 'capitalize'}} >
                                {
                                    (selectedItemForView?.availability.includes("{") || selectedItemForView?.availability.includes("}")) ? 
                                    `( ${selectedItemForView?.availability.replace(/{|}|"/g, '')} )`
                                    :
                                    `( ${selectedItemForView?.availability} )`
                                } 
                            </Typography> */}
                            {
                                (selectedItemForView && selectedItemForView.availability) ? 
                                    selectedItemForView.availability.split(",").map(item => {
                                        return(
                                            <Typography variant='p' fontSize={14} fontFamily={'OnestRegular'} style={{ textTransform: 'capitalize', color: theme.textSecondaryColor}} >
                                                {item.replace(/{|}|"/g, '')}
                                            </Typography>
                                        )
                                    })
                                :
                                    (<></>)
                            }
                        </Row>

                        {
                            additionalDescription() !== null ?
                            (
                                <Row className='flex flex-col'>   
                                    <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}  >
                                        Additional Info:
                                    </Typography>
                                    {
                                        additionalDescription()
                                    }
                                </Row>
                            )
                            :
                            (<></>)

                        }

                        {

                            selectedItemForView && selectedItemForView.prices.length !== 0 && selectedItemForView.prices.map((item, index) => {
                                return(<>
                                    <Typography key={index} variant='h6' fontWeight={600} fontSize={18} fontFamily={'OnestRegular'}>
                                        ₹ {item.price} <span className='font-normal text-lg capitalize' style={{ color: theme.textSecondaryColor }}> ( {item.quantity} )</span>
                                    </Typography>
                                </>)
                            })
                        }
                        
                    </Col>

                    <Col className='my-2.5'>
                        <Collapse
                            bordered={false}
                            className='food-item-details-reviews'
                            defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            items={getItems(panelStyle)}
                        />
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default FoodItemDetails;