// import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Input, Card } from 'antd';
import { Typography, Rating } from '@mui/material';

import SubmitSpinner from '../../../components/SubmitSpinner';
import theme from '../../../config/theme/themeVariable';
// import ChefReviews from '../../../components/ChefReviews';
import ReviewsCard from '../../../components/ReviewsCard';
import { postChefReview, setInitialLoadedChefReviewsCount } from '../../../redux/slices/profileSlice';


const { TextArea } = Input;

const ChefReviewTabUserView = ({ chefId }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.profile.loading);
    const user = useSelector(state => state.auth.user);
    // const chef_access_token = useSelector(state => state.auth.chef_access_token);
    const chefReviews = useSelector(state => state.profile.chefReviews);
    // const initialLoadedChefReviewsCount = useSelector(state => state.profile.initialLoadedChefReviewsCount);
    const chefReviewsResponse = useSelector(state => state.profile.chefReviewsResponse);


    const [ chefRating, setChefRating ] = useState(4);

    const clearReviewField = () => {
        form.setFieldsValue({
            chef_review: ''
        });
    }

    const handlePostReview = (values) => {
        dispatch(postChefReview({ rating: chefRating.toFixed(1), review: values.chef_review, chefId: chefId?.restaurant_id, callback: clearReviewField }));
    } 

    const handleLoadMore = () => {
        dispatch(setInitialLoadedChefReviewsCount(1))
    }

    return (
        <>
            <Row className='w-full my-2'>
                <Row className='w-full my-4'>
                    {
                        chefReviews.length !== 0 ? 
                            chefReviews.filter(obj => obj.user_name !== chefId?.restaurant_name
                            ).map(item => {
                                return(
                                    <>
                                        <ReviewsCard key={item.id} review={item} />
                                    </>
                                )
                            })
                            // (<><ChefReviews /></>)
                        :
                            (
                                <>
                                    <Row className='w-full bg-gray-100 py-4 px-5 mt-2 mb-2.5' style={{ borderRadius: '5px'}}>
                                        No Reviews Found
                                    </Row>
                                </>
                            )
                    }

                        {
                            (chefReviews.length > 0 && chefReviews.length < chefReviewsResponse?.total_records) ? 
                                (
                                    <>
                                        <Col className='w-full py-2'>
                                            <Card
                                                title=""
                                                onClick={handleLoadMore}
                                                style={{
                                                    textAlign: 'center',
                                                    boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <p>Load More</p>
                                            </Card>
                                        </Col>
                                    </>
                                )
                            :
                                (<></>)
                        }
                    
                    {/* <ChefReviews /> */}
                </Row>

                <Row 
                    className='w-full flex-col items-center justify-center my-2 gap-y-3'
                    style={{ display: (user && chefId?.identity !== user?.identity) ? 'flex' : 'none'}}
                    >
                    <Col>
                        <Typography
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            Write your review
                        </Typography>   
                    </Col>

                    <Col>
                        <Rating 
                            name="half-rating" 
                            size="large" 
                            // defaultValue={chefRating} 
                            precision={1}
                            value={chefRating}
                            onChange={(event, value) => { setChefRating(value)}} 
                            sx={{ fontSize: 40 }} />
                    </Col>

                    <Col className='w-11/12'>
                        <Form
                            name="create_dish_form_1"
                            form={form}
                            className='auth-form'
                                                
                            // initialValues={{
                            //     remember: true,
                            // }}
                            onFinish={handlePostReview}
                            autoComplete="off"
                        >
                            <Form.Item
                                    className=''
                                    name="chef_review"
                                >
                                <TextArea className='onboard-form-input' placeholder='Write you review' rows={3} />
                            </Form.Item>

                            <Form.Item>
                                <Button 
                                    block
                                    htmlType="submit" 
                                    key="submit"
                                    style={{ backgroundColor: theme.primaryColor, fontFamily: 'OnestRegular'}} 
                                    type="primary" 
                                    size='large'
                                >
                                    {loading ? <SubmitSpinner /> : 'Post Review' }
                                </Button>             
                            </Form.Item> 
                        </Form>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default ChefReviewTabUserView;