import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// import OnboardNav from './sections/OnboardNav';
import OnboardForm from './sections/OnboardForm';
import formLayout from '../Layout/formLayout';


const Onboard = () => {
    // const currentChefStep = useSelector(state => state.auth.user.step);
    const currentChefStep = useSelector(state => state.profile.currentChefStep);

    const steps = [
        'Basic Information',
        'Service Details',
        'Photos & Images',
    ];

    return(
        <>
            {/* <OnboardNav /> */}
            <Row className='w-full sm:min-h-full sm:h-auto sm:flex-col  sm:items-center bg-neutral-100'>
                <Col className='w-full bg-white rounded-md 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:px-3 sm:my-4'>
                    
                    <Row className='w-full flex justify-center my-4 ml-2 px-4'>
                        <h1 className='text-xl font-medium' style={{ fontFamily: 'OnestSemibold'}}>Create Your Profile</h1>
                    </Row>

                    <Row className='sm:w-full my-3 py-2 ml-2 px-4' style={{ margin: '0 auto'}}>
                        <Box sx={{ width: '100%' }}>
                        <Stepper 
                            activeStep={ currentChefStep === 0 ? 0 : currentChefStep === 1 ? 1 : 2 } 
                            alternativeLabel>

                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        </Box>
                    </Row>
                    
                    <OnboardForm />
                </Col>
            </Row>
        </>
    );
}

export default formLayout(Onboard);