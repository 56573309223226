import { Row, Col } from "antd";
import { Typography, Avatar } from "@mui/joy";
import theme from "../../config/theme/themeVariable";

const SenderMessage = (props) => {
    return (
        <>
            <Row className="w-full flex justify-start">
                <Col className="w-3/5 flex flex-col gap-y-1">
                    <Row className="w-full flex justify-center items-center">
                        <Col span={6}>
                            <Avatar src={props?.channel?.profileUrl || ""} size="md" />
                        </Col>
                        <Col span={18}>
                            <Typography
                            variant="h3"
                            fontSize={14}
                            fontWeight={600}
                            fontFamily={"OnestRegular"}
                            sx={{ textTransform: "capitalize" }}
                            >
                            {props?.channel?.name}
                            </Typography>
                        </Col>
                    </Row>
    
                    <Row className="flex justify-end">
                        <Col span={6}></Col>
        
                        <Col span={18} className="flex">
                            <Col className="flex flex-col items-end">

                                <Row
                                    className="flex py-2.5 px-5"
                                        style={{
                                        backgroundColor: "#e4e9f0",
                                        borderRadius: "0 20px 20px 20px",
                                        justifyContent: 'center',
                                        minWidth: '80px'
                                    }}
                                >
                                    <Typography
                                        sx={{ color: theme.textColor }}
                                        variant="h6"
                                        fontSize={14}
                                        fontWeight={500}
                                        fontFamily={"OnestRegular"}
                                    >
                                        {props?.message?.message?.text}
                                    </Typography>
                                </Row>
            
                                <Typography
                                    variant="p"
                                    fontSize={12}
                                    fontWeight={500}
                                    fontFamily={"OnestRegular"}
                                >
                                    {props?.time}
                                </Typography>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>        
        </>
    )
}

export default SenderMessage;