/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, message } from 'antd';

import { setFeedError } from '../../../redux/slices/feedSlice'; 
import PostCreateForm from '../../../components/forms/PostCreateForm';

const FeedCreateForm = () => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const errorMsg = useSelector(state => state.feed.error);

    const errorMessageAlert = (errMsg) => {
        messageApi.open({
          type: 'error',
          content: errMsg,
        });
    };

    useEffect(() => {
        if(errorMsg !== null){
            errorMessageAlert(errorMsg);
            dispatch(setFeedError(null));
        }
    },[errorMsg]);

    return(
        <>
            {contextHolder}
            <Col className='w-full my-3 px-4'>
                <PostCreateForm />
            </Col>
        </>
    );
}

export default FeedCreateForm;
