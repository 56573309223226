/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Row, Image } from 'antd';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { setInitialLoadedDishCountReset } from '../../../redux/slices/foodSlice';
import { setInitialFeedLoadedCountReset } from '../../../redux/slices/feedSlice'
import { setInitialLoadedChefReviewsCountReset } from '../../../redux/slices/profileSlice';
import { isIOS , isTablet } from 'react-device-detect';

// import LocalPizzaOutlinedIcon from '@mui/icons-material/LocalPizzaOutlined';
// import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
// import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import food_gray from '../../../assets/imgs/icons/food_gray.png';
import food_colored from '../../../assets/imgs/icons/food_colored.png'

import post_gray from '../../../assets/imgs/icons/post_gray.png';
import post_colored from '../../../assets/imgs/icons/post_colored.png'

import chat_gray from '../../../assets/imgs/icons/chat_gray.png';
import chat_colored from '../../../assets/imgs/icons/chat_colored.png'

import profile_gray from '../../../assets/imgs/icons/profile_gray.png';
import profile_colored from '../../../assets/imgs/icons/profile_colored.png'


const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const chatScreenActive = useSelector(state => state.user.chatScreenActive);

    useEffect(() => {
        // console.log('url= ',location)
        if (location.pathname.includes('food')) {
            setValue('food');
            dispatch(setInitialLoadedDishCountReset());
        }
        if (location.pathname.includes('feed')) {
            setValue('feed');
            dispatch(setInitialFeedLoadedCountReset());
        }
        if (location.pathname.includes('chat')) {
            setValue('chat');
        }
        if (location.pathname.includes('profile')) {
            setValue('profile');
        }

        if (location.pathname.includes('reviews')) {
            // console.log('-------------reviews reset call-----------')
            dispatch(setInitialLoadedChefReviewsCountReset())
        }
    }, [location.pathname]);

    const handleNav = (route) => {
        navigate(route);
    }

    return(
        <>
            <Row className='footer-container bg-white fixed bottom-0 left-0 right-0 bg-white border-b-gray-300 sm:border-neutral-100 w-full flex items-center justify-center py-3.5 sm:py-0 sm:bg-neutral-100' style={{ boxShadow: '0px -2px 10px rgb(0 0 0 / 0.1)', display: chatScreenActive !== 1 ? 'flex': 'none', zIndex:1000 }}>
                <Box className="bg-white w-full 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12" style={{ paddingBottom: isTablet && isIOS && process.env.REACT_APP_BUILDFOR==='bundle'? '30px':0  }}>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction 
                            onClick={() => handleNav('/food')} 
                            label="Food" 
                            value="food" 
                            icon={<Image preview={false} width={value === 'food' ? 22 : 28 } src={value === 'food' ? food_colored : food_gray} />} 
                            />

                        <BottomNavigationAction 
                            onClick={() => handleNav('/feed')} 
                            label="Feed"
                            value="feed" 
                            icon={<Image preview={false} width={value === 'feed' ? 20 : 23 } src={value === 'feed' ? post_colored : post_gray} />} 
                        />

                        <BottomNavigationAction 
                            onClick={() => handleNav('/chat')} 
                            label="Chat" 
                            value="chat" 
                            icon={<Image preview={false} width={value === 'userchat' ? 22 : 23 } src={value === 'userchat' ? chat_colored : chat_gray} />} 
                        />

                        <BottomNavigationAction 
                            onClick={() => handleNav('/profile')} 
                            label="Profile" 
                            value="profile" 
                            icon={<Image preview={false} width={value === 'profile' ? 24 : 28 } src={value === 'profile' ? profile_colored : profile_gray} />} 
                        />

                    </BottomNavigation>
                </Box>
            </Row>
        </>
    );
}

export default BottomNav;
