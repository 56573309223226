/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "antd";
import { Typography } from "@mui/joy";
import titleHeadLayout from '../UserLayout/titleHeadLayout';
// import layout from "../Layout/layout";
import PubNubChat from "./sections/ChatSection";
// import usePubNub from "../../hooks/pubNubNotification";
import {
  updateUserIdentity,
  getProfileImage,
} from "../../redux/slices/userSlice";

const ChatList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  // const identity = useSelector((state) => state?.auth?.user?.identity);
  // const mypubnub = usePubNub(identity);
  useEffect(() => {
    dispatch(getProfileImage());
  }, []);

  if (!user?.identity) {
    dispatch(updateUserIdentity());
  }

  return (
    <>
      {user && user?.identity !== null ? (
        <>
          {/* <div className="chefnet-chat"> */}
          <PubNubChat />
          {/* </div> */}
        </>
      ) : (
        <>
          <Row className="w-full flex justify-center items-center">
            <Typography
              variant="h4"
              fontSize={16}
              fontWeight={500}
              fontFamily={"OnestRegular"}
              sx={{ textTransform: "capitalize" }}
            >
              User Identity missing. Please login again !
            </Typography>
          </Row>
        </>
      )}
    </>
  );
};

export default titleHeadLayout(ChatList);
