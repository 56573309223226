import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Image, Row } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';

// import { getItem } from '../../utility/localStorageControl';
import theme from '../../config/theme/themeVariable';
import warning from '../../assets/imgs/warning.png';

const NotFound = () => {
    const navigate = useNavigate();

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        setTimeout(() => {
            if(user && user?.access_token ){
                navigate('/');
            }else{
                navigate('/');
            }
          
        }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <>
            <Row className='flex flex-col items-center justify-center h-screen gap-y-3'>
                <Image src={warning} preview={false} style={{ width: 150}} alt="404" />
                <p>Sorry! the page you are looking for does not exist.</p>
                <p>Redirecting in 3 seconds...</p>
                <NavLink to="/">
                    <Button size="default" type="primary" style={{ backgroundColor: theme.primaryColor, display: 'none'}} to="/">
                    Return
                    </Button>
                </NavLink>
            </Row>
        </>
    );
}

export default NotFound;