/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, message } from 'antd';

import FoodCreateBasicInfoForm from '../../../components/forms/FoodCreateBasicInfoForm';
import FoodCreatePhotosForm from '../../../components/forms/FoodCreatePhotosForm';
import FoodCreateDietaryInfoForm from '../../../components/forms/FoodCreateDietaryInfoForm';

import { getChefRestaurantId } from '../../../redux/slices/profileSlice'; 
import { getCuisines, getDietaryPreference, getTag, getAvailability, getQuantity, setFoodError } from '../../../redux/slices/foodSlice';


const FoodCreateForm = () => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const foodCreateStep = useSelector(state => state.food.foodCreateStep);
    const errorMsg = useSelector(state => state.food.error);

    const errorMessageAlert = (errMsg) => {
        messageApi.open({
          type: 'error',
          content: errMsg,
        });
    };

    useEffect(() => {
        if(errorMsg !== null){
            errorMessageAlert(errorMsg);
            dispatch(setFoodError(null));
        }
    },[errorMsg]);

    useEffect(() => {
        dispatch(getChefRestaurantId())
        dispatch(getQuantity());
        dispatch(getAvailability());
        dispatch(getCuisines());
        dispatch(getDietaryPreference());
        dispatch(getTag());
    },[]);

    return(
        <>
            {contextHolder}
            <Col className='w-full my-3 px-4'>
                {
                    foodCreateStep === 0 ? 
                        (<><FoodCreateBasicInfoForm /></>)
                    :
                    foodCreateStep === 1 ? 
                        (<><FoodCreatePhotosForm /></>)
                    :
                    foodCreateStep === 2 ? 
                        (<><FoodCreateDietaryInfoForm /></>)
                    :
                        (<></>)
                }
                
            </Col>
        </>
    );
}

export default FoodCreateForm;
