import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../../config/apiService';


export const initialState = {
    selectedTab: 0,
    searchedKeyword: null,

    searchedResultDataResponse: null,
    searchedResultData: null,
    chefSearchedResults: [],
    dishSearchedResults: [],
    dietaryPreferenseFilterData: null,
    initialLoadedSearchCount: 1,

    loading: false,
    error: null,
    serverError: null,
}

export const getSearchedResult = createAsyncThunk('userSearch/getSearchedResult', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  const pattern = /[Cc]hef/g;
  let cleanedSearchTerm = '';

  if(data?.search && data?.search.trim() !== ''){
    cleanedSearchTerm = data?.search.replace(pattern, '').trim()
  }

  // console.log('----data----', cleanedSearchTerm)
  try {

    const result = await ApiService.get(`/search-chef-food?lat=${data.lat}&long=${data.lng}&page=${state.userSearch.initialLoadedSearchCount}&per_page=10&search-term=${cleanedSearchTerm}${data.dietaryPrefFilter ? `&filter_by=dietary-preference&filter_value=${data.dietaryPrefFilter}` : ''}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result?.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});



const userSearchSlice = createSlice({
  name: 'userSearch',
  initialState,
  reducers: {
    setSearchError: (state, action) => {
      state.error = action.payload;
    },

    setSelectedTab: (state, action) => {
        state.selectedTab = action.payload;
    },

    setSearchedKeyword: (state, action) => {
      state.searchedResultDataResponse = null;
      state.searchedResultData = null;
      state.chefSearchedResults = [];
      state.dishSearchedResults = [];
      state.dietaryPreferenseFilterData = null;
      state.initialLoadedSearchCount = 1;
      state.searchedKeyword = action.payload;
    },

    setChefSearchedResults: (state, action) => {
      state.chefSearchedResults = action.payload;
    },

    setDishSearchedResults: (state, action) => {
      state.dishSearchedResults = action.payload;
    },

    setDietaryPreferenseFilterData: (state, action) => {
      state.dietaryPreferenseFilterData = action.payload;
    },

    setInitialLoadedSearchCount: (state, action) => {
      state.initialLoadedSearchCount += action.payload;
    },

    setInitialLoadedSearchCountReset: (state) => {
      state.initialLoadedSearchCount = 1;
    },

    setLogoutOpsUserSearch: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchedResult.pending, (state) => {
          state.loading = true;
      })
      .addCase(getSearchedResult.fulfilled, (state, action) => {
          state.loading = false;
          state.searchedResultDataResponse = action.payload;
          state.searchedResultData =  action.payload.data;
          state.chefSearchedResults = action.payload.page !== 1 ?  [...state.chefSearchedResults, ...action.payload.data.restaurant] : action.payload.data.restaurant;
          state.dishSearchedResults = action.payload.page !== 1 ?  [...state.dishSearchedResults, ...action.payload.data.dish] : action.payload.data.dish;
      })
      .addCase(getSearchedResult.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })
  }
});

export const { 
    setSearchError,
    setSelectedTab ,
    setSearchedKeyword,
    setChefSearchedResults,
    setDishSearchedResults,
    setDietaryPreferenseFilterData,
    setLogoutOpsUserSearch,
    setInitialLoadedSearchCount,
    setInitialLoadedSearchCountReset
} = userSearchSlice.actions;

export default userSearchSlice.reducer;
