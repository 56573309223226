import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from 'antd';
import { Typography } from '@mui/material';
import { setInitialLoadedSearchCount } from '../../../redux/slices/userSearchSlice';
import DishSuggestionWithChefCard from '../../../components/cards/DishSuggestionWithChefCard';
import SubmitSpinner from '../../../components/SubmitSpinner';

const DishesList = () => {
    const dispatch = useDispatch();
    const dishSearchedResults = useSelector(state => state.userSearch.dishSearchedResults);
    const searchedResultDataResponse = useSelector(state => state.userSearch.searchedResultDataResponse);
    // const initialLoadedSearchCount = useSelector(state => state.userSearch.initialLoadedSearchCount);
    const loading = useSelector(state => state.userSearch.loading);


    const handleLoadMore = () => {
        dispatch(setInitialLoadedSearchCount(1))
    }
    return (
        <>
            <Row className='w-11/12'>  
                <Col className='w-full flex items-center justify-between'>
                    <Typography variant='h6' fontSize={18} fontWeight={500} fontFamily={'OnestSemibold'}>
                        Dishes
                    </Typography> 
                </Col>

                <Col className='w-full'>
                    {
                        dishSearchedResults && dishSearchedResults.length > 0 ? 
                            dishSearchedResults.map(item => {
                                return(
                                    <>
                                        <DishSuggestionWithChefCard dish={item} />
                                    </>
                                );
                            })
                        :
                            (<>No matching dishes found!</>)
                    }

                    {
                        // (searchedResultDataResponse && searchedResultDataResponse?.data?.dish.length >= searchedResultDataResponse?.per_page ) 
                        ( searchedResultDataResponse && dishSearchedResults && dishSearchedResults.length < searchedResultDataResponse?.total_dishes) 
                        ?
                            (<>
                                <>
                                    <Col className='w-full py-2'>
                                        <Card
                                            title=""
                                            onClick={handleLoadMore}
                                            style={{
                                                textAlign: 'center',
                                                boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {/* <p>Load More</p> */}
                                            {loading ? <><SubmitSpinner /></> : <p>Load More</p>}
                                        </Card>
                                    </Col>
                                </>
                            </>)
                        :
                            (<></>)
                    } 

                </Col>
            </Row>    
        </>
    )
}

export default DishesList;