import { Row, Col } from 'antd';

import titleHeadLayout from '../UserLayout/titleHeadLayout';
import UserProfileBannerSection from './sections/UserProfileBannerSection';
import UserProfileSettingSection from './sections/UserProfileSettingSection';

const UserProfile = () => {
    return (
        <Row className='w-full 2xs:h-full sm:h-auto sm:min-h-full sm:bg-neutral-100 flex justify-center py-2'>
            <Col className='flex flex-col items-center w-full bg-white 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12' style={{    minHeight: "100vh"}} >
                <Col className='w-10/12'>
                    <UserProfileBannerSection />
                </Col> 
                <Col className='w-10/12'>
                    <UserProfileSettingSection />
                </Col> 
            </Col>
        </Row>
    )
}

export default titleHeadLayout(UserProfile);