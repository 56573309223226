/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { addUserNotificationHistory ,setHideBottomOnChatScreen } from "../../redux/slices/notificationSlice";
import { isIOS , isTablet } from 'react-device-detect';
import { usePubNub } from "pubnub-react";
import { format } from 'timeago.js';
import { Typography, Avatar } from "@mui/joy";
import { useParams } from 'react-router';
import { enrichChannelDataList, userVerificationForNewChat } from "../../redux/slices/userSlice";


import {
    Chat,
    MessageInput,
    useUser
} from "@pubnub/react-chat-components";
import { Row, Col, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import {
    setChatScreenActive,
    setSelectedChefChatInfo,
    setIsChatStarted
} from "../../redux/slices/userSlice";
import LoadingSpinner from "../LoadingSpinner";
import RenderMessages from "./RenderMessages";


const MessageList = (props) => {
    const pubnub = usePubNub();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routeparams = useParams();
    const channel = routeparams.chatid;
    const [messages, setMessages] = useState([]);
    const [currentChannel, setCurrentChannel] = useState(null);
    const [channelMeta, setChannelMeta] = useState(null);
    const [messagesForChat, setMessagesForChat] = useState(null);
    const [nextMessageTimestamp,setNextMessageTimestamp] = useState(null)
    const [hasMoreMessages,setHasMoreMessages] = useState(false)
    const [isLoadingMessage,setIsLoadingMessage] = useState(false)
    const [sentForEnrichment,setSentForEnrichement] = useState(false);
    const [newChat,setNewChat] = useState(false);
    const chatTheme = "light";
    const theme = "light";
    const formattedChannelDataList = useSelector( (state) => state.user.formattedChannelDataList );
    const userPicIdentity = useSelector( (state) => state.user.userPicIdentity );
    const userPicIdentityPending = useSelector( (state) => state.user.userPicIdentityPending );

    // const [channelError, setChannelError] = useState(false);
    const identity = useSelector((state) => state?.auth?.user?.identity);
    
    useEffect(()=>{
      resetState();
      dispatch(setHideBottomOnChatScreen(true));
      return ()=>{
        resetState();
        dispatch(setHideBottomOnChatScreen(false));

      }
    },[])

    const resetState = ()=>{
      setMessages([])
      setMessagesForChat(null)
      setNextMessageTimestamp(null)
      setHasMoreMessages(true)
      setIsLoadingMessage(false)
    }
    const [currentUser] = useUser();
    
    const handleError = (e) => {
      console.log("Error handler: ", e);
    };
    const handleGoBack = () => {
      dispatch(setIsChatStarted(false));
      dispatch(setChatScreenActive(0));
      dispatch(setSelectedChefChatInfo(null));
      resetState();
      dispatch(setHideBottomOnChatScreen(false));
      navigate(-1);
    };
    const handleMessage = event => {
      const message = event.message;
      console.log(event)
      if (typeof message === 'string' || message.hasOwnProperty('text')) {
        postpendMessage([event]);
      }
    };
    
    const prependMessage = (oldmessage)=>{
      console.log("oldmessage,messages",oldmessage, messages);
      setMessages((messages)=>{return [...oldmessage,...messages]});
    }

    const postpendMessage = (newmessage)=>{
      setMessages((messages)=>{
        console.log("messages,newmessage",messages,newmessage);
        if(messages !== null){
          return [...messages,...newmessage]
        }else{
          return [...newmessage]
        }
      });
    }
    const fetchOldMessage = (endtime,isNew=false) => {
      console.log("endtime,isNew",endtime,isNew, channel)
      setIsLoadingMessage(true);
      const count = 2; 
      if( isNew ){
        pubnub.fetchMessages(
        {
            channels: [channel],
            count: count
        },
        (status, response) => {
          console.log("____________state , response", status,response)
          if(status && false === status.error && response && response.channels){
            console.log("---------------count check",response.channels[channel]?.length,count, response.channels[channel]);
            if(response.channels[channel]){
              setMessages(response.channels[channel]);
              if(response.channels[channel].length<count){
                setHasMoreMessages(false);
              }else{
                setNextMessageTimestamp(response.channels[channel][0].timetoken)
                setHasMoreMessages(true);

              }
            }else{
              console.log('-------------condtn--------')
              setMessages(null);
              setHasMoreMessages(false);
            }
          }
          setIsLoadingMessage(false)
        })
      }else{
        pubnub.fetchMessages(
        {
            channels: [channel],
            start: endtime,
            count: count
        },
        (status, response) => {
          console.log(" state , response", status,response)
          if(status && false === status.error && response && response.channels){
            if(response.channels[channel]){
              prependMessage(response.channels[channel]);
              if(response.channels[channel].length<count){
                setHasMoreMessages(false);
              }else{
                setNextMessageTimestamp(response.channels[channel][0].timetoken)
              }
            }else{
              setHasMoreMessages(false);
            }
          }
          setIsLoadingMessage(false)
        })
      }
    }

    useEffect(() => {
      if(currentChannel && currentUser.id){
        const listenerParams = { message: handleMessage }
        pubnub.addListener(listenerParams);
        pubnub.subscribe([channel]);
        fetchOldMessage(null,true); 
        return () => {
            pubnub.unsubscribe([channel])
            pubnub.removeListener(listenerParams)
        }
      }
    }, [currentChannel]);

    useEffect(()=>{
        if(identity && channel){
            const identities = channel.split('.');
            if(identities.length!==2){
                navigate('/404')
            }else if( -1 === identities.findIndex((i)=>{return i === identity;}) ){
                navigate('/404');
            }else{
                pubnub.objects.getChannelMetadata({
                    channel: channel
                }).then((channelResponse)=>{
                    setChannelMeta(channelResponse.data);
                }).catch((error)=>{
                    const identities = channel.split('.')
                    const o_identity=identities.find((i)=>{return i!==identity;});
                    dispatch(userVerificationForNewChat(o_identity));
                    setNewChat(true);
                    console.log("coudn't find channel.","May be new chat.");
                });
            }
        }
    },[channel,identity])

    useEffect(()=>{
        if( !newChat ){
            if(channelMeta && currentUser){
                const identities = channelMeta.id.split('.')
                const identity=identities.find((i)=>{return i!==currentUser.id;});
                const name=channelMeta.name.replace(currentUser.name,"").replace(/(^\.|\.$)/,"");
                const c = Object.assign({},channelMeta,{name:name,identity:identity});
                setSentForEnrichement(true);
                dispatch(enrichChannelDataList([c]));
            }
        }
        else{
            if(newChat && userPicIdentity && currentUser && !userPicIdentityPending ){
                console.log("userPicIdentity,userPicIdentityPending",userPicIdentity)
                pubnub.objects.setChannelMetadata({
                  channel: channel,
                  data: {
                    name: `${userPicIdentity.name}.${currentUser.name}`
                  }
                }).then(d=>{
                    console.log("new chat channel",d);
                    setChannelMeta(d.data);
                    setNewChat(false);
                  // dispatch(
                  //   enrichChannelDataList([
                  //     ...formattedChannelDataList,
                  //     d,
                  //   ])
                  // );
                }).catch((e)=>{
                    console.log("new chat channel error",e);
                })
            }
            console.log("channelMeta,currentUser, newChat , userPicIdentity", channelMeta,currentUser, newChat , userPicIdentity);
        }
        console.log("channelMeta, currentUser, newChat , userPicIdentity", channelMeta, currentUser, newChat , userPicIdentity, userPicIdentityPending);
    },[channelMeta,currentUser, newChat , userPicIdentity, userPicIdentityPending])

    useEffect(() =>{
        if(sentForEnrichment && formattedChannelDataList.length>0){
            setCurrentChannel(formattedChannelDataList[0]);
        }
    },[formattedChannelDataList,sentForEnrichment]);

    useEffect(() => {
        console.log('-------------msgs data--------',currentChannel,messages);
        if(messages){

          if(currentChannel && messages.length>0 && currentUser){
            const enrichedChannel = currentChannel;
            const user_pubnub={
              "name":enrichedChannel.name,
              "id":enrichedChannel.identifier,
              "profileUrl":enrichedChannel.profileUrl
            }
            setMessagesForChat(messages.map((m)=>{
              let m_edited={"editedText":undefined,"message":m}
              // return {"editedText":undefined,"message":m,"time":"time",isOwn:true,user:user}
              if(m.publisher){
                m_edited.isOwn= currentUser.id===m.publisher;
              }
              if(m.uuid){
                m_edited.isOwn=currentUser.id===m.uuid;
              }
              m_edited.user= user_pubnub;
              m_edited.time=format(m.message.createdAt);
              m_edited.channel=enrichedChannel;
              return m_edited;
            }))
          }else{
              setMessagesForChat(null);
          }

        }else{
          setMessagesForChat([]);
        }
    },[messages,currentChannel,currentUser]);
    const publish_new_notification=(currentChannel)=>{
        let userIdentity=currentChannel?.identity;

        if ( null === currentChannel ) {
            const identities = channel.split(".");
            if (identities.length == 2){
                const identities_data = identities.find((i)=>{ return i !== currentUser.id; });
                if ( identities_data.length === 1 ){
                    userIdentity = identities_data[0];
                }
            }
        }
        dispatch(addUserNotificationHistory({
          user_identity:userIdentity,
          sender_identity: currentUser?.id,
          sender_name: currentUser?.name
        }))
    }
    const sendMessage = async (message) => {   
        await pubnub.objects.setChannelMetadata({
          channel: channel,
          data: {
            name: `${currentChannel?.name}.${currentUser?.name}`,
            custom: {
              id: currentUser?.id,
              lastMessage: message.text,
              timestamp: new Date().getTime(),
            },
          },
        }).then(() => {
          publish_new_notification(currentChannel);
        });
    };

    console.log('---------here msgs---------', messages, messagesForChat, isLoadingMessage)

    return (
        <Chat
          currentChannel={channel}
          theme={chatTheme}
          onError={handleError}
        >
            <Row className="w-full">
                <Col className="w-full" span={24} >
                <Row className="justify-start w-full items-center py-3.5 pl-2 gap-x-3 border-t-2 border-slate-300 chat-msg-box-top">
                    <Col className="" span={4}>
                    <Button
                        icon={<LeftOutlined />}
                        onClick={handleGoBack}
                        style={{
                            color: theme.textColor,
                            boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                        }}
                        size="large"
                    />
                    </Col>

                    <Col className="items-center" span={18}>
                    <Row>
                        <Col span={4}>
                        <Avatar
                            src={
                                currentChannel ? 
                                    currentChannel.profileUrl
                                    : ""
                            }
                            size="md"
                        />
                        </Col>
                        <Col span={20}>
                        <Typography
                            variant="h3"
                            fontSize={16}
                            fontWeight={600}
                            style={{"paddingTop":"10px"}}
                            fontFamily={"OnestRegular"}
                            sx={{ textTransform: "capitalize" }}
                        >
                            {currentChannel
                            ? currentChannel?.name
                            : ""}
                        </Typography>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                <Row className="chat-msg-box-middle">
                  { messagesForChat === null && <><div className="w-full text-center"><LoadingSpinner /></div></> }
                  {
                    messagesForChat !== null && hasMoreMessages && !isLoadingMessage ? (<div className="w-full text-center py-5" style={{cursor:'pointer'}} onClick={()=>{fetchOldMessage(nextMessageTimestamp)}} > Load more</div>):<></>
                  }
                  {messagesForChat !== null && messagesForChat.length>0 ?
                  
                    <Col span={24} className="px-5 pt-2">
                      {
                        messagesForChat.map(m=>{
                          return RenderMessages(m);
                        })
                      }
                    </Col>
                  :
                    (messagesForChat !==null && !isLoadingMessage && <>
                        <div className="w-full text-center pt-5">
                          {
                            (currentChannel?.custom) ?
                              (<><p>No recent messages</p><p>(Showing chat from last seven days)</p></>)
                            :
                              (<p>No older messages</p>)  
                          }
                        </div>
                      </>)
                  }
                </Row>
                <Row className="w-full chat-msg-box-bottom bg-transparent " style={{backgroundColor: 'transparent'}} >
                    <Col  span={24} sm={{span:10}} lg={{span:8}} xxl={{span:6}} className="bg-white" style={{ paddingBottom: isTablet && isIOS && process.env.REACT_APP_BUILDFOR==='bundle'? '30px':0  }} >
                        <MessageInput typingIndicator={true} onSend={sendMessage} />
                    </Col>
                </Row>
                </Col>
            </Row>
        </Chat>
    );
}
export default MessageList;