/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button, Collapse, Upload, message, Image } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { setCurrentChefStep } from '../../redux/slices/profileSlice'; 
import { getProfileImage, setUserImageData } from '../../redux/slices/userSlice';

import {  
    uploadChefImage , 
    onboardImagesSubmit,
    setChefImage,
    onboardImagesUpdate,
    setIsChefImageEmpty
} from '../../redux/slices/profileSlice'; 

import SubmitSpinner from '../SubmitSpinner';
import theme from '../../config/theme/themeVariable';

import img_upload_icon from '../../assets/imgs/img_upload_icon.png'; 

const { Dragger } = Upload;

const validateImage = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    // console.log(file.file.type)

    return acceptedImageTypes.includes(file.file.type);
};


const ProfileImage = ({updateChefImage}) => {
  const dispatch = useDispatch();
  const chefImage = useSelector(state => state.profile.chefImage);
  const userImageData = useSelector(state => state.user.userImageData);
  const isChefImageEmpty = useSelector(state => state.profile.isChefImageEmpty);

    const customUpload = async (file) => {
        if (!validateImage(file)) {
            message.error('Please upload only image files (JPEG, PNG, GPG)');
            return;
        }else{
            try {
                await dispatch(uploadChefImage(file));
                message.success('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading Image:', error);
                message.error('Files upload failed.');
            }
        }
    };
    
    const uploadProps = {
        customRequest: (filedata) => {
           const { onSuccess, onError } = filedata;
           customUpload(filedata)
            .then(() => onSuccess())
            .catch((error) => {
              console.error('Custom upload error:', error);
              onError(error);
            });
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
          }
        },
        onRemove(file){
            // console.log('onremove= ',file, chefImage)
            const restData = chefImage.filter(item => (item.originalName || item.name) !== file.name);
            // console.log(restData);
            updateChefImage(restData);
            dispatch(setUserImageData(null));
        }
    };

    // const handleUpload = (file) => {
    // //   console.log(file);
    //   dispatch(uploadChefImage(file));
    // }  

    return(
        <>
            <Form.Item
                name="full_name"
                className=''
                style={{ border: isChefImageEmpty ? "1px solid red" : ''}}
                validateStatus={isChefImageEmpty ? 'error' : 'success'}
                help={isChefImageEmpty? 'Please upload profile image.' : ''}
            >
                  <Dragger 
                    defaultFileList={chefImage.length !== 0 ? 
                        [...chefImage] : 
                        (userImageData ? 
                            [{uid : userImageData?.image?.id, id: userImageData?.image?.id, name: userImageData?.image?.name, status: 'done', url: userImageData?.profile_pic_url, thumbUrl: userImageData?.profile_pic_url}] : 
                            [])} className='image-drag-box' 
                    listType='picture' 
                    maxCount={1} 
                    {...uploadProps} 
                    >
                        {/* <Row className='flex flex-col justify-center items-center'> */}
                            {
                                chefImage.length !== 0 ? 
                                    (
                                        <>
                                            <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                                <Image preview={false} className="w-full" src={chefImage[chefImage.length - 1].image_url || chefImage[chefImage.length - 1].url} alt='img' />
                                            </Row>
                                        </>
                                    )
                                :
                                
                                userImageData ? 
                                    (
                                        <>
                                            <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                                <Image preview={false} className="w-full" src={userImageData?.profile_pic_url} alt='img' />
                                            </Row>
                                        </>
                                    )
                                :
                                    (
                                        <>
                                            <Row className='flex flex-col justify-center items-center overflow-hidden'>
                                                <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' />
                                                <p className="ant-upload-text">Click to Upload</p>
                                            </Row>
                                        </>
                                    )

                            }
                        {/* </Row> */}
                    
                  </Dragger>
            </Form.Item> 
        </>
    );
}


const OnboardChefPhotosForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.profile.loading);
    const user = useSelector(state => state.auth.user);
    // const bannerImage = useSelector(state => state.profile.bannerImage);
    const chefImage = useSelector(state => state.profile.chefImage);
    const foodImages = useSelector(state => state.profile.foodImages);
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const currentChefStep = useSelector(state => state.profile.currentChefStep);
    const isUpdate = ( currentChefStep === user["step"] );
    const userImageData = useSelector(state => state.user.userImageData);

    const fetchedRestaurantImg = [];
    const fetchedBannerImg = [];

    useEffect(() => {
        if ( currentChefStep === 3 ){
            if ( isUpdate ){
                navigate('/profile');
            }else{
                navigate('/food');
            }
        }
        if(currentChefStep !== 3){
            console.log('profile image api call')
            dispatch(getProfileImage())
        }
    },[currentChefStep]);


    const handleProfileSubmit = () => {
        console.log("chefImage",chefImage,fetchedBannerImg,fetchedRestaurantImg,userImageData);
        if(chefImage.length === 0 && !( userImageData && userImageData.image_id) ){
            dispatch(setIsChefImageEmpty(true));
        }else{

            // const bannerImageIdArr = bannerImage.map(item => item.id);
            const chefImageIdArr = chefImage.map(item => item.id);
            const foodImagesIdArr = foodImages.map(item => item.id);



            if(user['step'] > 2){
                const data = {
                    reqData: { restaurant_id: restaurantId, food: foodImagesIdArr, restaurant: chefImageIdArr.length !== 0 ? chefImageIdArr : [userImageData?.image_id], banner: []},
                    callback: () => navigate('/profile')
                }
                dispatch(onboardImagesUpdate(data));
            }else{

                console.log(chefImageIdArr, [userImageData?.image_id])
                const data = {
                    reqData: { restaurant_id: restaurantId, food: foodImagesIdArr, restaurant: chefImageIdArr.length !== 0 ? chefImageIdArr : [userImageData?.image_id], banner: []},
                    callback: () => navigate('/food')
                }
                dispatch(onboardImagesSubmit(data));
            }

        }
    }

    const handleBack = () => {
        dispatch(setIsChefImageEmpty(false));
        dispatch(setCurrentChefStep(1))
    }

    const updateChefImage = (data) => {
        dispatch(setChefImage(data));
    }

    const panelStyle = {
      marginBottom: 20,
      background: '#fff',
      border: 'none',
    };


    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Profile Picture',
            children: <ProfileImage fetchedRestaurantImg={fetchedRestaurantImg} updateChefImage={updateChefImage} />,
            style: panelStyle,
        },
    ];

    return(
        <>
            <Form
                name="onboard_form_2"
                form={form}
                className='auth-form'
                                    
                initialValues={{
                    remember: true,
                }}
                onFinish={handleProfileSubmit}
                autoComplete="off"
            >

                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    items={getItems(panelStyle)}
                />

                <Form.Item>
                    <Row className='w-full flex'>
                        <Col className='px-4' span={12} style={{ display: setCurrentChefStep === 3 ? 'none' : 'block'}}>
                            <Button 
                                block
                                size='large'
                                onClick={handleBack}
                                disabled={loading ? true : false}
                            >
                               Go Back
                            </Button>
                        </Col>
                        <Col className='px-4' span={setCurrentChefStep === 3 ? 24 : 12}>
                            <Button 
                                block
                                htmlType="submit" 
                                key="submit" 
                                style={{ backgroundColor: theme.primaryColor}} 
                                type="primary" 
                                size='large'
                            >
                                { loading ? <SubmitSpinner /> : 'Finish'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>    
            </Form>            
        </>
    );
}

export default OnboardChefPhotosForm;
