import { useEffect, lazy, Suspense  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'antd';

import layout from "../Layout/layout";
import EmptyFoodList from './sections/EmptyFoodList';
// import FoodList from './sections/FoodList';
import { getAllDish } from '../../redux/slices/foodSlice';
import LoadingSpinner from  '../../components/LoadingSpinner';
import usePubNub from "../../hooks/pubNubNotification";
import { getChefRestaurantId, updateChefAvailability } from '../../redux/slices/profileSlice';


const FoodList = lazy(() => import('./sections/FoodList'));


const Food = ({ style }) => {
  const dispatch = useDispatch();
  const allDishData = useSelector(state => state.food.allDishData);
  const initialLoadedDishCount = useSelector(state => state.food.initialLoadedDishCount);
  const identity = useSelector((state) => state?.auth?.user?.identity);
  const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
  const allDishResponseData = useSelector(state => state.food.allDishResponseData);
  const restaurantId = useSelector(state => state.profile.restaurantId);
  const mypubnub = usePubNub(identity);
  

  useEffect(() => {
    dispatch(getChefRestaurantId());
  },[])

  useEffect(() => {
    dispatch(getAllDish());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[initialLoadedDishCount]);

  useEffect(() => {
    if(aboutRestaurantInfo?.is_available && allDishResponseData?.data.length === 0 && restaurantId){
        const reqData = {
            restaurant_id: restaurantId,
            is_available: false
        }
        dispatch(updateChefAvailability(reqData));
    }
},[aboutRestaurantInfo?.is_available, allDishResponseData, restaurantId, dispatch]);

  return (
    <Suspense
      fallback={
        <LoadingSpinner />
      }
    >
      <Row className='w-full 2xs:h-full sm:h-auto sm:min-h-full sm:bg-neutral-100 flex justify-center py-2' style={style}>
        {
          allDishData.length !== 0 ? 
              (<FoodList />)
            :
              (<EmptyFoodList />)
        }
          
      </Row>
    </Suspense>
  )
}

export default layout(Food);