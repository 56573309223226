import { useSelector, useDispatch } from "react-redux";
import { Row, Input, Col, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Tabs, TabList, Tab } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { isIOS , isMobile } from 'react-device-detect';

import {
  setSelectedTab,
  setSearchedKeyword,
} from "../../../redux/slices/userSearchSlice";
import bellImg from "../../../assets/imgs/bell.png";
import theme from "../../../config/theme/themeVariable";

const { Search } = Input;

const TopSearchHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTab = useSelector((state) => state.userSearch.selectedTab);
  const searchedKeyword = useSelector(
    (state) => state.userSearch.searchedKeyword
  );
  const { notificationHistoryData, formattedNotificationData } = useSelector(
    (state) => state.notification
  );

  const handleSeachTab = (val) => {
    dispatch(setSelectedTab(val));
  };

  const handleSearch = (value) => {
    dispatch(setSearchedKeyword(value));
    // console.log("search keyword---", value);
  };

  const handleSearchValueChange = (e) => {
    e.preventDefault();

    if(e.target.value === ""){
      dispatch(setSearchedKeyword(e.target.value));
      // console.log('------empty search keyword---- ',e.target.value)
    }
  }

  return (
    <>
      <Row
        className="border-b-gray-300 sm:border-neutral-100 border-2  w-full 2xs:hidden sm:flex items-center justify-center py-1.5 sm:py-0"
        style={{ boxShadow: "0px 2px 10px rgb(0 0 0 / 0.1)", "paddingTop":(process.env.REACT_APP_BUILDFOR==='bundle' && isIOS && isMobile )?'30px':'0px'  }}
      >
        <Row className="w-full 2xs:hidden sm:flex py-1.5 px-5">
          <Col className="w-1/5 flex justify-start px-4">
            {/* <h1 className='text-black mr-auto sm:text-2xl 2xs:text-4xl font-bold'>Chef App</h1> */}
            <Image
              className="chef-logo-desktop"
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>
          <Col className="w-1/5 ml-auto flex items-center justify-end px-4" onClick={() => navigate("/notifications")}>
            <Image width={25} height={25} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-3 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {/* <Row className='w-full sm:hidden 2xs:flex py-2.5'>
                    <Col className='w-4/5 px-4 flex items-center gap-x-1'>
                        <Col>
                            <PlaceOutlinedIcon />
                        </Col>

                        <Col>
                            <Typography 
                                className='user-location-address' 
                                variant='p' 
                                fontSize={14} 
                                fontWeight={500} 
                                fontFamily={'OnestRegular'}
                                >
                                {userAddress}
                            </Typography>
                        </Col>
                    </Col>
                    <Col className='w-1/5 flex items-center justify-end gap-x-2 px-4'>
                        <Image
                            width={30}
                            height={30}
                            preview={false}
                            src={bellImg}
                        />
                    </Col>
                </Row>       */}
      </Row>

      <Row className="border-b-gray-300 sm:border-neutral-100 border-2 w-full flex flex-col items-center justify-center pt-3.5 sm:py-0 sm:bg-neutral-100"
      style={{ boxShadow: "0px 2px 10px rgb(0 0 0 / 0.1)", "paddingTop":(process.env.REACT_APP_BUILDFOR==='bundle' && isIOS && isMobile )?'30px':'0px'  }}
      >
        <Row className="w-full 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 2xs:hidden sm:flex justify-center py-4 sm:py-2 px-5 sm:px-0">
          <Row className="w-full">
            <Search
              placeholder=" Find food or chefs..."
              className="user-search-input h-12 bg-gray-50"
              defaultValue={searchedKeyword}
              onSearch={handleSearch}
              onChange={handleSearchValueChange}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          </Row>
        </Row>
        <Row className="w-full 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:hidden 2xs:flex justify-center py-2.5">
          <Row className="w-11/12">
            <Search
              placeholder=" Find food or chefs..."
              className="user-search-input h-12 bg-gray-50"
              defaultValue={searchedKeyword}
              onSearch={handleSearch}
              onChange={handleSearchValueChange}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          </Row>
        </Row>

        <Row className="w-full 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 flex justify-center mt-2 sm:px-[3px] md:px-[2.5px] xl:px-[2px] 2xl:px-[2px]">
          <Tabs
            className="top-search-tab-container w-full flex items-center"
            aria-label="Basic tabs"
            size="lg"
            value={selectedTab}
            onChange={(e, data) => handleSeachTab(data)}
          >
            <TabList className="top-search-tablist w-full">
              <Tab className="w-1/2" value={0}>
                Chefs
              </Tab>
              <Tab className="w-1/2" value={1}>
                Dishes
              </Tab>
            </TabList>
          </Tabs>
        </Row>
      </Row>
    </>
  );
};

export default TopSearchHead;
