/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import moment from 'moment';
import { Typography, Rating } from '@mui/material';

import { getUserAllReviews, setInitialLoadedReviewsCount } from '../../redux/slices/userSlice';
import titleHeadLayout from '../UserLayout/titleHeadLayout';
import theme from '../../config/theme/themeVariable';
import { LeftOutlined } from '@ant-design/icons';
import SubmitSpinner from '../../components/SubmitSpinner';


const Review = ({ review }) => {
    const [ isMoreActive, setIsMoreActive ] = useState(false);

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }

    return(
        <>
            <Row className='w-full flex flex-col gap-y-1 bg-gray-100 py-4 px-5 my-1' style={{ borderRadius: '5px'}}>
                <Col>
                    <Rating name="half-rating" readOnly defaultValue={0} value={review?.rating} precision={0.5} />
                </Col>
                <Col>
                    <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}>
                        {/* {review?.review} */}
                        {
                            review?.review && review?.review.length > 200 ?
                             (!isMoreActive ? review?.review.slice(0, 200) : review?.review) 
                            : 
                             review?.review
                        }

                        <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { review?.review.length > 200 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                    </Typography>  
                </Col>
                <Col>
                    <Typography variant='p' fontSize={14} fontFamily={'OnestRegular'}>
                        {review?.user_name}
                    </Typography> 
                </Col>

                <Col>
                    <Typography variant='p' fontSize={12} fontFamily={'OnestRegular'}>
                        {   
                            review?.restaurant?.restaurant_name 
                            ? 
                            (<>Rated <span style={{ fontStyle: 'italic', color: theme.colorShade1}}>Chef {review?.restaurant?.restaurant_name}</span></>)
                            // `Rated Chef ${review?.restaurant?.restaurant_name}` 
                            : 
                            review?.dish?.name 
                            ? 
                            (<>Rated <span style={{ fontStyle: 'italic', color: theme.colorShade1}}>Dish {review?.dish?.name }</span> by <span style={{ fontStyle: 'italic', color: theme.colorShade1}}>Chef {review?.dish?.restaurant?.restaurant_name}</span></>)
                            // `Rated Dish ${review?.dish?.name }` 
                            : 
                            ''
                        }
                    </Typography> 
                    {/* <Typography variant='p' fontSize={11} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor }}>
                        {review?.dish?.name ? review?.dish?.name : ''}
                    </Typography>  */}
                </Col>

                <Col>
                    <Typography variant='p' fontSize={11} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor }}>
                        {moment(review?.created_at).utc(true).fromNow()}
                    </Typography> 
                </Col>
            </Row>
        </>
    );
}

const UserReviews = ({ style }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(state => state.user.loading);
    const initialLoadedReviewsCount = useSelector(state => state.user.initialLoadedReviewsCount);
    const userReviewsResponse = useSelector(state => state.user.userReviewsResponse);
    const userReviews = useSelector(state => state.user.userReviews);

    useEffect(() => {
        dispatch(getUserAllReviews());
    },[initialLoadedReviewsCount]);

    const handleLoadMore = () => {
        dispatch(setInitialLoadedReviewsCount(1))
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Row className='w-full h-full sm:min-h-full sm:bg-neutral-100 flex justify-center py-2'>
                <Col className='bg-white min-h-screen w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5' style={style} >
                    <Row className='w-full flex justify-start items-center my-3.5 gap-x-3'>
                        <Col>
                        <Button 
                            icon={<LeftOutlined />} 
                            onClick={handleGoBack}
                            style={{ color: theme.textColor, boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)' }}
                            size="large" />
                        </Col>
                        <Col>
                            <Typography className='font-bold' variant="h6" style={{ fontFamily: 'OnestRegular'}}>
                                Reviews
                            </Typography>
                        </Col>
                    </Row>

                    <Row className='w-full justify-start items-center my-3.5 gap-x-3'>
                        {
                            userReviews.length !== 0 ? 
                                userReviews.filter(item=>("id" in item)).map((item) => {
                                    return(
                                        <>
                                            <Review key={item.id} review={item} />
                                        </>
                                    )
                                })
                            :
                                (<>No Reviews Found...</>)
                        }

                        {
                            ( userReviews.length > 0 && userReviews.length < userReviewsResponse?.total_records) ? 
                                (
                                    <>
                                        <Col className='w-full py-2'>
                                            <Card
                                                title=""
                                                onClick={handleLoadMore}
                                                style={{
                                                    textAlign: 'center',
                                                    boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                { loading ? <SubmitSpinner /> : <p>Load More</p> }
                                            </Card>
                                        </Col>
                                    </>
                                )
                            :
                                (<></>)
                        }


                        {/* <Review />
                        <Review /> */}

                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default titleHeadLayout(UserReviews);