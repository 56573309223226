import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select, Row } from 'antd';
import { login, verifyOtp, resendOtp } from '../../redux/slices/authSlice';

import theme from '../../config/theme/themeVariable';


const { Option } = Select;

const UserLoginForm = ({authFormType = 'Login'}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const otpData = useSelector(state => state.auth.otpData);
    const [ countryCode, setCountryCode ] = useState('+91');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [form] = Form.useForm();


    useEffect(() => {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
      
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(interval);
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 1000);
      
        return () => {
          clearInterval(interval);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);


    // const handleAuthFormTypeChange = (value) => {
    //     updateAuthFormType(value);
    // };

    const handleCountryCode = (value) => {
        setCountryCode(value);
    }

    const prefixSelector = (
        <Form.Item name="country_code" noStyle>
          <Select
            style={{
              width: 70,
            }}
            defaultValue="+91"
            disabled
            onChange={handleCountryCode}
          >
            <Option value="+91">+91</Option>
            {/* <Option value="+86">+86</Option>
            <Option value="+87">+87</Option> */}
          </Select>
        </Form.Item>
    );  

    // Custom validation for mobile number
    const validateMobileNumber = (_, value) => {
        const regex = /^[0-9]{10}$/; // Simple validation for a 10-digit mobile number
        if (!regex.test(value)) {
        return Promise.reject('Please enter a valid 10-digit mobile number');
        }
        return Promise.resolve();
    };

    const handleLogin = (values) => {
        dispatch(login({ ...values, "country_code": countryCode }));
        // handleAuthFormTypeChange('Otp');
        setMinutes(1);
        setSeconds(30);
    }

    const handleOtpVerify = (values) => {
        const data = {
            reqData: {...values,"phone_number": otpData.phone_number, "time_stamp": otpData.timestamp},
            callbackOnboard: () => navigate('/user-onboard'),
            callbackUserProfile: () => navigate('/user-profile'),
            callbackChefProfile: () => navigate('/profile')
        }
        dispatch(verifyOtp(data));
    } 

    const handleSignup = (values) => {
        console.log(values);
    }

    const handleResendOtp = () => {
        dispatch(resendOtp({'phone_number': otpData?.phone_number}));
        setMinutes(1);
        setSeconds(30);
    }

    return(
        <>
            {
                authFormType === 'Login' ? 
                    (
                        <>
                            <Form
                                name="Auth Form"
                                form={form}
                                className='w-10/12 auth-form'
                
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={handleLogin}
                                autoComplete="off"
                            >
                            

                                <Form.Item
                                    name="phone"
                                    className='auth-form__login_phone'
                                    rules={[{
                                            required: true,
                                            message: '',
                                        },
                                        {
                                            validator: validateMobileNumber
                                        }
                                    ]}
                                >
                                    <Input 
                                        className='auth-form__login_phone-input w-full' 
                                        placeholder='Your Phone Number' 
                                        addonBefore={prefixSelector}/>
                                </Form.Item>                


                                <Form.Item>
                                    <Button 
                                        block 
                                        htmlType="submit" 
                                        key="submit" 
                                        style={{ backgroundColor: theme.primaryColor, height: '44px'}} 
                                        type="primary" 
                                        size='large'
                                        >
                                            Continue
                                    </Button>
                                </Form.Item> 
                            </Form> 
                        </>
                    )
                :
                
                authFormType === 'Otp' ? 
                    (
                        <>
                            <Row className='w-8/12 flex flex-col items-center mt-1 mb-1 '>
                                <p className='font-normal' style={{ color: theme.paragraphColor }}>OTP sent to {otpData?.phone_number || 'entered phone number'}</p>
                            </Row>
                            <Form
                                name="Auth Form"
                                form={form}
                                className='w-10/12 auth-form'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={handleOtpVerify}
                                autoComplete="off"
                            >

                            
                                <Form.Item
                                    name="otp"
                                    className='auth-form__login_phone sm:mb-4'
                                    rules={[{
                                            required: true,
                                            message: 'Please Enter OTP',
                                        }
                                    ]}
                                >
                                    <Input className='auth-otp-input p-2.5' placeholder='Enter OTP'/>
                                </Form.Item> 

                                <Row className='flex justify-center my-4 sm:my-2'>
                                    {seconds > 0 || minutes > 0 ? (
                                        <p>
                                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    ) : (
                                        <p>Didn't receive code ? Click on Resend</p>
                                    )}
                                </Row>               

                                <Form.Item className='sm:mb-4'>
                                    <Button 
                                        block 
                                        htmlType="submit" 
                                        key="submit" 
                                        style={{ backgroundColor: theme.primaryColor, height: '44px'}} 
                                        type="primary" 
                                        size='large'
                                        >
                                            Verify
                                    </Button>
                                </Form.Item> 

                                <Form.Item className='sm:mb-4'>
                                    <Button 
                                        block 
                                        style={{ height: '44px'}} 
                                        type="default" 
                                        size='large'
                                        disabled={seconds === 0 && minutes === 0 ? false : true }
                                        onClick={handleResendOtp}
                                        >
                                            Resend
                                    </Button>
                                </Form.Item>
                            </Form>                                 
                        </>
                    )
                :
                    
                    (
                        <>
                            <Form
                                name="Auth Form"
                                form={form}
                                className='w-10/12 auth-form'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={handleSignup}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name='fullname'
                                    className='auth-form__signup_name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Full Name is required',
                                        },
                                    ]}
                                >
                                    <Input className='auth-form__signup_name-input w-full' placeholder='Full Name' />
                                </Form.Item>
                                <Form.Item
                                    name='email'
                                    className='auth-form__signup_email'
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address',
                                        },
                                        {
                                            required: true,
                                            message: 'Email is required',
                                        },
                                    ]}
                                >
                                    <Input className='auth-form__signup_email-input w-full' placeholder='Email' />
                                </Form.Item>
                                <Form.Item>
                                    <Button block htmlType="submit" key="submit" style={{ backgroundColor: 'blue'}} type="primary" size='large'>Create Account</Button>
                                </Form.Item>   
                            </Form>    
                            {/* <p>Already had a account? <span style={{ cursor: 'pointer', color: 'blue'}} onClick={() => handleAuthFormTypeChange('Login')}>Go to Login</span></p> */}
                        </>
                    )

            }
        </>

    );
};

UserLoginForm.propTypes = {
    authFormType: PropTypes.string,
    updateAuthFormType: PropTypes.func,
}

export default UserLoginForm;
