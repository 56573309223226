import { useEffect, lazy, Suspense } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { message, Row, Col } from 'antd';
import { setServerError } from '../../redux/slices/authSlice';
import LoadingSpinner from '../../components/LoadingSpinner';

const LoginHeader = lazy(() => import('./sections/LoginHeader'));
const LoginSection = lazy(() => import('./sections/LoginSection'));
// import LoginHeader from "./sections/LoginHeader";
// import LoginSection from "./sections/LoginSection";


const Login = () => {
    const dispatch = useDispatch();
    const otpData = useSelector(state => state.auth.otpData);
    const otpTimestamp = useSelector(state => state.auth.otpData?.timestamp);
    const otpMessage = useSelector(state => state.auth.otpData?.message);
    const serverError = useSelector(state => state.auth.serverError);
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
          type: 'success',
          content: otpMessage,
        });
    };

    const error = (text) => {
        messageApi.open({
          type: 'error',
          content: text,
        });
      };

    useEffect(() => {
        if(otpData !== null){
            success();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otpTimestamp]);

    useEffect(() => {
        if(serverError !== null){
            error(serverError);
            dispatch(setServerError(null));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serverError])

    return(
        <>
            {contextHolder}
            <Suspense
              fallback={
                <LoadingSpinner />
              }
            >
                <Row className='w-full sm:h-screen justify-center items-center' style={{ backgroundColor: '#f6f6f6'}}>
                    <Col className='w-full rounded-md bg-white flex flex-col justify-center items-center sm:w-7/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 sm:h-3/4 sm:px-4'>
                        <LoginHeader />
                        <LoginSection />
                    </Col>
                </Row>
            </Suspense>
        </>
    );
}

export default Login;