import { useDispatch, useSelector } from 'react-redux';
import { Form, Upload, Image, message, Row } from 'antd';

import { formFoodImagesUpload, setFormFoodImages } from '../../redux/slices/foodSlice'; 

import img_upload_icon from '../../assets/imgs/img_upload_icon.png'; 
const { Dragger } = Upload;

const validateImage = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    // console.log(file.file.type)

    return acceptedImageTypes.includes(file.file.type);
};

const FoodPhotosUploadSection = () => {
    const dispatch = useDispatch();
    const formFoodImages = useSelector(state => state.food.formFoodImages);
    const isDishImageEmpty = useSelector(state => state.food.isDishImageEmpty);

    const customUpload = async (file) => {
        if (!validateImage(file)) {
            message.error('Please upload only image files (JPEG, PNG, GPG)');
            return;
        }else{
            try {
                await dispatch(formFoodImagesUpload(file));
                message.success('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading Image:', error);
                message.error('Files upload failed.');
            }
        }
    };

    const uploadProps = {
        customRequest: (filedata) => {
           const { onSuccess, onError } = filedata;
           customUpload(filedata)
            .then(() => onSuccess())
            .catch((error) => {
              console.error('Custom upload error:', error);
              onError(error);
            });
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
          }
        },
        onRemove(file){
            console.log('onremove= ',file, formFoodImages); // || item?.id  !== file?.id
            const restData = formFoodImages.filter(item =>  (item?.originalName || item?.name ) !== file?.name );
            console.log('rest---',restData);
            dispatch(setFormFoodImages(restData));
        }
    };

    // console.log('-----food imahe---', [{...formFoodImages[0], url: formFoodImages[0].image_url, thumbUrl: formFoodImages[0].image_url}])

    return(
        <>
            <Form.Item
                name="food_picture"
                className=''
                style={{ border: isDishImageEmpty ? "1px solid red" : ''}}
                validateStatus={isDishImageEmpty ? 'error' : 'success'}
                help={isDishImageEmpty? 'Upload Dish Image' : ''}
            >
                <Dragger maxCount={1} defaultFileList={formFoodImages.length !== 0 ? [{...formFoodImages[formFoodImages.length - 1], url: formFoodImages[0].image_url, thumbUrl: formFoodImages[0].image_url}] : []} className='image-drag-box' listType='picture' {...uploadProps} >
                    {/* <Row className='flex flex-col justify-center items-center'> */}
                        {
                            formFoodImages.length !== 0 ? 
                                (
                                    <>
                                        <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                            <Image preview={false} className="w-full" src={formFoodImages[formFoodImages.length - 1].image_url || formFoodImages[formFoodImages.length - 1].url} alt='img' />
                                        </Row>
                                    </>
                                )
                            :
                                (
                                    <>
                                        <Row className='flex flex-col justify-center items-center overflow-hidden'>
                                            <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' />
                                            <p className="ant-upload-text">Click to Upload</p>
                                        </Row>
                                    </>
                                )

                        }
                        {/* <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' /> */}
                        {/* <p className="ant-upload-text">Click to Upload</p> */}
                    {/* </Row> */}
                </Dragger>
            </Form.Item> 
        </>
    );
}
export default FoodPhotosUploadSection;