/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';

import { getCuisines, getDietaryPreference } from '../../redux/slices/foodSlice'; 
import { getAboutRestaurant, getChefRestaurantId, getRestaurantImageInfo, getChefReviews } from '../../redux/slices/profileSlice'; 
import { getAllDishesByChefId, getFilteredDishesListByChefId } from '../../redux/slices/userSlice'; 
import ChefPublicView from './sections/ChefPublicView';
import ChefTabSection from './sections/ChefTabSection';
import ChefDishAndReviews from './sections/ChefDishAndReviews';
import layout from '../Layout/layout';

const PublicProfile = ({style}) => {
    const dispatch = useDispatch();
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const chefImage = useSelector(state => state.profile.chefImage);
    const publicViewFilterValue = useSelector(state => state.user.publicViewFilterValue);
    const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    // console.log('res id-----', restaurantId)

    useEffect(() => {
        dispatch(getCuisines());
        dispatch(getDietaryPreference());

    },[]);

    useEffect(() => {
        if(restaurantId === null){
            dispatch(getChefRestaurantId());
        }
        if(restaurantId !== null){
            dispatch(getAboutRestaurant(restaurantId));
            dispatch(getAllDishesByChefId(restaurantId));
            dispatch(getChefReviews(restaurantId));
        }
        // eslint-disable-next-line
    },[restaurantId]);

    useEffect(() => {
        if(chefImage.length === 0){
            dispatch(getRestaurantImageInfo());
        }
        // eslint-disable-next-line
    },[chefImage]);

    useEffect(() => {
        if(publicViewFilterValue !== null){
            dispatch(getFilteredDishesListByChefId({ chefId: restaurantId, filterVal: publicViewFilterValue}));
        }else{
            dispatch(getAllDishesByChefId(restaurantId));
        }
        // eslint-disable-next-line
    },[publicViewFilterValue]);

    return (
        <Row className='w-full 2xs:h-full sm:h-auto sm:min-h-screen sm:bg-neutral-100 flex justify-center' style={style}>

            <Row className='bg-white w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5 flex flex-col' style={{    minHeight: "100vh"}} >

                <ChefPublicView aboutRestaurantInfo={aboutRestaurantInfo} chefImage={chefImage} />
                <ChefTabSection />
                <ChefDishAndReviews />
            </Row>
        </Row>
    )
}

export default layout(PublicProfile);