import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import {
  persistStore,
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from 'redux-persist';
const persistConfig = {
  key: 'root',
  storage,
  whitelist:["auth","profile"]
}
const middlewares = [];
const store = configureStore({
	reducer: persistReducer(persistConfig,rootReducer()),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})


export default store;

export const persistor = persistStore(store)
