import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'antd';
import { Tabs, TabList, Tab } from '@mui/joy';
import theme from '../../../config/theme/themeVariable';
import { setChefProfileDishReviewTab } from '../../../redux/slices/userSlice'; 

const ChefTabSection = () => {
    const dispatch = useDispatch();

    const chefProfileDishReviewTab = useSelector(state => state.user.chefProfileDishReviewTab);

    const handleTabSelect = (val) => {
        dispatch(setChefProfileDishReviewTab(val));
    }

    return (
        <>
            <Row className='w-full flex justify-center mt-2'>
                <Tabs 
                    className='public-view-tab-container w-full flex items-center' 
                    aria-label="Basic tabs" 
                    size='lg' 
                    value={chefProfileDishReviewTab}
                    onChange={(e,data) => handleTabSelect(data)}
                    sx={{ color: theme.colorShade5 }}
                >
                    <TabList className="top-search-tablist w-full">
                        <Tab className="w-1/2" value={0}>Dishes</Tab>
                        <Tab className="w-1/2" value={1}>Reviews</Tab>
                    </TabList>
                </Tabs>
            </Row> 
        </>
    )
}

export default ChefTabSection;