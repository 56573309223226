/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Row,Col, message } from 'antd';

import titleHeadWithBackLayout from '../UserLayout/titleHeadWithBackLayout';
import ChefBasicInfoUserView from './sections/ChefBasicInfoUserView';
import ChefTabSection from '../PublicProfile/sections/ChefTabSection';
import ChefDishAndReviewsUserView from './sections/ChefDishAndReviewsUserView';
import { getChefReviews } from '../../redux/slices/profileSlice';
import { getAllDishesByChefId, getFilteredDishesListByChefId } from '../../redux/slices/userSlice';
import { getCuisines, getDietaryPreference } from '../../redux/slices/foodSlice';
import { getAboutRestaurant, setProfileError } from '../../redux/slices/profileSlice';

const UserChefDetails = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const [messageApi, contextHolder] = message.useMessage();

    let selectedChefData ;

    if(location?.state) selectedChefData = location.state?.selectedChefData;

    // const { selectedChefData } = location.state;
    const initialLoadedChefReviewsCount = useSelector(state => state.profile.initialLoadedChefReviewsCount);
    const chefDishesUserViewFilterValue = useSelector(state => state.user.chefDishesUserViewFilterValue);
    const chefReviews = useSelector(state => state.profile.chefReviews);
    const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    const error = useSelector(state => state.profile.error);

    // console.log('selected chef data----------', selectedChefData );

    const errorToast = (text) => {
        messageApi.open({
          type: 'error',
          content: text,
        });
    };

    useEffect(() => {
        dispatch(getCuisines());
        dispatch(getDietaryPreference());

    },[]);

    useEffect(() => {
        if(error !== null){
            errorToast(error);
            dispatch(setProfileError(null));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error])

    useEffect(() => {
        if(selectedChefData?.restaurant_id !== null){
            dispatch(getAboutRestaurant(selectedChefData?.restaurant_id ||  params.id));
            dispatch(getAllDishesByChefId(selectedChefData?.restaurant_id ||  params.id));
        }
    },[selectedChefData?.restaurant_id]);

    useEffect(() => {
        if(chefReviews && chefReviews.length > 0 && selectedChefData?.restaurant_id !== null){
            console.log('effect about chef called--------------------')
            dispatch(getAboutRestaurant(selectedChefData?.restaurant_id ||  params.id));
        }
    },[chefReviews.length])

    useEffect(() => {
        if(chefDishesUserViewFilterValue !== null){
            dispatch(getFilteredDishesListByChefId({chefId: selectedChefData?.restaurant_id, filterVal: chefDishesUserViewFilterValue}));
        }else{
            dispatch(getAllDishesByChefId(selectedChefData?.restaurant_id ||  params.id));
        }
    },[chefDishesUserViewFilterValue]);

    useEffect(() => {
        if(selectedChefData?.restaurant_id !== null){
            dispatch(getChefReviews(selectedChefData?.restaurant_id ||  params.id));
        }
    },[selectedChefData?.restaurant_id, initialLoadedChefReviewsCount]);

    return (
        <>
            {contextHolder} 
            <Row className='w-full 2xs:h-full sm:h-auto sm:min-h-screen sm:bg-neutral-100 flex flex-col justify-start sm:items-center'>
                <Col className="bg-white w-full xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 2xl:w-3/12 h-full p-5" style={{minHeight:"100vh"}}>
                    <Row className=''>
                        <ChefBasicInfoUserView aboutRestaurantInfo={aboutRestaurantInfo || selectedChefData}  />
                    </Row>

                    <Row >
                        <ChefTabSection />
                    </Row>

                    <Row >
                        <ChefDishAndReviewsUserView aboutRestaurantInfo={aboutRestaurantInfo || selectedChefData} />
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default titleHeadWithBackLayout(UserChefDetails);