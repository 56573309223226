import { useState, useEffect, useRef } from "react";
import Pubnub from "pubnub";
import { getNotificationHistory } from "../redux/slices/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import store from "../redux/store";

const usePubNub = (identity) => {
  const userIdentity = identity || "test"; // Default identity to "test" if not provided
  const [messages, setMessages] = useState([]);
  const pubnub = useSelector((state) => state.user.pubnub);
  const dispatch = useDispatch();
  const pubnubRef = useRef(null);
  const subscribedChannelsRef = useRef([]);
  const handleMessage = (message) => {
    const chatScreenActive = store.getState()?.user?.chatScreenActive ;
    console.log("Received message:",  chatScreenActive,message);
    if(chatScreenActive === 0){
      new Notification(message.message.title, {
        body: message.message.description,
      });
      setMessages((prevMessages) => [message, ...prevMessages]);
      dispatch(getNotificationHistory());
    }else{
      // new Notification(message.message.title, {
      //   body: message.message.description,
      // });
      setMessages((prevMessages) => [message, ...prevMessages]);
      dispatch(getNotificationHistory());
    }

  };

  useEffect(() => {
    const setupPubNub = () => {
      if (!pubnub) {
        pubnubRef.current = new Pubnub({
          publishKey: process.env.REACT_APP_PUBNUB_PUBLISH,
          subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE,
          userId: userIdentity,
        });
      } else {
        pubnubRef.current = pubnub;
      }
      pubnubRef.current.addListener({
        message: handleMessage,
      });
      // / Check if the channel is already subscribed
      if (!subscribedChannelsRef.current.includes(`user_${userIdentity}`)) {
        pubnubRef.current.subscribe({
          channels: ["public", `user_${userIdentity}`],
        });
        subscribedChannelsRef.current.push(`user_${userIdentity}`);
      }
    };

    setupPubNub();

    return () => {
      // Clean up PubNub instance and remove listener when component unmounts
      pubnubRef.current.removeListener({
        message: handleMessage,
      });
      // pubnubRef.current.unsubscribeAll();
    };
  }, [userIdentity, pubnub, dispatch]);

  return pubnubRef.current; // Return the current PubNub instance
};

export default usePubNub;