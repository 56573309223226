import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import store ,{persistor} from './redux/store';

import FrontRoutes from './routes';
import './assets/css/style.css';
import './assets/css/responsive.css';


function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
          <ConfigProvider>
            <BrowserRouter>
              <FrontRoutes />
            </BrowserRouter>
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
