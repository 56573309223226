import { useSelector } from 'react-redux';
import { Col } from 'antd';

import ChefDishTabUserView from './ChefDishTabUserView';
import ChefReviewTabUserView from './ChefReviewsTabUserView';

const ChefDishAndReviewsUserView = ({ aboutRestaurantInfo }) => {
    const chefProfileDishReviewTab = useSelector(state => state.user.chefProfileDishReviewTab);
    // const cuisinesData = useSelector(state => state.food.cuisinesData);
    // const chefAllDishData = useSelector(state => state.user.chefAllDishData);

    return (
        <>
            <Col className='w-full my-2.5'>
                {
                    chefProfileDishReviewTab === 0 ? 
                        (<><ChefDishTabUserView /></>)
                    :
                        (<><ChefReviewTabUserView chefId={ aboutRestaurantInfo } /></>)
                }
            </Col>
        </>
    )
}

export default ChefDishAndReviewsUserView;