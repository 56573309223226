import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Collapse, Button, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import theme from '../../config/theme/themeVariable';
import FoodBasicSection from './FoodBasicSection';
import FoodQuantitySection from './FoodQuantitySection';
import FoodAvailabilitySection from './FoodAvailabilitySection';
import FoodAdditionalInfoSection from './FoodAdditionalInfoSection';
import { 
    setFoodBasicFormData, 
    setFoodCreateStep, 
    setFormFoodImages, 
    setSelectedItemForEdit, 
    setFoodFormStateClearOnBack, 
    setFoodCulinaryInfoClear,
    setAvailabilityList
} from '../../redux/slices/foodSlice';


const FoodCreateBasicInfoForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const selectedItemForEdit = useSelector(state => state.food.selectedItemForEdit);
    const quantityData = useSelector(state => state.food.quantityData);
    const availabilityData = useSelector(state => state.food.availabilityData);
    const availabilityList = useSelector(state => state.food.availabilityList);
    const foodBasicFormData = useSelector(state => state.food.foodBasicFormData);

    const handleBasicInfoSubmit = (values) => {
        // console.log('food form 1= ',values);
        dispatch(setFoodBasicFormData(values));
        dispatch(setFoodCreateStep(1));
    };

    const updateAvailabilityList = (value) => {
        dispatch(setAvailabilityList(value));
    }

    useEffect(()=>{
        if(availabilityList !== null  && availabilityList.length > 0){
            form.setFieldsValue({
                availability: availabilityList ,
            })
        }
    },[availabilityList, form])

    if(selectedItemForEdit){
        form.setFieldsValue({
            dish_name: selectedItemForEdit.name,
            dish_description: selectedItemForEdit.description,
            dish_additional_notes: selectedItemForEdit.additional_information,
            // availability: selectedItemForEdit.availability.split(',')
        })
    };

    if(foodBasicFormData){
        console.log('basic data------',foodBasicFormData);

        if(foodBasicFormData?.dish_name)  form.setFieldsValue({ dish_name: foodBasicFormData?.dish_name }) ;
        if(foodBasicFormData?.dish_description)  form.setFieldsValue({ dish_description: foodBasicFormData?.dish_description }) ;

        if(foodBasicFormData?.dish_quantity)  form.setFieldsValue({ dish_quantity: foodBasicFormData?.dish_quantity }) ;
        if(foodBasicFormData?.dish_price)  form.setFieldsValue({ dish_price: foodBasicFormData?.dish_price }) ;
        if(foodBasicFormData?.quantity_price_additional_data)  form.setFieldsValue({ quantity_price_additional_data: foodBasicFormData?.quantity_price_additional_data }) ;

        if(foodBasicFormData?.availability.length > 0)  form.setFieldsValue({ availability: foodBasicFormData?.availability }) ;
        if(foodBasicFormData?.dish_additional_notes)  form.setFieldsValue({ dish_additional_notes: foodBasicFormData?.dish_additional_notes }) ;
    }



    const panelStyle = {
        marginBottom: 20,
        background: '#fff',
        border: 'none',
    };

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Name & Description',
            children: <FoodBasicSection />,
            style: panelStyle,
        },
        {
            key: '2',
            label: 'Quantity & Price',
            children: <FoodQuantitySection quantityData={quantityData} prices={selectedItemForEdit?.prices} />,
            style: panelStyle,
        },
        {
            key: '3',
            label: 'Availability',
            children: <FoodAvailabilitySection 
                    availabilityData={availabilityData} 
                    availabilityList={availabilityList}
                    updateAvailabilityList={updateAvailabilityList}
                    availability={selectedItemForEdit?.availability} 
                />,
            style: panelStyle,
        },
        {
            key: '4',
            label: 'Additional Information',
            children: <FoodAdditionalInfoSection />,
            style: panelStyle,
        },
    ];

    const handleBackBtn = () => {
        dispatch(setFormFoodImages([]));
        dispatch(setFoodFormStateClearOnBack());
        dispatch(setSelectedItemForEdit(null));
        dispatch(setFoodCulinaryInfoClear());
        navigate('/food');
    }

  return (
    <>
        <Form
            name="create_dish_form_1"
            form={form}
            className='auth-form'
            style={{ height: '100%'}}             
            initialValues={{
                remember: true,
            }}
            onFinish={handleBasicInfoSubmit}
            autoComplete="off"
        >

            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2', '3', '4']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={getItems(panelStyle)}
            />

            <Form.Item>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            size='large'
                            onClick={handleBackBtn}
                        >
                           Go Back
                        </Button>
                    </Col>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                        >
                            Next
                        </Button>
                    </Col>
                </Row>                
            </Form.Item>    
        </Form>            
    </>
  )
}

export default FoodCreateBasicInfoForm