import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Collapse, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { createChefDetails, updateChefDetails, getRestaurantDetailsInfo } from '../../redux/slices/profileSlice'; 

import theme from '../../config/theme/themeVariable';
import DetailsForm from './ChefDetails';
import LocationForm from './ChefLocation'
import ContactForm from './ChefContactForm';
import SubmitSpinner from '../SubmitSpinner';
// import { getItem } from '../../utility/localStorageControl';


const OnboardChefInfoForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.profile.loading);
    const user = useSelector(state => state.auth.user);
    // const currentChefStep = useSelector(state => state.profile.currentChefStep);
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const restaurantDetailsData = useSelector(state => state.profile.restaurantDetailsData);
    const createdUserData = useSelector(state => state.user.createdUserData);

    const [location, setLocation] = useState({
        address: '',
        lat: 13.019144,
        lng: 77.646454,
    });
    const [ userFullLocData, setuserFullLocData ] = useState(null);

    // console.log('userFullLoc----',userFullLocData, restaurantId)

    useEffect(() => {
        if(restaurantId  && user['step'] !== 0){
            console.log('call updated data')
            dispatch(getRestaurantDetailsInfo(restaurantId));
        }
        // eslint-disable-next-line
    },[restaurantId]);

    if(createdUserData){
        console.log('user data-------------', createdUserData);
        form.setFieldsValue({
            chef_name: createdUserData?.full_name,
            email: createdUserData?.email,

            address: createdUserData?.address,
            house_number:  createdUserData?.house_number,
            area: createdUserData?.area,
            pincode: createdUserData?.zipcode,
            country: createdUserData?.country,
            city: createdUserData?.city,
            state: createdUserData?.state,
        });
    }
    // useEffect(() => {
        if(restaurantDetailsData !== null){
            let chef_description=null
            if("restaurant_description" in restaurantDetailsData){
                chef_description = restaurantDetailsData?.restaurant_description;
            }else if("description" in restaurantDetailsData){
                chef_description = restaurantDetailsData?.description;
            }
            // console.log('updated data= ',restaurantDetailsData)
            form.setFieldsValue({
                chef_name: restaurantDetailsData?.restaurant_name,
                chef_description: chef_description,
                email: restaurantDetailsData?.restaurant_email,
                alternate_phone: restaurantDetailsData?.restaurant_owner.owner_mobile_number.slice(3),

                address: restaurantDetailsData?.restaurant_address,
                house_number:  restaurantDetailsData?.restaurant_location.house_number,
                area: restaurantDetailsData?.restaurant_location.area,
                pincode: restaurantDetailsData?.restaurant_location.zipcode,
                country: restaurantDetailsData?.restaurant_location.country,
                city: restaurantDetailsData?.restaurant_location.city,
                state: restaurantDetailsData?.restaurant_location.state,
            });
        }

        // eslint-disable-next-line
    // },[restaurantDetailsData]);


    useEffect(() => {
        if(userFullLocData !== null){
            console.log('userFullLoc----',userFullLocData)
            const resPostal = userFullLocData.find(item => item.hasOwnProperty('postal_code'));
            const resState = userFullLocData.find(item => item.hasOwnProperty('state'));
            const resCity = userFullLocData.find(item => item.hasOwnProperty('locality'));
            const resCountry = userFullLocData.find(item => item.hasOwnProperty('country'));

            form.setFieldsValue({
                pincode: resPostal?.postal_code,
                country: resCountry?.country,
                city: resCity?.locality,
                state: resState?.state
            });

            // console.log(resPostal, resState,resCity, resCountry )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFullLocData]);

    console.log('restaurantDetailsData -------------------', location, restaurantDetailsData)


    const handleProfileSubmit = (values) => {
        //   console.log('form_1 data= ',values);
        //   console.log({...values, location_info: location});
          
        if(user['step'] === 0){
          dispatch(createChefDetails({
            ...values, 
            location_info: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? 
                location 
                : 
                { address: createdUserData?.search_address, lat: createdUserData?.lat, lng: createdUserData?.long}
        }));
          // console.log('create info call= ',{...values, location_info: location});
        }else{
          // dispatch(updateChefDetails({...values, restaurant_id: restaurantId, location_info: location}));
            console.log('update call= ',{
              ...values, 
                restaurant_id: restaurantId, 
              location_info: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? 
                  location : 
                  { 
                      lat: restaurantDetailsData?.restaurant_location?.latitude, 
                      lng: restaurantDetailsData?.restaurant_location?.longitude, 
                      address: restaurantDetailsData?.restaurant_location?.place_name
                  } ,
            });
    
          dispatch(updateChefDetails({
              ...values, 
              restaurant_id: restaurantId, 
              location_info: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? 
                  location : 
                  { 
                      lat: restaurantDetailsData?.restaurant_location?.latitude, 
                      lng: restaurantDetailsData?.restaurant_location?.longitude, 
                      address: restaurantDetailsData?.restaurant_location?.place_name
                  } ,
          }));
        }
          
    }

    const updateLocation = (loc, fullLocData) => {
        setLocation(loc);
        setuserFullLocData(fullLocData)
    }

    const panelStyle = {
      marginBottom: 20,
      background: '#fff',
      border: 'none',
    };

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Name',
          children: <DetailsForm />,
          style: panelStyle,
        },
        {
          key: '2',
          label: 'Address',
          children: 
          <>
            {
                (user['step'] === 0 ) ? 
                    (<>
                        <LocationForm 
                            locationAddressData={{
                                place_name: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location.address : createdUserData?.search_address, 
                                latitude: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location.lat : createdUserData?.lat , 
                                longitude: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location.lng : createdUserData?.long
                            }} 
                            location={location} 
                            updateLocation={updateLocation} />
                    </>)
                :   
                (user['step']  > 0 || restaurantDetailsData !== null) ? 
                    (<>
                        <LocationForm 
                            locationAddressData={
                                (user['step']  > 0 && location?.lat !== 13.019144 && location?.lng !==  77.646454) ? 
                                    {place_name: location.address, latitude: location.lat, longitude: location.lng } 
                                    : 
                                    {   
                                        place_name: restaurantDetailsData?.restaurant_location?.place_name, 
                                        latitude: restaurantDetailsData?.restaurant_location?.latitude, 
                                        longitude: restaurantDetailsData?.restaurant_location?.longitude 
                                    } 
                                    // restaurantDetailsData?.restaurant_location
                                } 
                            location={location} 
                            updateLocation={updateLocation} />
                    </>)
                :
                    (<></>)
            }
            
        </>,
          style: panelStyle,
        },
        {
          key: '3',
          label: 'Contact',
          children: <ContactForm />,
          style: panelStyle,
        },
    ];

    const handleBack = () => {
        navigate(-1);
    }

    return(
        <>
            <Form
                name="onboard_form_1"
                form={form}
                className='auth-form'
                                    
                initialValues={{
                    remember: true,
                }}
                onFinish={handleProfileSubmit}
                autoComplete="off"
            >

                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2', '3']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    items={getItems(panelStyle)}
                />
                <Form.Item>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12} style={{ display: user["step"] === 0 ? 'none' : 'block'}}>
                        <Button 
                            block
                            size='large'
                            onClick={handleBack}
                            disabled={loading ? true : false}
                        >
                           Go Back
                        </Button>
                    </Col>
                    <Col className='px-4' span={ user["step"] === 0 ? 24 : 12}>
                        <Button 
                            block 
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                        >
                            { loading ? <SubmitSpinner /> : user["step"] === 3 ? 'Update': 'Next'}
                        </Button>
                    </Col>
                </Row>

                </Form.Item>  
            </Form>        
        </>
    );
}

export default OnboardChefInfoForm;
