import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Collapse, Form, Button, Input } from 'antd';
import { Typography, Rating } from '@mui/material';
import { AspectRatio, Card } from '@mui/joy';
import onion_crossed from '../../../assets/imgs/onion_crossed.png'

// import DishReviews from '../../../components/DishReviews';
import DishReviewsSection from './DishReviewsSection';
import SubmitSpinner from '../../../components/SubmitSpinner';
import theme from '../../../config/theme/themeVariable';
import StarIcon from '@mui/icons-material/Star';
import { CaretRightOutlined } from '@ant-design/icons';
import { postDishReview } from '../../../redux/slices/foodSlice';


const { TextArea } = Input;

const DishItemDetails = ({ selectedDishData, dishReviews = [] }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.food.loading);
    const user = useSelector(state => state.auth.user);
    const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    // const chef_access_token = useSelector(state => state.auth.chef_access_token);
    const [ dishRating, setDishRating ] = useState(4);
    const [ isMoreActive, setIsMoreActive ] = useState(false);
    const [ foodSymbol, setFoodSymbol ] = useState([]);


    const panelStyle = {
        marginBottom: 20,
        background: '#fff',
        border: 'none',
    };

    useEffect(() => {

        if(selectedDishData && selectedDishData?.dietary_types.length > 0){
            let foodSymbols = [];

            selectedDishData?.dietary_types.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                }  else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 
            })

            setFoodSymbol(foodSymbols)
        }

    },[selectedDishData])

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Reviews',
          children: <DishReviewsSection selectedDishReviews={dishReviews} />,
          style: panelStyle,
        },
    ];

    
    const clearReviewField = () => {
        form.setFieldsValue({
            dish_review: ''
        });
    }

    const handlePostDishReview = (values) => {
        dispatch(postDishReview({ 
            rating: dishRating.toFixed(1), 
            review: values.dish_review, 
            dishId: selectedDishData?.id,
            callback: clearReviewField
        }));
    }

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }

    const handleChefClick = (id) => {
        console.log('-------chef id------', id)
        navigate(`/search/chef-details/${id}`)
    }

    const additionalDescription = ()=>{
        if(selectedDishData && selectedDishData.additional_information){
            if(selectedDishData.additional_information.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor , textAlign: 'justify'}} >
                    {selectedDishData.additional_information}
                </Typography>);
            }else{
                return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} style={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: 'break-all'}} >
                    {selectedDishData.additional_information}
                </Typography>);
            }
        }else{
            return null
        }
    }
    const description = ()=>{
        if(selectedDishData && selectedDishData.description){
            let isLongText=false
            if(selectedDishData.description.slice(0, 30).indexOf(" ")<0){
                isLongText=true;
            }
            return (<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify', wordBreak: isLongText?'break-all':'none' }}>
                        {selectedDishData.description.length > 150 ? ( !isMoreActive ? selectedDishData.description.slice(0, 150) : selectedDishData.description ) : selectedDishData.description }

                        <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { selectedDishData.description?.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                    </Typography>);
        }else{
            return ""
        }
    }

    const dishname = ()=>{
        if(selectedDishData && selectedDishData.name){
            if(selectedDishData.name.slice(0, 30).indexOf(" ") < 0){
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                    {selectedDishData.name}
                </Typography>);
            }else{
                return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}} style={{textAlign: 'left', wordBreak: 'break-all'}}>
                    {selectedDishData.name}
                </Typography>);
            }
        }else{
            return (<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                    NA
                </Typography>);
        }
    }
    
    return (
        <>
            <Row className=''>
                <Col className='w-full flex-col'>
                    <Card variant="plain" sx={{ width: '100%', padding: '15px 0' }}>
                        <AspectRatio minHeight="120px" maxHeight="200px" sx={{ borderRadius: '10px'}}>
                            <img
                                src={selectedDishData?.main_image?.image_url || selectedDishData?.image || selectedDishData?.main_image_url}
                                srcSet={selectedDishData?.main_image?.image_url || selectedDishData?.image || selectedDishData?.main_image_url }
                                loading="lazy"
                                alt="img"
                            />
                        </AspectRatio>
                    </Card>

                    <Col className='w-full flex flex-col gap-y-2.5'>
                        <Row  className='flex items-center'>
                            <StarIcon style={{ color: '#ffd12b'}} />
                            <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                <span className='font-bold' style={{ color: '#000' }}>
                                    {selectedDishData?.average_rating ? 
                                    parseFloat(selectedDishData?.average_rating).toFixed(1) 
                                    : 
                                    selectedDishData?.avg_rating ? 
                                    parseFloat(selectedDishData?.avg_rating).toFixed(1)
                                    :
                                    '0.0'} 
                                    </span> ( {selectedDishData?.total_reviews}  {selectedDishData?.total_reviews === 1 ? 'Review' : 'Reviews'} )
                            </Typography>
                        </Row>

                        <Row className='w-full flex gap-x-1'>
                            {foodSymbol}
                        </Row>

                        {/*<Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}} style={{textAlign:"left", wordBreak: "break-all"}}>
                            {selectedDishData?.name}
                        </Typography>*/}
                        {dishname()}
                        <Typography fontSize="sm" level="body-xs" mb={0.5} fontFamily={'OnestRegular'} sx={{ color: '#000'}}>
                            {(selectedDishData?.culinary_preferences !== 0 && selectedDishData?.culinary_preferences.map(item => item.name).join(" | ")) || ''}
                        </Typography>

                        <Typography variant='h6' fontWeight={500} fontSize={14} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                            By 
                            <span 
                                onClick={() => handleChefClick(selectedDishData?.restaurant_id)}
                                style={{ fontStyle: 'italic', color: theme.colorShade1, textTransform: 'capitalize', cursor: 'pointer' }}
                            >
                                {` Chef ${aboutRestaurantInfo?.restaurant_name || ''}`}
                            </span>
                        </Typography>

                        {/*<Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify', hyphens: 'auto'}}>
                            {selectedDishData?.description.length > 150 ? ( !isMoreActive ? selectedDishData?.description.slice(0,150) : selectedDishData?.description) : selectedDishData?.description }
                            <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  {selectedDishData?.description.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                        </Typography>*/}
                        {description()}


                        <Row className='flex flex-col'>
                            <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}  >
                                Availability:
                            </Typography>

                            {
                                (selectedDishData && selectedDishData.availability) ? 
                                    selectedDishData.availability.split(",").map(item => {
                                        return(
                                            <Typography variant='p' fontSize={14} fontFamily={'OnestRegular'} style={{ textTransform: 'capitalize', color: theme.textSecondaryColor}} >
                                                {item.replace(/{|}|"/g, '')}
                                            </Typography>
                                        )
                                    })
                                :
                                    (<></>)
                            }
                        </Row>    

                        {/* <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} >
                            {
                                (selectedDishData?.availability.includes("{") || selectedDishData?.availability.includes("}")) ?
                                `( ${selectedDishData?.availability.replace(/{|}/g, '')} )`
                                :
                                `( ${selectedDishData?.availability} )`
                            }    
                        </Typography> */}

                        {
                            selectedDishData?.additional_information.trim() !== '' ?
                            (
                                <Row className='flex flex-col'>   
                                    <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'} >
                                        Additional Info...
                                    </Typography>
                                    {additionalDescription()}
                                </Row>
                            )
                            :
                            (<></>)

                        }

                        {

                            selectedDishData && selectedDishData.prices.length !== 0 && selectedDishData.prices.map((item, index) => {
                                return(<>
                                    <Typography key={index} variant='h6' fontWeight={600} fontSize={18} fontFamily={'OnestRegular'}>
                                        ₹ {item.price} <span className='font-normal text-lg capitalize' style={{ color: theme.textSecondaryColor}}> ( {item.quantity} )</span>
                                    </Typography>
                                </>)
                            })
                        } 
                    </Col>

                    <Col className='w-full my-2.5'>
                        <Collapse
                            bordered={false}
                            className='food-item-details-reviews'
                            defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            items={getItems(panelStyle)}
                        />
                    </Col>

                    <Col className='w-full'>
                        <Row 
                            className='w-full flex-col items-center justify-center my-4 gap-y-3'
                            style={{ display: user?.access_token ? 'flex' : 'none'}}
                            >
                            <Col>
                                <Typography
                                    variant='h6' 
                                    fontSize={18} 
                                    fontWeight={500} 
                                    fontFamily={'OnestSemibold'} 
                                    color={theme.textColor}
                                >
                                    Write your review
                                </Typography>   
                            </Col>

                            <Col>
                                <Rating 
                                    name="half-rating" 
                                    size="large" 
                                    value={dishRating} 
                                    precision={0.5} 
                                    onChange={(event, value) => setDishRating(value)}
                                    sx={{ fontSize: 40 }} />
                            </Col>

                            <Col className='w-11/12'>
                                <Form
                                    name="create_dish_form_1"
                                    form={form}
                                    className='auth-form'
                                                        
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={handlePostDishReview}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                            className=''
                                            name="dish_review"
                                        >
                                        <TextArea className='onboard-form-input' placeholder='Write you review' rows={3} />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button 
                                            block
                                            htmlType="submit" 
                                            key="submit" 
                                            style={{ backgroundColor: theme.primaryColor, fontFamily: 'OnestRegular'}} 
                                            type="primary" 
                                            size='large'
                                        >
                                            {loading ? <SubmitSpinner /> : 'Post Review' }
                                        </Button>             
                                    </Form.Item> 
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </>
    )
}

export default DishItemDetails;