import { Row, Col } from 'antd';

import UserOnboardForm from '../UserOnboard/sections/UserOnboardForm';
import formLayout from '../Layout/formLayout';


const Onboard = () => {


    return(
        <>
            <Row className=' w-full min-h-full h-auto sm:flex-col sm:items-center bg-neutral-100'>
                <Col className='w-full bg-white rounded-md 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:px-3 sm:my-4' style={{    minHeight: "100vh"}} >
                    
                    <Row className='w-full flex justify-center my-4 ml-2 px-4'>
                        <h1 className='text-xl font-medium' style={{ fontFamily: 'OnestSemibold'}}>Edit Your Profile</h1>
                    </Row>
                    
                    <UserOnboardForm />
                </Col>
            </Row>
        </>
    );
}

export default formLayout(Onboard);