/* eslint-disable default-case */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Input, Upload, Image, message } from 'antd';
import { runes } from 'runes2';

import theme from '../../config/theme/themeVariable';
import SubmitSpinner from '../SubmitSpinner';
import { feedFormImageUpload, setFeedUploadedImage, createFeed, setIsPostImageEmpty } from '../../redux/slices/feedSlice'; 
import img_upload_icon from '../../assets/imgs/img_upload_icon.png';


const { Dragger } = Upload;

const validateImage = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    // console.log(file.file.type)

    return acceptedImageTypes.includes(file.file.type);
};

const PostCreateForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.feed.loading);
    const feedUploadedImage = useSelector(state => state.feed.feedUploadedImage);
    const isPostImageEmpty = useSelector(state => state.feed.isPostImageEmpty);

    const handleFeedCreate = (values) => {
        console.log('here= ',values)
        console.log('--', feedUploadedImage)

        if(feedUploadedImage.length === 0){
            dispatch(setIsPostImageEmpty(true));
        }else{
            dispatch(createFeed({
                reqData: {
                    title: values.feed_caption,
                    imageList: feedUploadedImage
                },
                callback: () => navigate('/feed')
            }));
        }
    };

    // form.setFieldsValue({
    // });

    const customUpload = async (file) => {
        if (!validateImage(file)) {
            message.error('Please upload only image files (JPEG, PNG, GPG)');
            throw new Error('Please upload only image files (JPEG, PNG, GPG)');
        }else{
            try {
                await dispatch(feedFormImageUpload(file));
                message.success('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading Image:', error);
                message.error('Files upload failed.');
            }
        }
    };

    const uploadProps = {
        customRequest: (filedata) => {
           const { onSuccess, onError } = filedata;
           customUpload(filedata)
            .then(() => onSuccess())
            .catch((error) => {
              console.error('Custom upload error:', error);
              onError(error);
            });
        },
        onChange(info) {
            switch (info.file.status) {
              case "error":
                const restData = feedUploadedImage.filter(item => item.originalName !== info.file.name);
                dispatch(setFeedUploadedImage(restData));
            }
        },
        onRemove(file){
            console.log('onremove= ',file)
            const restData = feedUploadedImage.filter(item => item.originalName !== file.name);
            dispatch(setFeedUploadedImage(restData));
            // console.log(restData);
        }
    };

    const handleGoBack = () => {
        dispatch(setIsPostImageEmpty(false))
        navigate('/feed');
    }

    return (
        <>
            <Form
                name="create_post_form"
                form={form}
                className='auth-form'
                style={{ height: '500px'}}             
                initialValues={{
                    remember: true,
                }}
                layout="vertical"
                onFinish={handleFeedCreate}
                autoComplete="off"
            >

                <Form.Item
                    name="feed_caption"
                    className='post_caption_input'
                    label="Caption"
                >
                    <Input 
                        className='update-profile-form-input'
                        count={{
                            show: true,
                            max: 300,
                            strategy: (txt) => runes(txt).length,
                            exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                        }} 
                        placeholder='Enter Caption' />
                </Form.Item> 

                <Form.Item
                    name="feed_picture"
                    className='post_picture_input'
                    label="Picture"
                    style={{ border: isPostImageEmpty ? "1px solid red" : ''}}
                    validateStatus={isPostImageEmpty ? 'error' : 'success'}
                    help={isPostImageEmpty? 'Please upload post image' : ''}
                >
                    <Dragger className='image-drag-box' listType='picture' maxCount={1} accept='image/png, image/jpeg' {...uploadProps} >
                        {/* <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' />
                        <p className="ant-upload-text">Click to Upload</p> */}
                        {/* <Row className='helloooooooo flex flex-col justify-center items-center h-32 overflow-hidden'> */}
                            {
                                feedUploadedImage.length !== 0 ? 
                                    (
                                        <>
                                            <Row className='flex flex-col justify-center items-center h-36 overflow-hidden'>
                                                <Image preview={false} className="imge-upload-box w-full" src={feedUploadedImage[feedUploadedImage.length - 1].image_url || feedUploadedImage[feedUploadedImage.length - 1].url} alt='img' />
                                            </Row>
                                        </>
                                    )
                                :
                                    (
                                        <>
                                            <Row className='flex flex-col justify-center items-center overflow-hidden'>
                                                <Image preview={false} width={50} height={50} src={img_upload_icon} alt='img' />
                                                <p className="ant-upload-text">Click to Upload</p>
                                            </Row>
                                        </>
                                    )

                            }
                        {/* </Row> */}
                    </Dragger>
                </Form.Item> 

                <Form.Item>
                    <Row className='w-full flex'>
                        <Col className='px-4' span={12}>
                            <Button 
                                block
                                size='large'
                                disabled={loading ? true : false}
                                onClick={handleGoBack}
                            >
                                Go Back
                            </Button>
                        </Col>
                        <Col className='px-4' span={12}>
                            <Button 
                                block
                                htmlType="submit" 
                                key="submit" 
                                style={{ backgroundColor: theme.primaryColor}} 
                                type="primary" 
                                size='large'
                            >
                                { loading ? <SubmitSpinner /> : 'Submit'}
                            </Button>
                        </Col>
                    </Row>                
                </Form.Item>    
            </Form>            
        </>
    )
}

export default PostCreateForm