import storage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import auth from './slices/authSlice';
import profile from './slices/profileSlice';
import food from './slices/foodSlice';
import feed from './slices/feedSlice';
import user from './slices/userSlice';
import userSearch from './slices/userSearchSlice';
import notificationSlice from './slices/notificationSlice';
const authPersistConfig = {
  key: 'auth',
  storage,
}

const profilePersistConfig = {
  key: 'profile',
  storage,
}

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    "auth":persistReducer(authPersistConfig,auth),
    "profile" :persistReducer(profilePersistConfig,profile),
    food,
    feed,
    user,
    userSearch,
    notification:notificationSlice,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
