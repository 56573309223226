import { Form, Select } from 'antd';



const FoodCulinaryInfoSection = ({ selectedItemForEdit, cuisinesData = [], tagData = [], dietaryPreferenceData = [], updateCuisinesList, updateTagsList, updateDietaryPreferenceList }) => {

    const cuisinesOptions = [];
    const tagsOptions = [];
    const dietaryPreferenceOptions = [];

    const culinaryCategoryUpdatedData = selectedItemForEdit?.culinary_categories.map(item => item.id);
    const culinaryPrefUpdatedData = selectedItemForEdit?.culinary_preferences.map(item => item.id);
    const dietaryTypeUpdatedData = selectedItemForEdit?.dietary_types.map(item => item.id);

    if(cuisinesData !== null){
        cuisinesData.forEach( (cuisine) => {
            cuisinesOptions.push({ label: cuisine.name, value: cuisine.id})
        })
    }

    if(tagData !== null){
        tagData.forEach( (tag) => {
            tagsOptions.push({ label: tag.name, value: tag.id})
        })
    }

    if(dietaryPreferenceData !== null){
        dietaryPreferenceData.forEach( (item) => {
            dietaryPreferenceOptions.push({ label: item.name, value: item.id})
        })
    }


    const culinaryCategoryProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          height: '55px',
        },
        options: cuisinesOptions,
        onChange: (value) => {
            console.log(value);
            updateCuisinesList(value);
        },
        placeholder: 'Select Culinary Category',
        maxTagCount: 'responsive',
    };

    const culinaryPreferenceProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          height: '55px',
        },
        options: tagsOptions,
        onChange: (value) => {
            console.log(value);
            updateTagsList(value);
        },
        placeholder: 'Select Culinary Cuisines',
        maxTagCount: 'responsive',
    };


    const culinaryTypeProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          height: '55px',
        },
        options: dietaryPreferenceOptions,
        onChange: (value) => {
            console.log(value);
            updateDietaryPreferenceList(value);
        },
        placeholder: 'Select Dietary Type',
        maxTagCount: 'responsive',
    };

    return(
        <>
            <Form.Item
                name="dish_culinary_category"
                initialValue={culinaryCategoryUpdatedData ? culinaryCategoryUpdatedData : []}
                rules={[
                    {
                      required: true,
                      message: 'Please select Culinary Category',
                    },
                ]}
            >
                <Select {...culinaryCategoryProps} />
            </Form.Item>

            <Form.Item
                name="dish_culinary_preference"
                initialValue={culinaryPrefUpdatedData ? culinaryPrefUpdatedData : []}
                rules={[
                    {
                      required: true,
                      message: 'Please select Culinary Cuisine',
                    },
                ]}
            >
                <Select {...culinaryPreferenceProps} />
            </Form.Item>

            <Form.Item
                name="dish_culinary_type"
                initialValue={dietaryTypeUpdatedData ? dietaryTypeUpdatedData : []}
                rules={[
                    {
                      required: true,
                      message: 'Please select Dietary Type',
                    },
                ]}
            >
                <Select {...culinaryTypeProps} />
            </Form.Item>
        </>
    );
}
export default FoodCulinaryInfoSection;