import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Checkbox, Input  } from 'antd';

const ChefServiceType = ({ restaurantTypeData, updateBusinessTypeInputData, updateBusinessTypeOthers }) => {

    const businessTypeInput = useSelector(state => state.profile.businessTypeInput);

    const options = [
        // {
        //   label: 'Delivery',
        //   value: 'delivery',
        // },
        // {
        //   label: 'Catering',
        //   value: 'catering',
        // },
        // {
        //   label: 'Workshop',
        //   value: 'workshop',
        // },

        {
          label: 'Home Delivery',
          value: 'home_delivery',
        },
        {
          label: 'Party & Catering',
          value: 'party_catering',
        },
        {
          label: 'Cooking Workshop',
          value: 'cooking_workshop',
        },
        {
          label: 'Meal Subscription',
          value: 'meal_subscription',
        },
        {
          label: 'Tiffin Service',
          value: 'tiffin_service',
        },
    ];
    const onChange = (checkedValues) => {
        // console.log('checked = ', checkedValues);
        updateBusinessTypeInputData(checkedValues);
    };

    const formattedServiceType = [];
    if(restaurantTypeData){
        const serviceUpdatedData = Object.keys(restaurantTypeData?.kitchen_type).filter(key => restaurantTypeData?.kitchen_type[key] === true);
        serviceUpdatedData.forEach(item => {
            formattedServiceType.push(item)
            // if(item === 'delivery_only'){
            //     formattedServiceType.push('delivery');
            // }else{
            //     formattedServiceType.push(item)
            // }
        });
    }

    const handleInputVal = (e) => {
        e.preventDefault();
        updateBusinessTypeOthers(e.target.value);
    }


    return(
        <>
            <Form.Item
                name="business_type"
                className=''
                initialValue={formattedServiceType ? formattedServiceType : (businessTypeInput || [])}
                rules={[
                    {
                        required: true,
                        message: 'Selection of Establishment Type is required',
                    },
                ]}
            >
                <Checkbox.Group className='service-type-checkbox-group' name="business_type_options" options={options} onChange={onChange} />          
            </Form.Item> 
            <Form.Item
                name="business_type_others"
                className=''
                initialValue={restaurantTypeData?.kitchen_type?.other !== '' && restaurantTypeData?.kitchen_type?.other !== 'none' ? restaurantTypeData?.kitchen_type?.other : ''}
            >
                <Input 
                    className='onboard-form-input' 
                    onChange={handleInputVal}
                    placeholder='Other (Specify)' />
            </Form.Item>
        </>
    );
}

ChefServiceType.propTypes = {
    updateBusinessTypeInputData: PropTypes.func,
}

export default ChefServiceType;