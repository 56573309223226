import { Form, Input } from 'antd';
import { useLocation } from 'react-router-dom';


const { TextArea } = Input;

const DetailsForm = () => {
    const location = useLocation();

    return(
        <>
            <Form.Item
                name="chef_name"
                className=''
                rules={[
                    {
                        required: true,
                        message: 'Full Name is required',
                    },
                ]}
            >
                <Input 
                    className='onboard-form-input' 
                    placeholder='Enter Full Name' />
            </Form.Item> 
            <Form.Item
                    name="chef_description"
                    style={{ display: (location.pathname.includes('user-profile-edit') || location.pathname.includes('user-onboard')) ? 'none': 'block'}}
                    rules={[
                        {
                            required: (location.pathname.includes('user-profile-edit') || location.pathname.includes('user-onboard')) ? false : true,
                            message: 'Description is required',
                        },
                    ]}
                >
                <TextArea className='onboard-form-input' placeholder='Enter about the chef' rows={3} />
            </Form.Item>
        </>
    );
}
export default DetailsForm;