import MyMessage from "./MyMessage";
import SenderMessage from "./SenderMessage";

const RenderMessages = (props) => {
    // console.log('--------------message props---------',props);
    console.log("renderMessages props",props);
    return (
      <>
        {props?.isOwn ? (
            <MyMessage {...props} />
        ) : (
            <SenderMessage {...props} />
        )}
      </>
    );
};

export default RenderMessages;