import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../../config/apiService';
// import { setItem, getItem } from '../../utility/localStorageControl';
import { setCurrentChefStep, setCurrentUserStep, setLogoutOpsProfile } from './profileSlice';
import { setLogoutOpsUser } from './userSlice';
import { setLogoutOpsFood } from './foodSlice';
import { setLogoutOpsUserSearch } from './userSearchSlice';
import { setLogoutOpsFeed } from './feedSlice';
import { setLogoutOpsNotification } from './notificationSlice';
import { removeItem } from '../../utility/localStorageControl';


export const initialState = {
  user: null,
  otpData: null,
  // chef_access_token: getItem('access_token'),
  // chef_phone_number: getItem('phone_number'),
  // is_chef: getItem('is_chef'),
  
  // chef_access_token: getItem('access_token'),
  // chef_phone_number: getItem('phone_number'),
  // is_chef: getItem('is_chef'),
  // user_identity: getItem('user_identity'),

  isLoggedIn: false,
  authFormType: 'Login',

  // userCreated:  getItem('user_step'),

  loading: false,
  error: null,
  serverError: null,
}



export const login = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {

  const body = {
    "is_chef": false,
    "phone_number": `${data.country_code || '+91'}${data.phone}`
  }

  try {

    // console.log('phone login data= ', body)

    const result = await ApiService.post('/login', JSON.stringify(body));

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return { ...result.data, 'phone_number': body.phone_number};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const verifyOtp = createAsyncThunk('auth/verifyOtp', async (data, { rejectWithValue, dispatch }) => {
  try {
    
    const result = await ApiService.post('/confirm-otp', data.reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // setItem('access_token', result.data.access_token);
    // setItem('refresh_token', result.data.refresh_token);
    // setItem('onboard_step', result.data.step);
    // setItem('isLoggedIn',true);
    // setItem('phone_number', result.data.phone_number);
    // setItem('is_chef', result.data.is_chef);
    // setItem('user_step', result.data?.user_step);
    // setItem('user_identity', result.data?.identity);

    dispatch(setActiveOnboardForm(result.data.step));
    dispatch(setCurrentChefStep(result.data.step));
    dispatch(setCurrentUserStep(result.data.user_step ? result.data.user_step : 0));

    console.log('otp data= ', {resData: result.data, callback: data})
    return {resData: result.data, callback: data};
    // return {resData: result.data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const resendOtp = createAsyncThunk('auth/resendOtp', async (data, { rejectWithValue }) => {

  const body = {
    "is_chef": false,
    "phone_number": data.phone_number
  }

  try {

    // console.log('phone login data= ', body)

    const result = await ApiService.post('/login', body);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return { ...result.data, 'phone_number': body.phone_number};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const chefActivation = createAsyncThunk('auth/chefActivation', async (data, { rejectWithValue }) => {

  try {

    const result = await ApiService.put('/restaurant-activation', data?.reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return {resData: result?.data, callback: data};
    
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const logout = createAsyncThunk('auth/logout', async (cb, {dispatch}) => {
  console.log('----logout executed------> ', cb)
  dispatch(setLogoutOpsProfile());
  dispatch(setLogoutOpsUser());
  dispatch(setLogoutOpsFood());
  dispatch(setLogoutOpsUserSearch())
  dispatch(setLogoutOpsFeed());
  dispatch(setLogoutOpsNotification());

  removeItem('userLoc');
  removeItem('userAddress');

  if(cb){
    cb();
  }

});




const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateAccessToken: (state,action) => {
      if(state.user){
        state.user.access_token=action.payload
      }else{
        state.user={"access_token":action.payload}
      }
    },
    setActiveOnboardForm: (state, action) => {
      if(state.user){
        if(state.user.step<action.payload){
          state.user.step = action.payload;
        }
      }else{
        state.user= {"step":action.payload}
      }
    },
    setAuthError: (state, action) => {
      state.error = action.payload;
    },

    setAuthFormType: (state, action) => {
      state.authFormType = action.payload;
    },

    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
    setIdentity: (state, action) => {
      if(state.user){
        state.user.identity = action.payload;
      }else{
        state.user = {"identity":action.payload};
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.otpData = action.payload;
        state.authFormType = 'Otp';
        state.error="";
      })
      .addCase(login.rejected, (state, action) => {
        console.log("authExtraReducer",state,action)
        state.loading = false;
        state.error = action.payload
      })

      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.authFormType = 'Login';
        state.user = action.payload.resData;
        // state.chef_access_token = action.payload.resData.access_token;
        // state.chef_phone_number = action.payload.resData.phone_number;
        // state.userCreated = action.payload.resData.user_step;
        // state.is_chef = action.payload.resData.is_chef;
        // state.user_identity = action.payload.resData.identity;
        if (action.payload.callback) {
          if(action.payload.resData.user_step && action.payload.resData.user_step === 1){
            if(action.payload.resData.is_chef && action.payload.resData.step > 2){
              action.payload.callback.callbackChefProfile();
            }else{
              action.payload.callback.callbackUserProfile()
            }
          }else{
            action.payload.callback.callbackOnboard(); 
          }          
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        console.error(action);
        if(action.payload.message){
          state.error = action.payload.message;
        }else{
          state.error = action.payload;
        }
      })   
      
      .addCase(resendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.authFormType = 'Otp';
        state.error = "";
        state.otpData = action.payload;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
      })

      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.otpData = null;
        state.user = null;
        state.isLoggedIn = false;
        state.error= null;
        state.serverError = null;
        state.authFormType = 'Login';

        // state.chef_access_token = null;
        // state.chef_phone_number = null;
        // state.userCreated = null;
        // state.is_chef = null;
        // state.user_identity = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
      })

      .addCase(chefActivation.pending, (state) => {
        state.loading = true;
      })
      .addCase(chefActivation.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.resData;
        if (action.payload.callback) {
          action.payload.callback.callback();
        }
      })
      .addCase(chefActivation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  }
});

export const { 
  setActiveOnboardForm,
  setUser,
  setIdentity,
  updateAccessToken,
  setAuthError, 
  setAuthFormType ,
  setServerError,
} = authSlice.actions;

export default authSlice.reducer;
