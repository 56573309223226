import { Form, Input } from 'antd';



const { TextArea } = Input;

const FoodAdditionalInfoSection = () => {
    return(
        <>
            <Form.Item
                name="dish_additional_notes"
            >
                <TextArea placeholder='Notes' rows={3} />
            </Form.Item> 
        </>
    );
}
export default FoodAdditionalInfoSection;