
import logoHorizontal from '../../assets/imgs/logo/logo-horizontal.png'; 
import logoVertical from '../../assets/imgs/logo/logo-vertical.png'; 
import chefLogo from '../../assets/imgs/logo/chef-new-logo.png';
import chefLogoWhite from '../../assets/imgs/logo/chef_logo_white.png';
import chefLogoColored from '../../assets/imgs/logo/chef_logo_colored.png';

const theme = {
    // theme colors
    'colorShade1': '#445626',
    'colorShade2': '#52682D',
    'colorShade3': '#6E8B3D',
    'colorShade4': '#7C9D45',
    'colorShade5': '#96B85D',
    'colorShade6': '#171A21',

    
    'primaryColor': '#445626',
    'textColor': '#171A21',
    'paragraphColor': '#b5b1b1',
    'deleteColor': '#860c0c',
    'textSecondaryColor': '#919191',

    // theme logos
    'logoHorizontal': logoHorizontal,
    'logoVertical': logoVertical,
    'chefLogo': chefLogo,
    'chefLogoWhite': chefLogoWhite,
    'chefLogoColored': chefLogoColored,
};

export default theme;  
