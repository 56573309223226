// import { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "antd";
// import { Typography } from "@mui/material";
import bellImg from "../../../assets/imgs/bell.png";
import theme from "../../../config/theme/themeVariable";
import { isIOS , isMobile } from 'react-device-detect';

const TopTitleHead = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { notificationHistoryData,formattedNotificationData } = useSelector(
    (state) => state.notification
  );
  // const [heading, setHeading] = useState();

  // useEffect(() => {
  //   if (location.pathname.includes("user-profile")) {
  //     setHeading("Profile");
  //   }
  //   if (location.pathname.includes("user-chat")) {
  //     setHeading("Chat");
  //   }
  // }, [location.pathname]);

  return (
    <>
      <Row className="border-b-gray-300 sm:border-neutral-100 border-2  w-full flex items-center justify-center py-1.5 sm:py-0"
      style={{ boxShadow: "0px 2px 10px rgb(0 0 0 / 0.1)","paddingTop":(process.env.REACT_APP_BUILDFOR==='bundle' && isIOS && isMobile )?'30px':'0px' }}
      >
        <Row className="w-full 2xs:hidden sm:flex py-1.5 px-5">
          <Col className="w-1/5 flex justify-start px-4">
            {/* <h1 className='text-black mr-auto sm:text-2xl 2xs:text-4xl font-bold'>Chef App</h1> */}
            <Image
              className="chef-logo-desktop"
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>
          <Col
            className="w-1/5 ml-auto flex items-center justify-end px-4"
            onClick={() => navigate("/notifications")}
          >
            <Image width={25} height={25} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-3 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="w-full sm:hidden 2xs:flex py-1.5">
          {/* <Col span={4} className="flex justify-center items-center"></Col>
          <Col span={16} className="flex justify-center items-center">
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={600}
              fontFamily={"OnestRegular"}
            >
              {heading || "NA"}
            </Typography>
          </Col>
          <Col span={4}></Col> */}
          <Col className="w-4/5 px-4">
            {/* <h1 className='text-black sm:text-2xl 2xs:text-3xl font-bold'>Chef App</h1> */}
            <Image
              className="chef-logo-mob"
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>
          <Col className="w-1/5 flex items-center justify-end gap-x-2 px-4" onClick={() => navigate("/notifications")}>
            <Image width={24} height={24} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-5 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default TopTitleHead;
