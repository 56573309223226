/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Avatar, CardContent, Typography, Badge } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import theme from '../../../config/theme/themeVariable';
import { getChefRestaurantId } from '../../../redux/slices/profileSlice';
import { getProfileImage, getUserProfile } from '../../../redux/slices/userSlice';
import { getItem } from '../../../utility/localStorageControl';

const UserProfileBannerSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userImage = useSelector(state => state.user.userImage);
    const createdUserData = useSelector(state => state.user.createdUserData);

    useEffect(() => {
        // dispatch(getChefRestaurantId());
        dispatch(getProfileImage());
        dispatch(getUserProfile())
    },[])

    const handleEditProfileImage = () => {
        navigate('/edit-user-image');
    }

    return (
        <>
            <Card
                variant='plain'
                sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'auto',
                    rowGap: 0,
                    '--icon-size': '100px',
                    backgroundColor: '#fff'
                }}
            >

                {/* <Avatar
                    src="https://images.unsplash.com/photo-1507833423370-a126b89d394b"
                    sx={{ width: 130, height: 130, border: '15px solid #fff' }}  
                /> */}

                <Badge
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClick={handleEditProfileImage}
                    variant="plain"
                    className='user-profile-image-badge-container'
                    badgeContent={
                        <EditIcon fontSize='small' sx={{ color: theme.primaryColor }} />
                    }
                    badgeInset="23%"
                    sx={{ '--Badge-paddingX': '0px' }}
                >
                    <Avatar
                        src={userImage}
                        sx={{ width: 130, height: 130, border: '15px solid #fff' }}  
                    />
                </Badge>

                <CardContent>
                    <Typography fontSize={18} fontWeight={600} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}}>
                        { createdUserData?.full_name || ''}
                    </Typography>

                    <Typography fontSize={12} fontWeight={500} fontFamily={'OnestRegular'} sx={{ textAlign: 'center', color: theme.textSecondaryColor }}>
                        {createdUserData?.mobile_number || ''}
                    </Typography>
                </CardContent>

            </Card>       
        </>
    )
}

export default UserProfileBannerSection;