import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from 'antd';
import ReviewsCard from '../../../components/ReviewsCard';

import { setInitialSelectedDishReviewsCount } from '../../../redux/slices/foodSlice'; 

const DishReviewsSection = ({ selectedDishReviews = [] }) => {
    const dispatch = useDispatch();

    // const initialSelectedDishReviewsCount = useSelector(state => state.food.initialSelectedDishReviewsCount);
    const selectedDishReviewsResponse = useSelector(state => state.food.selectedDishReviewsResponse);

    const handleLoadMore = () => {
        dispatch(setInitialSelectedDishReviewsCount(1))
    }


    // console.log('review data----', selectedDishReviews);
    return (
        <>
            {
                selectedDishReviews.length !== 0 ? 
                    selectedDishReviews.map( item => {
                        return (
                            <>
                                <ReviewsCard key={item.id} review={item} />
                            </>
                        )
                    })    
                :
                    (
                        <>
                            <Row className='w-full bg-gray-100 py-4 px-5 mt-2 mb-2.5' style={{ borderRadius: '5px'}}>
                                No Reviews Found
                            </Row>
                        </>
                    )
            }

            {
                ( selectedDishReviews.length > 0 && selectedDishReviews.length < selectedDishReviewsResponse?.total_records) ? 
                    (
                        <>
                            <Col className='w-full py-2'>
                                <Card
                                    title=""
                                    onClick={handleLoadMore}
                                    style={{
                                        textAlign: 'center',
                                        boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <p>Load More</p>
                                </Card>
                            </Col>
                        </>
                    )
                :
                    (<></>)
                
            }
        </>
    )
}

export default DishReviewsSection;