import { Row, Col } from 'antd';

import TopBannerSection from './sections/TopBannerSection';
import BottomInputSection from './sections/BottomInputSection';

const UserLogin = () => {
    return (
        <Row className='w-full sm:h-screen justify-center items-center' style={{ backgroundColor: '#f6f6f6'}}>
            <Col className='w-full rounded-md bg-white flex flex-col justify-center items-center sm:w-7/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 sm:h-[90%] sm:p-4 '>
                <TopBannerSection />
                <BottomInputSection />
            </Col>
        </Row>
    )
}

export default UserLogin;