/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Row, Col } from "antd";
import ChatList from "../../../components/chat/ChatList";


const PubNubChat = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [pubnub,setPubnub] = useState(null);
  const chefName = useSelector((state) => state.profile.chefName);
  // const channel = useSelector((state) => state.user.channel);
  const userImage = useSelector((state) => state.user.userImage);
  // const [loaded, setLoaded] = useState(false);
  const createpubnub = () => {
    if (user && "identity" in user) {
      return new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE,
        userId: user?.identity,
      });
    }
    return null;
  };
  useEffect(() => {
    if (pubnub === null) {
      setPubnub(createpubnub());
      // dispatch(setPubnub(createpubnub()));
      // console.log("pubnub created-----------", pubnub);
    }
  }, []);
  useEffect(() => {
    if (user && "identity" in user && pubnub) {
      pubnub.objects
        .setUUIDMetadata({
          data: {
            name: chefName,
            profileUrl: userImage,
            custom: {
              phonenumber: user?.phone_number,
              userImage: userImage
            }
          }
        })
        .then((res) => {
          console.log("res", res);
        })
        .catch((error) => console.log("set uuid error-------", error,{
            name: chefName,
            profileUrl: userImage,
            custom: {
              phonenumber: user?.phone_number,
              userImage: userImage
            }
          }));
    }
  }, [pubnub]);

  return (
    <>
      {user && "identity" in user && pubnub ? (
        <Row className="w-full min-h-full h-auto sm:bg-neutral-100 flex justify-center py-2">
          <Col className="w-full bg-white 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12" style={{    minHeight: "100vh"}}>
            <PubNubProvider client={pubnub}>
               <ChatList />
            </PubNubProvider>
          </Col>
        </Row>
      ) : (
        <div className="chat-signin">
          {/* Please <Link to="/">Signin</Link> to chat. */}
        </div>
      )}
    </>
  );
};

export default PubNubChat;
