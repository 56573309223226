import { Form, Input } from 'antd';
import { runes } from 'runes2';


const { TextArea } = Input;

const FoodBasicSection = () => {
    return(
        <>
            <Form.Item
                name="dish_name"
                rules={[
                    {
                        required: true,
                        message: 'Food Name is required',
                    },
                ]}
            >
                <Input 
                    className='update-profile-form-input' 
                    count={{
                        show: true,
                        max: 100,
                        strategy: (txt) => runes(txt).length,
                        exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                    }}
                    placeholder='Enter Food Item Name' />
            </Form.Item> 

            <Form.Item
                name="dish_description"
                rules={[
                    {
                        required: true,
                        message: 'Food Description is required',
                    },
                ]}
            >
                <TextArea placeholder='Enter Short Description' rows={3} />
            </Form.Item> 
        </>
    );
}
export default FoodBasicSection;