import { useSelector } from 'react-redux';
import { Col } from 'antd';

import ChefDishTab from './ChefDishTab';
import ChefReviewTab from './ChefReviewTab';

const ChefDishAndReviews = () => {
    const chefProfileDishReviewTab = useSelector(state => state.user.chefProfileDishReviewTab);
    const cuisinesData = useSelector(state => state.food.cuisinesData);
    const chefAllDishData = useSelector(state => state.user.chefAllDishData);
    const chefReviews = useSelector(state => state.profile.chefReviews);

    return (
        <>
            <Col className='w-full my-3.5'>
                {
                    chefProfileDishReviewTab === 0 ? 
                        (<><ChefDishTab cuisinesData={cuisinesData} chefAllDishData={chefAllDishData} /></>)
                    :
                        (<><ChefReviewTab chefReviews={chefReviews} /></>)
                }
            </Col>
        </>
    )
}

export default ChefDishAndReviews;