import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../../config/apiService';


export const initialState = {
  allDishData: [],
  initialLoadedDishCount: 1,
  allDishResponseData: null,
  foodCreateStep: 0,
  foodBasicFormData: null,
  foodPhotosFormData: null,
  foodDietaryFormData: null,

  availabilityList: null,
  cuisinesList: null,
  tagList: null,
  dietaryPreferenceList: null,
  
  cuisinesData: null,
  tagData: null,
  packageData: null,
  daysData: null,
  deliveryTypeData: null,
  dietaryPreferenceData: null,
  quantityData: null,
  availabilityData: null,

  isDishImageEmpty: false,
  dishImages: [],
  formFoodImages: [],
  createdDishData: null,
  selectedItemForView: null,
  selectedDishReviews: [],
  selectedItemForEdit: null,

  starRatedChefResponse: null,
  starRatedChefData: [],
  initialLoadedStartRatedChefCount: 1,
  topRatedDishResponse: null,
  topRatedDishData: [],
  initialLoadedTopRatedDishCount: 1,
  
  selectedDishReviewsResponse: null,
  selectedDishReviewsData: [],
  initialSelectedDishReviewsCount: 1,


  loading: false,
  error: null
}

export const getTag = createAsyncThunk('food/getTag', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/tag');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getDeliveryType = createAsyncThunk('food/getDeliveryType', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/delivery');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getPackaging = createAsyncThunk('food/getPackaging', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/packaging');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getDietaryPreference = createAsyncThunk('food/getDietaryPreference', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/dietary-preference');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getCuisines = createAsyncThunk('food/getCuisines', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/cuisine', data);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getDays = createAsyncThunk('food/getDays', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/days', data);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getAllDish = createAsyncThunk('food/getAllDish', async (_, { rejectWithValue, getState }) => {
  
  const state = getState();
  // console.log(state.food.initialLoadedDishCount)

  try {

    const result = await ApiService.get(`/dish?page=${state.food.initialLoadedDishCount}&per_page=10`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const CreateDish = createAsyncThunk('food/CreateDish', async (data, { rejectWithValue }) => {


  const totalImages = []
  if(data.reqData.foodPhotosFormData.length !== 0){
    data.reqData.foodPhotosFormData.forEach(item => {
      totalImages.push(item.id);
    })
  }

  const totalQuantityPrice = data.reqData.foodBasicFormData.quantity_price_additional_data;
  const defaultQuanityPrice = {quantity: data.reqData.foodBasicFormData.dish_quantity, price: data.reqData.foodBasicFormData.dish_price};

  const reqData = {
    restaurant_id:  data.reqData.restaurant_id,
    name: data.reqData.foodBasicFormData.dish_name,
    description: data.reqData.foodBasicFormData.dish_description,
    additional_information: data.reqData.foodBasicFormData.dish_additional_notes || '',
    availability: data.reqData.foodBasicFormData.availability.length > 0 ? data.reqData.foodBasicFormData.availability.join(", ") : [] ,
    prices: [defaultQuanityPrice, ...totalQuantityPrice ],
    main_image_id: totalImages[0],
    culinary_preferences: data.reqData.tagList || [],
    culinary_categories: data.reqData.cuisinesList || [],
    dietary_types: data.reqData.dietaryPreferenceList || [],
    additional_images: totalImages
  }

  try {
    const result = await ApiService.post('/dish', reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // return data; // return data response as api response returns success msg only
    return {resData: result.data.data, callback: data};
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const UpdateDish = createAsyncThunk('food/UpdateDish', async (data, { rejectWithValue }) => {


  const totalImages = []
  if(data.reqData.foodPhotosFormData.length !== 0){
    data.reqData.foodPhotosFormData.forEach(item => {
      totalImages.push(item.id);
    })
  }

  const totalQuantityPrice = data.reqData.foodBasicFormData.quantity_price_additional_data;
  const defaultQuanityPrice = {quantity: data.reqData.foodBasicFormData.dish_quantity, price: data.reqData.foodBasicFormData.dish_price.toString()};

  const reqData = {
    restaurant_id:  data.reqData.restaurant_id,
    name: data.reqData.foodBasicFormData.dish_name,
    description: data.reqData.foodBasicFormData.dish_description,
    additional_information: data.reqData.foodBasicFormData.dish_additional_notes || '',
    availability: data.reqData.foodBasicFormData.availability || '',
    prices: [defaultQuanityPrice, ...totalQuantityPrice ],
    main_image_id: totalImages[0],
    culinary_preferences: data.reqData.tagList || [],
    culinary_categories: data.reqData.cuisinesList || [],
    dietary_types: data.reqData.dietaryPreferenceList || [],
    additional_images: totalImages
  }


  try {
    const result = await ApiService.put(`/dish/${data.reqData.dish_id}`, reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // if (data.callback){
    //   data.callback()
    // }
    // return data; // return data response as api response returns success msg only
    return {resData: result.data.data};
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const DeleteDish = createAsyncThunk('food/DeleteDish', async (data, { rejectWithValue }) => {

  // const state = getState();
  // const restFoodData = state.food.allDishData.filter( item => item.id !== data.reqData.id);
  // return { resData: restFoodData, callback: data }

  try {
    const result = await ApiService.delete(`/dish/${data.reqData.id}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return { resData: result.data,  callback: data };
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const uploadDishImages = createAsyncThunk('food/uploadDishImages', async (data, { rejectWithValue }) => {
  
  // const formdata = new FormData();
  // formdata.append('file', data);

  try {
    const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // console.log(result.data);
    return {...result.data, originalName: data.file.name};
    // return result.data.url;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const formFoodImagesUpload = createAsyncThunk('food/formFoodImagesUpload', async (data, { rejectWithValue, dispatch }) => {
  
  // const formdata = new FormData();
  // formdata.append('file', data);

  try {
    const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // console.log(result.data);
    dispatch(setIsDishImageEmpty(false));
    return {...result.data, originalName: data.file.name};
    // return result.data.url;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getDishById = createAsyncThunk('food/getDishById', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/dish/${data}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getQuantity = createAsyncThunk('food/getQuantity', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/quantity');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getAvailability = createAsyncThunk('food/getAvailability', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/availability');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getFoodReviews = createAsyncThunk('food/getFoodReviews', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/dish/${data}/reviews?page=${state.food.initialSelectedDishReviewsCount}&per_page=5`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});


export const getBestRatedChefs = createAsyncThunk('food/getBestRatedChefs', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/restaurant/start-rated?lat=${data.lat}&long=${data.lng}&page=${state.food.initialLoadedStartRatedChefCount}&per_page=10`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getTopRatedDishes = createAsyncThunk('food/getTopRatedDishes', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/dish/top-rated?lat=${data.lat}&long=${data.lng}&page=${state.food.initialLoadedTopRatedDishCount}&per_page=10`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const postDishReview = createAsyncThunk('foos/postDishReview', async (data, { rejectWithValue }) => {
  
  const reqData = {
    review: data.review && data.review !== undefined ? data.review : '',
    rating: parseFloat(data.rating)
  }

  console.log('dish review-------------',reqData)

  try {
    const result = await ApiService.post(`/dish/${data.dishId}/ratings`, reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return { resData: result.data.data, callback: data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

const foodSlice = createSlice({
  name: 'food',
  initialState,
  reducers: {
    setFoodError: (state, action) => {
      state.error = action.payload;
    },
    setDishImages: (state, action) => {
      state.dishImages = action.payload;
    },
    setFoodCreateStep: (state, action) => {
      state.foodCreateStep = action.payload;
    },

    setFoodBasicFormData: (state, action) => {
      state.foodBasicFormData = action.payload;
    },

    setFoodPhotosFormData: (state, action) => {
      state.foodPhotosFormData = action.payload;
    },

    setFoodDietaryFormData: (state, action) => {
      state.foodDietaryFormData = action.payload;
    },

    setFormFoodImages: (state, action) => {
      state.formFoodImages = action.payload;
    },

    setSelectedItemForView: (state, action) => {
      state.selectedItemForView = action.payload;
      // state.selectedDishReviews = action.payload.review_ratings;
    },

    setSelectedDishReviews: (state, action) => {
      state.selectedDishReviews = action.payload;
    },

    setSelectedItemForEdit: (state, action) => {
      state.selectedItemForEdit = action.payload;
    },

    setSelectedItemForEditWithImage: (state, action) => {
      state.selectedItemForEdit = action.payload;
      state.formFoodImages = action.payload.additional_images;
    },

    setInitialLoadedDishCount: (state, action) => {
      state.initialLoadedDishCount += action.payload;
    },

    setInitialLoadedDishCountReset: (state) => {
      state.initialLoadedDishCount = 1;
    },

    setInitialSelectedDishReviewsCount: (state, action) => {
      state.initialSelectedDishReviewsCount += action.payload;
    },

    setInitialSelectedDishReviewsCountReset: (state) => {
      state.initialSelectedDishReviewsCount = 1;
    },
    
    setSelectedDishReviewsData: (state, action) => {
      state.selectedDishReviewsData = action.payload;
    },

    setFoodFormStateClearOnBack: (state) => {
      state.foodBasicFormData = null;
      state.foodPhotosFormData = null;
      state.foodDietaryFormData = null;
      state.formFoodImages = [];
      state.availabilityList = null;
    },

    setAvailabilityList: (state, action) => {
      state.availabilityList = action.payload;
    },

    setCuisinesList: (state, action) => {
      state.cuisinesList = action.payload;
    },

    setTagList: (state, action) => {
      state.tagList = action.payload;
    },

    setDietaryPreferenceList: (state, action) => {
      state.dietaryPreferenceList = action.payload;
    },

    setFoodCulinaryInfoClear: (state) => {
      state.cuisinesList = null;
      state.tagList = null;
      state.dietaryPreferenceList = null;
    },

    setIsDishImageEmpty: (state, action) => {
      state.isDishImageEmpty = action.payload;
    },

    setInitialLoadedStartRatedChefCount: (state, action) => {
      state.initialLoadedStartRatedChefCount += action.payload;
    },

    setInitialLoadedStartRatedChefCountReset: (state) => {
      state.initialLoadedStartRatedChefCount = 1;
    },

    setInitialLoadedTopRatedDishCount: (state, action) => {
      state.initialLoadedTopRatedDishCount += action.payload;
    },

    setInitialLoadedTopRatedDishCountReset: (state) => {
      state.initialLoadedTopRatedDishCount = 1;
    },

    setSelectedDishReviewsResponseReset: (state) => {
      state.selectedDishReviewsResponse = null;
      state.selectedDishReviewsData  = [];
    },

    setLogoutOpsFood: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTag.pending, (state) => {
          state.loading = true;
      })
      .addCase(getTag.fulfilled, (state, action) => {
          state.loading = false;
          state.tagData = action.payload;
      })
      .addCase(getTag.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getPackaging.pending, (state) => {
          state.loading = true;
      })
      .addCase(getPackaging.fulfilled, (state, action) => {
          state.loading = false;
          state.packageData = action.payload;
      })
      .addCase(getPackaging.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getDietaryPreference.pending, (state) => {
          state.loading = true;
      })
      .addCase(getDietaryPreference.fulfilled, (state, action) => {
          state.loading = false;
          state.dietaryPreferenceData = action.payload;
      })
      .addCase(getDietaryPreference.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getCuisines.pending, (state) => {
          state.loading = true;
      })
      .addCase(getCuisines.fulfilled, (state, action) => {
          state.loading = false;
          state.cuisinesData = action.payload;
      })
      .addCase(getCuisines.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getDays.pending, (state) => {
          state.loading = true;
      })
      .addCase(getDays.fulfilled, (state, action) => {
          state.loading = false;
          state.daysData = action.payload;
      })
      .addCase(getDays.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getDeliveryType.pending, (state) => {
          state.loading = true;
      })
      .addCase(getDeliveryType.fulfilled, (state, action) => {
          state.loading = false;
          state.deliveryTypeData = action.payload;
      })
      .addCase(getDeliveryType.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getAllDish.pending, (state) => {
          state.loading = true;
      })
      .addCase(getAllDish.fulfilled, (state, action) => {
          state.loading = false;
          state.allDishData = state.initialLoadedDishCount > 1 ? [...state.allDishData, ...action.payload.data] : action.payload.data;
          state.allDishResponseData = action.payload;
      })
      .addCase(getAllDish.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(uploadDishImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadDishImages.fulfilled, (state, action) => {
          state.loading = false;
          state.dishImages = [...state.dishImages, action.payload];
      })
      .addCase(uploadDishImages.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(CreateDish.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateDish.fulfilled, (state, action) => {
          state.loading = false;
          state.createdDishData = action.payload.resData;
          state.foodCreateStep = 0;
          state.formFoodImages = [];
          state.foodBasicFormData = null;
          state.foodPhotosFormData = null;
          state.foodDietaryFormData = null;
          state.cuisinesList = null;
          state.tagList = null;
          state.dietaryPreferenceList = null;
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(CreateDish.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(UpdateDish.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateDish.fulfilled, (state, action) => {
          state.loading = false;
          state.foodCreateStep = 0;
          state.formFoodImages = [];
          state.foodBasicFormData = null;
          state.foodPhotosFormData = null;
          state.foodDietaryFormData = null;
          state.selectedItemForEdit = null;
          state.availabilityList = null;
          state.cuisinesList = null;
          state.tagList = null;
          state.dietaryPreferenceList = null;
          // console.log("action.payload.callback.callback",action.payload.callback.callback)
          // if (action.payload.callback  && action.payload.callback.callback) {
          //   action.payload.callback.callback();
          // }
      })
      .addCase(UpdateDish.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(formFoodImagesUpload.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(formFoodImagesUpload.fulfilled, (state, action) => {
          state.loading = false;
          state.status = 'done';
          state.formFoodImages = [action.payload];
      })
      .addCase(formFoodImagesUpload.rejected, (state, action) => {
          state.loading = false;
          state.status = 'failed';
          state.error = action.payload
      })

      .addCase(getDishById.pending, (state) => {
          state.loading = true;
      })
      .addCase(getDishById.fulfilled, (state, action) => {
          state.loading = false;
          state.selectedItemForView = action.payload;
      })
      .addCase(getDishById.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getQuantity.pending, (state) => {
          state.loading = true;
      })
      .addCase(getQuantity.fulfilled, (state, action) => {
          state.loading = false;
          state.quantityData = action.payload;
      })
      .addCase(getQuantity.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getAvailability.pending, (state) => {
          state.loading = true;
      })
      .addCase(getAvailability.fulfilled, (state, action) => {
          state.loading = false;
          state.availabilityData = action.payload;
      })
      .addCase(getAvailability.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(DeleteDish.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteDish.fulfilled, (state, action) => {
          state.loading = false;
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(DeleteDish.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getBestRatedChefs.pending, (state) => {
          state.loading = true;
      })
      .addCase(getBestRatedChefs.fulfilled, (state, action) => {
          state.loading = false;
          state.starRatedChefData = state.initialLoadedStartRatedChefCount > 1 ? [...state.starRatedChefData, ...action.payload.data] : action.payload.data;
          state.starRatedChefResponse = action.payload;
      })
      .addCase(getBestRatedChefs.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getTopRatedDishes.pending, (state) => {
          state.loading = true;
      })
      .addCase(getTopRatedDishes.fulfilled, (state, action) => {
          state.loading = false;
          state.topRatedDishData = state.initialLoadedTopRatedDishCount > 1 ? [...state.topRatedDishData, ...action.payload.data] : action.payload.data;
          state.topRatedDishResponse = action.payload
      })
      .addCase(getTopRatedDishes.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getFoodReviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFoodReviews.fulfilled, (state, action) => {
          state.loading = false;
          state.selectedDishReviewsResponse = action.payload;
          state.selectedDishReviewsData = state.initialSelectedDishReviewsCount > 1 ? [...state.selectedDishReviewsData, ...action.payload.data] : action.payload.data ;
      })
      .addCase(getFoodReviews.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(postDishReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDishReview.fulfilled, (state, action) => {
          state.loading = false;
          state.selectedDishReviewsData = [ action.payload.resData, ...state.selectedDishReviewsData];
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(postDishReview.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })
  }
});

export const { 
    setFoodError,
    setDishImages,
    setFoodCreateStep,
    setFoodBasicFormData,
    setFoodPhotosFormData,
    setFoodDietaryFormData,
    setFormFoodImages,
    setSelectedItemForView,
    setSelectedDishReviews,
    setSelectedItemForEdit,
    setSelectedItemForEditWithImage,
    setInitialLoadedDishCount,
    setInitialSelectedDishReviewsCount,
    setFoodFormStateClearOnBack,
    setAvailabilityList,
    setCuisinesList,
    setTagList,
    setDietaryPreferenceList,
    setFoodCulinaryInfoClear,
    setIsDishImageEmpty,
    setInitialLoadedStartRatedChefCount,
    setInitialLoadedTopRatedDishCount,
    setLogoutOpsFood,
    setSelectedDishReviewsData,
    setInitialSelectedDishReviewsCountReset,
    setInitialLoadedStartRatedChefCountReset,
    setInitialLoadedTopRatedDishCountReset,
    setInitialLoadedDishCountReset,
    setSelectedDishReviewsResponseReset
} = foodSlice.actions;

export default foodSlice.reducer;
