import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "antd";
import { Typography } from "@mui/joy";
import LoadingSpinner from "../LoadingSpinner";
import RenderChannelList from "./RenderChannelList";
import { ChannelList, useChannels, useUser } from "@pubnub/react-chat-components";
import { enrichChannelDataList } from "../../redux/slices/userSlice";

const ChatList = () => {
    const dispatch = useDispatch();
    const identity = useSelector((state) => state?.auth?.user?.identity);
    const [channels, fetchPage, total, error, isLoading] = useChannels({
      include: { customFields: true },
      filter:"(id LIKE '"+identity+".*' || (id LIKE '*."+identity+"')) && custom != null",
      sort: { updated: "desc" },
      limit: 5,
    });

    const [ filterListLoaded , setFilterListLoaded ] = useState(false)
    const [ channelsLoading , setChannelsLoading ] = useState(true);
    const formattedChannelDataList = useSelector( (state) => state.user.formattedChannelDataList );
    const formattedChannelDataListLoading = useSelector( (state) => state.user.formattedChannelDataListLoading );

    useEffect(()=>{
        setChannelsLoading(true);
        dispatch(enrichChannelDataList([]));

        return ()=>{
            setFilterListLoaded(true);
            setChannelsLoading(true);
            dispatch(enrichChannelDataList([]));
        }
    },[])

    useEffect(() => {
      if (channels.length < total){
        fetchPage()
      }
      console.log("partial channel",channels)
    }, [channels, total]);
    const [curentUser] = useUser();
    useEffect(()=>{
        if( curentUser){
            const channelsUpdated=[];
            channels.forEach((c)=>{
                const identities = c.id.split('.')
                const identity=identities.find((i)=>{return i!==curentUser.id;});
                const name=c.name.replace(curentUser.name,"").replace(/(^\.|\.$)/,"");

                channelsUpdated.push(Object.assign({},c,{"identity":identity,"name":name}));
            });
            if(total > 0 && channelsUpdated.length >= total){
                console.log("channelsUpdated",channelsUpdated,curentUser, filterListLoaded, channelsLoading, total)
                dispatch(enrichChannelDataList(channelsUpdated));
             
            }

            if (curentUser){
                setChannelsLoading(false);
            }
        }
        // console.log("channels",channels,curentUser)
    },[channels,curentUser]);

    useEffect(()=>{
        console.log("setting filterlistloaded", channels.length,filterListLoaded, channelsLoading,total, isLoading)
        if( channels.length >= total && !channelsLoading ){
            setFilterListLoaded(true);

        }
    },[channels,total,channelsLoading])
    // const channelUsersList = useSelector(state => state.user.channelUsersList);

    // console.log('-----------formattedChannelDataList--------1', filterListLoaded, channelsLoading)

    const channelSorter = (a, b) => {
        const timestampA = new Date(a.updated);
        const timestampB = new Date(b.updated);
        return timestampB - timestampA;
    };

    return (
        <>
            <Row  className="w-full flex px-4">
                {
                    (isLoading || !filterListLoaded || channelsLoading || formattedChannelDataListLoading) && <>
                            <Row className="w-full flex justify-center">
                                <Typography
                                    variant="h3"
                                    fontSize={14}
                                    fontWeight={600}
                                    fontFamily={"OnestRegular"}
                                >
                                    <LoadingSpinner />
                                </Typography>
                            </Row>
                        </>
                }
                {
                    ( (!isLoading && filterListLoaded && !channelsLoading && !formattedChannelDataListLoading) &&( total === 0 && formattedChannelDataList.length===0 ) )&& <>
                        <Row className="w-full flex justify-center">
                            <Typography
                                variant="h3"
                                fontSize={14}
                                fontWeight={600}
                                fontFamily={"OnestRegular"}
                            >
                            Start Conversations...
                            </Typography>

                            <Typography
                                variant="h6"
                                fontSize={9}
                                fontWeight={300}
                                fontFamily={"OnestRegular"}
                            >
                            No current conversations. Connect with fellow chefs to
                            start new conversations.
                            </Typography>
                        </Row>
                    </>
                }
                {
                    (!isLoading && filterListLoaded && !channelsLoading && !formattedChannelDataListLoading ) && (total > 0 && formattedChannelDataList.length>0) && <>
                        <ChannelList
                            channels={formattedChannelDataList.filter(obj => obj.custom)}
                            sort={channelSorter}
                            channelRenderer={(channel) =>
                                RenderChannelList({
                                    channel,
                                    dispatch,
                                })
                            }
                        />
                    </>
                }
            </Row>        
        </>
    )
}

export default ChatList