import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';


const ChefCuisinesTag = ({ restaurantTypeData, cuisinesData, tagData, dietaryPreferenceData, deliveryTypeData, updateCuisinesList, updateTagsList, updateDietaryPreferenceList, updateDeliveryList }) => {

    const cuisinesList = useSelector(state => state.profile.cuisinesList);
    const tagList = useSelector(state => state.profile.tagList);
    const dietaryPreferenceList = useSelector(state => state.profile.dietaryPreferenceList);
    const deliveryTypeList = useSelector(state => state.profile.deliveryTypeList);

    const cuisineUpdatedData = restaurantTypeData?.cuisines.map(item => item.id);
    const dietaryUpdatedData = restaurantTypeData?.dietary_preferences.map(item => item.id);
    const tagsUpdatedData = restaurantTypeData?.tags.map(item => item.id);
    const deliveryUpdatedData = restaurantTypeData?.delivery_type.map(item => item.id);

    const cuisinesOptions = [];
    const tagsOptions = [];
    const dietaryPreferenceOptions = [];
    const deliveryTypeOptions = [];


    if(cuisinesData !== null){
        cuisinesData.forEach( (cuisine) => {
            cuisinesOptions.push({ label: cuisine.name, value: cuisine.id})
        })
    }

    if(tagData !== null){
        tagData.forEach( (tag) => {
            tagsOptions.push({ label: tag.name, value: tag.id})
        })
    }

    if(dietaryPreferenceData !== null){
        dietaryPreferenceData.forEach( (item) => {
            dietaryPreferenceOptions.push({ label: item.name, value: item.id})
        })
    }

    if(deliveryTypeData !== null){
        deliveryTypeData.forEach( (item) => {
            deliveryTypeOptions.push({ label: item.name, value: item.id})
        })
    }

    const selectCuisineProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          width: '100%',
        },
        // value,
        options: cuisinesOptions,
        onChange: (newValue) => {
            console.log(newValue)
            updateCuisinesList(newValue);
        },
        placeholder: 'Enter Culinary Category',
        maxTagCount: 'responsive',
    };

    const selectPreferenceProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          width: '100%',
        },
        // value,
        options: tagsOptions,
        onChange: (newValue) => {
            console.log(newValue)
            updateTagsList(newValue);
        },
        placeholder: 'Enter Culinary Cuisines',
        maxTagCount: 'responsive',
    };

    const selectDietaryProps = {
        mode: 'multiple',
        showSearch: false,
        style: {
          width: '100%',
        },
        // value,
        options: dietaryPreferenceOptions,
        onChange: (newValue) => {
            console.log(newValue)
            updateDietaryPreferenceList(newValue);
        },
        placeholder: 'Enter Dietary Type',
        maxTagCount: 'responsive',
    };

    const selectDeliveryProps = {
        showSearch: false,
        style: {
          width: '100%',
          height: '50px',
        },
        // value,
        options: deliveryTypeOptions,
        onChange: (newValue) => {
            console.log(newValue)
            updateDeliveryList(newValue);
        },
        placeholder: 'Enter Delivery Type',
        maxTagCount: 'responsive',
    };

    return(
        <>
            <Form.Item
                name="cuisine_select"
                className=''
                initialValue={cuisineUpdatedData ? cuisineUpdatedData : (cuisinesList|| [])}
            >
                <Select {...selectCuisineProps} />
            </Form.Item> 

            <Form.Item
                name="dietary_preference_select"
                className=''
                initialValue={tagsUpdatedData ? tagsUpdatedData : (tagList || [])}
            >
                <Select {...selectPreferenceProps} />
            </Form.Item> 

            <Form.Item
                name="dietary_type_select"
                className=''
                initialValue={dietaryUpdatedData ? dietaryUpdatedData : (dietaryPreferenceList || [])}
            >
                <Select {...selectDietaryProps} />
            </Form.Item> 

            <Form.Item
                name="delivery_select"
                className=''
                initialValue={deliveryUpdatedData ? deliveryUpdatedData : (deliveryTypeList || [])}
            >
                <Select {...selectDeliveryProps} />
            </Form.Item> 
        </>
    );
}

ChefCuisinesTag.propTypes = {
    cuisinesData: PropTypes.array,
    tagData: PropTypes.array,
    dietaryPreferenceData: PropTypes.array,
    updateCuisinesList: PropTypes.func,
}

export default ChefCuisinesTag;