import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import SafeArea from 'react-safe-area-component'
import TopLogoHead from './sections/TopLogoHead';
// import BottomNav from './sections/BottomNav';


const logoHeadOnlyLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

  return (
    <>
        <SafeArea>
            <Row className='flex flex-col min-h-screen' style={{paddingBottom:"56px"}}>
                <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                    <TopLogoHead />
                </Col>
                <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto'}}>
                    <ChildComponent {...props} />
                </Col>
                <Col className='footer w-full' style={{ display: 'none', flex: '0 0 auto'}}>
                    {/* <BottomNav /> */}
                </Col>
            </Row>
        </SafeArea>
    </>
  )
}

export default logoHeadOnlyLayout;