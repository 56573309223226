import { Route, Routes } from 'react-router-dom';

import ChefProtectedRoutes from './chefProtectedRoutes';
import UserProtectedRoutes from './userProtectedRoutes';

import Login from '../containers/Login';
import UserSearch from '../containers/UserSearch';
import Profile from '../containers/Profile';
import Onboard from '../containers/Onboard';
import EditProfile from '../containers/EditProfile';
import PublicProfile from '../containers/PublicProfile';
import UserHome from '../containers/UserHome';
import Food from '../containers/Food';
import FoodDetails from '../containers/FoodDetails';
import Feed from '../containers/Feed';
import FoodCreate from '../containers/FoodCreate';
import FoodEdit from '../containers/FoodEdit';
import FeedCreate from '../containers/FeedCreate';
import Reviews from '../containers/pages/Reviews';
import Notifications from '../containers/pages/Notifications';
import Chat from '../containers/Chat';
import UserChefDetails from '../containers/UserChefDetails';
import UserFoodDetails from '../containers/UserFoodDetails';
import UserProfile from '../containers/UserProfile';
import UserLogin from '../containers/UserLogin';
import UserOnboard from '../containers/UserOnboard';
import UserEditProfile from '../containers/UserEditProfile';
import EditUserProfileImage from '../containers/EditUserProfileImage';
import UserReviews from '../containers/pages/UserReviews';
import ChatInstance from '../containers/Chat/ChatInstance';
import NotFound from '../containers/pages/NotFound';


const FrontRoutes = () => {
    return(
        <Routes>
            <Route element={<ChefProtectedRoutes />}>
                <Route index path="/onboard" element={<Onboard />} />
                <Route index path="/profile" element={<Profile />} />
                <Route index path="/public" element={<PublicProfile />} />
                <Route index path="/edit-profile" element={<EditProfile />} />
                
                <Route index path="/food" element={<Food />} />
                <Route index path="/food/:id" element={<FoodDetails />} />
                <Route index path="/create-food" element={<FoodCreate />} />
                <Route index path="/edit-food" element={<FoodEdit />} />

                <Route index path="/feed" element={<Feed />} />
                <Route index path="/create-feed" element={<FeedCreate />} />
                
                <Route index path="/reviews" element={<Reviews />} />
                {/* <Route index path="/notifications" element={<Notifications />} /> */}
                

            </Route>

            <Route element={<UserProtectedRoutes />}>

                <Route index path="/user-profile" element={<UserProfile />} />

                <Route index path="/chat" element={<Chat />} />
                <Route index path="/chat/:chatid" element={<ChatInstance />} />
                
                <Route index path="/user-profile-edit" element={<UserEditProfile />} />
                <Route index path="/edit-user-image" element={<EditUserProfileImage />} />
                <Route index path="/user-reviews" element={<UserReviews />} />
                <Route index path="/notifications" element={<Notifications />} />

            </Route>
            

            <Route index path="/" element={<UserHome />} />
            <Route index path="/chef-login" element={<Login />} />
            <Route index path="/user-login" element={<UserLogin />} />

            <Route index path="/user-onboard" element={<UserOnboard />} />

            <Route index path="/search" element={<UserSearch />} />
            <Route index path="/search/chef-details/:id" element={<UserChefDetails />} />
            <Route index path="/search/food-details" element={<UserFoodDetails />} />
            
            <Route path="*" element={<NotFound />} />
            <Route index path="/404" element={<NotFound />} />
        </Routes>
    );
};

export default FrontRoutes;