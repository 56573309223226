import { Navigate, Outlet } from "react-router-dom";
import store from "../redux/store";

const userProtectedRoutes = () => {
    const isLoggedIn = store.getState()?.auth?.user?.access_token;
    const isUser = store.getState()?.profile?.currentUserStep;
    // const isLoggedIn = true;

    return(
        (isLoggedIn !== null && isLoggedIn !== undefined && isUser > 0) ? 
             <Outlet/>
        : <Navigate to="/user-login"/>
    )
};

export default userProtectedRoutes;