import { useSelector } from 'react-redux';
import { Col } from 'antd';

import FilterList from './FilterList';
import ChefsList from './ChefsList';
import LoadingSpinner from '../../../components/LoadingSpinner';

const ChefTabSection = () => {
    const loading = useSelector(state => state.userSearch.loading);
    const searchedResultDataResponse = useSelector(state => state.userSearch.searchedResultDataResponse);

    return (
        <>
            <Col className='w-full flex flex-col items-center mt-4 mb-3'>    
                <FilterList />
            </Col>

            <Col className='w-full flex flex-col items-center my-4'>    
                {
                    (loading && searchedResultDataResponse === null) ? 
                        (<><LoadingSpinner /></>)
                    :
                        (<><ChefsList /></>)
                }
                
            </Col>        
        </>
    )
}

export default ChefTabSection;