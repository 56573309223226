import { useState } from 'react';
import { Row, Col } from 'antd';
import { Typography, Rating } from '@mui/material';
import theme from '../config/theme/themeVariable';

const ChefReviews = ({ review }) => {
    const [ isMoreActive, setIsMoreActive ] = useState(false);

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }

    return (
        <>
            <Row className='w-full flex-col bg-gray-100 py-4 px-5 mt-2 mb-2.5' style={{ borderRadius: '5px'}}>
                <Col>
                    <Rating name="half-rating" defaultValue={review?.rating} precision={0.5} />
                </Col>
                <Col>
                    <Typography variant='p' fontSize={16} fontFamily={'OnestRegular'}>
                        {
                            review?.review && review?.review.length > 200 ?
                             (!isMoreActive ? review?.review.slice(0, 200) : review?.review) 
                            : 
                             review?.review
                        }

                        <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  { review?.review.length > 200 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                    </Typography>  
                </Col>
                <Col>
                    <Typography variant='p' fontSize={14} fontFamily={'OnestRegular'} sx={{ textTransform: 'capitalize'}} style={{ color: '#858585'}}>
                        {review?.user_name}
                    </Typography> 
                </Col>
            </Row>
        </>
    )
}

export default ChefReviews;