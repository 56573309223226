import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from 'antd';
import { Typography } from '@mui/material';
import { setInitialLoadedSearchCount } from '../../../redux/slices/userSearchSlice';
import ChefSuggestionCard from '../../../components/cards/ChefSuggestionCard';
import SubmitSpinner from '../../../components/SubmitSpinner';

const ChefsList = () => {
    const dispatch = useDispatch();
    const chefSearchedResults = useSelector(state => state.userSearch.chefSearchedResults);
    const searchedResultDataResponse = useSelector(state => state.userSearch.searchedResultDataResponse);
    // const initialLoadedSearchCount = useSelector(state => state.userSearch.initialLoadedSearchCount);
    const loading = useSelector(state => state.userSearch.loading);


    const handleLoadMore = () => {
        dispatch(setInitialLoadedSearchCount(1))
    }

    return (
        <>
            <Row className='w-11/12'>  
                <Col className='w-full flex items-center justify-between'>
                    <Typography variant='h6' fontSize={18} fontWeight={500} fontFamily={'OnestSemibold'}>
                        Chefs
                    </Typography> 
                </Col>

                <Col className='w-full'>
                    {
                        chefSearchedResults && chefSearchedResults.length > 0 ? 
                            chefSearchedResults.map(item => {
                                return(
                                    <>
                                        <ChefSuggestionCard key={item.restaurant_id} chef={item} />
                                    </>
                                );
                            })
                        :
                            (<>No matching chefs found!</>)
                    }

                    {
                        // ( searchedResultDataResponse && searchedResultDataResponse?.data?.restaurant.length >= searchedResultDataResponse?.per_page ) 
                        ( searchedResultDataResponse && chefSearchedResults && chefSearchedResults.length < searchedResultDataResponse?.total_restaurants) 
                        ?
                            (<>
                                <>
                                    <Col className='w-full py-2'>
                                        <Card
                                            title=""
                                            onClick={handleLoadMore}
                                            style={{
                                                textAlign: 'center',
                                                boxShadow: '3px 4px 5px rgb(0 0 0 / 0.1)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {/* <p>Load More</p> */}
                                            {loading ? <><SubmitSpinner /></> : <p>Load More</p>}
                                        </Card>
                                    </Col>
                                </>
                            </>)
                        :
                            (<></>)
                    }                    

                </Col>
            </Row>        
        </>
    )
}

export default ChefsList;