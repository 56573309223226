import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';

import TopTitleHead from './sections/TopTitleHead';
import BottomNav from './sections/BottomNav';
import SafeArea from 'react-safe-area-component'


const titleHeadLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();
    const hideBottomOnChatScreen = useSelector((state)=>state.notification.hideBottomOnChatScreen)

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return (
        <>
            <SafeArea>
                <Row className='flex flex-col min-h-screen' style={{paddingBottom:"56px"}} >
                    <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                        <TopTitleHead />
                    </Col>
                    <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto'}}>
                        <ChildComponent {...props} />
                    </Col>
                </Row>
            </SafeArea>
        </>
    )
}

export default titleHeadLayout;