import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Input, Form, Image } from 'antd';
import { Typography, Rating } from '@mui/material';
import { AspectRatio, Chip } from '@mui/joy';
import { RWebShare } from "react-web-share";

import theme from '../../../config/theme/themeVariable';
import defaultChef from "../../../assets/imgs/defaultchef.jpg";
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import IosShareIcon from '@mui/icons-material/IosShare';
import { postChefReview } from '../../../redux/slices/profileSlice';
// import { setSelectedChefChatInfo, setChatScreenActive } from '../../../redux/slices/userSlice';
import maps from '../../../assets/imgs/maps.png';
import onion_crossed from '../../../assets/imgs/onion_crossed.png'


const { TextArea } = Input;
const serviceNames = {
    cooking_workshop: 'Cooking Workshop',
    home_delivery: 'Home Delivery',
    meal_subscription: 'Meal Subscription',
    party_catering: 'Party Catering',
    tiffin_service: 'Tiffin Service'
};

const ChefBasicInfoUserView = ({ aboutRestaurantInfo , chefImage = [] }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [form] = Form.useForm();

    // const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    const currentUser = useSelector(state => state.auth?.user);
    // console.log('--------------chef data---------------------', currentUser);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ chefRating, setChefRating ] = useState(4)
    const [ isMoreActive, setIsMoreActive ] = useState(false);
    const [ foodSymbol, setFoodSymbol ] = useState([]);
    const [ establishmentType, setEstablishmentType ] = useState([]);

    useEffect(() => {

        if(aboutRestaurantInfo && aboutRestaurantInfo?.dietary_preference.length > 0){
            let foodSymbols = [];

            aboutRestaurantInfo?.dietary_preference.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"   key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 
            })

            setFoodSymbol(foodSymbols)
        }

    },[aboutRestaurantInfo]);

    useEffect(() => {
        if(aboutRestaurantInfo && aboutRestaurantInfo?.establishment_type.length > 0){
            const services = [];
            for (const key in aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ]) {
                if (aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ][key] === true) {
                    services.push(serviceNames[key]);
                }
            }

            if (aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other !== 'none' && aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other !== '') {
                services.push(aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other);
            }

            setEstablishmentType(services);
        }
    },[aboutRestaurantInfo]);

    const showModal = () => {
        if(currentUser){
            setIsModalOpen(true);
        }else{
            navigate('/user-login')
        }
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleChatBtn = () => {
        // navigate('/user-chat');
        // console.log("aboutRestaurantInfo,currentUser",aboutRestaurantInfo,currentUser)
        if(currentUser){
            if(aboutRestaurantInfo?.identity !== null && currentUser.identity){
                const identities = [aboutRestaurantInfo.identity,currentUser.identity];
                const chatpath = "/chat/"+identities.sort().join('.');
                navigate(chatpath); 
            }
        }else{
            navigate('/user-login')
        }
    }

    const handleReviewBtn = (values) => {
        // console.log('post review', { rating: chefRating.toFixed(1), review: values.chef_review, chefId: aboutRestaurantInfo?.restaurant_id });
        dispatch(postChefReview({ rating: chefRating.toFixed(1), review: values.chef_review, chefId: aboutRestaurantInfo?.restaurant_id }));
        handleCancel();
    }

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }
    console.log('--------------chef data---------------------', aboutRestaurantInfo);

    return (
        <>
            <Row className='w-full my-4 py-2'>
                <Col className='w-full'>
                    <Row className='w-full flex flex-row gap-x-4' >
                        <Col span={8} className='w-full'>
                            <AspectRatio ratio="1" sx={{ width: 110, borderRadius: 10 }}>
                                <img
                                    src={aboutRestaurantInfo?.profile_image_url ? aboutRestaurantInfo?.profile_image_url : defaultChef }
                                    srcSet={aboutRestaurantInfo?.profile_image_url ? aboutRestaurantInfo?.profile_image_url : defaultChef }
                                    loading="lazy"
                                    alt="img"
                                />
                            </AspectRatio>
                        </Col>
                        <Col span={14} className='flex flex-col gap-y-1.5'>
                            <Typography 
                                variant='h6' 
                                fontSize={18} 
                                fontWeight={500} 
                                fontFamily={'OnestSemibold'} 
                                sx={{ textTransform: 'capitalize'}}
                                color={theme.textColor}
                            >
                                Chef {aboutRestaurantInfo?.restaurant_name ? aboutRestaurantInfo?.restaurant_name.charAt(0).toUpperCase() + aboutRestaurantInfo?.restaurant_name.slice(1) : ''}
                            </Typography>

                            <Row className='w-full flex gap-x-0.5'>
                            {foodSymbol}
                            </Row>

                            <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor }}>
                                {(aboutRestaurantInfo && aboutRestaurantInfo?.tags.length !== 0 && aboutRestaurantInfo?.tags.map(item => item.name).join(" | ")) || ''}
                            </Typography>

                            <Row className='flex gap-x-2 gap-y-1.5'>
                                <Chip
                                    variant="solid"
                                    size="sm"
                                    sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5,  padding: '2px 9px' }}
                                >
                                    <StarIcon fontSize='sm' /> {aboutRestaurantInfo?.rating ? parseFloat(aboutRestaurantInfo?.rating).toFixed(1) : '0.0'}
                                </Chip>

                                <Chip
                                    variant="solid"
                                    size="sm"
                                    sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5,  padding: '2px 9px' }}
                                >
                                    <ChatIcon fontSize='sm' /> {aboutRestaurantInfo?.reviews || '0'} {aboutRestaurantInfo?.reviews === 1 ? 'Review' : 'Reviews'}
                                </Chip>
                            </Row>

                            <Row className='flex'>
                                <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                { aboutRestaurantInfo?.delivery.length > 0 && aboutRestaurantInfo?.delivery[ aboutRestaurantInfo?.delivery.length - 1].name}
                                </Typography>
                            </Row>

                            <Row className='flex'>
                                <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                    {establishmentType && establishmentType.join(", ")}
                                </Typography>
                            </Row>

                            <RWebShare
                                    data={{
                                        text: "Share Chef Profile",
                                        url: `${process.env.REACT_APP_FRONT_URL}/search/chef-details/${params.id}`,
                                        title: "ChefNet",
                                    }}
                                    // onClick={() => console.log("shared successfully!")}
                            >
                            <Button
                                className="chef-share-btn"
                                icon={<IosShareIcon fontSize='sm' />}
                            >
                                Share
                                </Button>
                            </RWebShare>

                        </Col>
                    </Row>

                    <Row className='w-full flex flex-col gap-y-2 my-4 py-2'>
                        {/* <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                            id="card-description"
                        >
                            About
                        </Typography> */}

                        <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify'}}>
                            {aboutRestaurantInfo?.description && aboutRestaurantInfo?.description.length > 150 ? ( !isMoreActive ? aboutRestaurantInfo?.description.slice(0,150) : aboutRestaurantInfo?.description) : aboutRestaurantInfo?.description }
                            <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  {aboutRestaurantInfo?.description && aboutRestaurantInfo?.description.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                        </Typography>

                    </Row>

                    <Row className='w-full flex flex-col gap-y-2 my-4 py-2'>
                        <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                            id="card-description"
                        >
                            Address
                        </Typography>

                        <Row className='w-full flex items-center gap-x-2'>
                            <Image width={35} src={maps} preview={false} />
                            <Typography 
                                variant='h6' 
                                fontSize={16} 
                                fontWeight={500} 
                                fontFamily={'Onestregular'} 
                                color={theme.textColor}
                                sx={{ textTransform: 'capitalize'}}
                            >
                                {`${aboutRestaurantInfo?.location?.area ? aboutRestaurantInfo?.location?.area+',' : '' } ${aboutRestaurantInfo?.location?.city || ''}`}
                            </Typography>
                        </Row>
                    </Row>

                    <Row 
                        className='w-full flex gap-y-2 my-4 py-2 justify-center'
                        style={{ display: (aboutRestaurantInfo?.identity === currentUser?.identity) ? 'none' : 'flex'  }} 
                        >
                        <Col 
                            className='pr-1 public-profile-btn' 
                            span={12} 
                            // style={{ display: (aboutRestaurantInfo?.identity === currentUser?.identity) ? 'none' : 'block'  }} 
                            >
                            <Button 
                                block
                                onClick={handleChatBtn}
                                style={{ backgroundColor: theme.primaryColor}} 
                                type="primary" 
                                size='large'
                                icon={<ChatIcon fontSize='1rem' />}
                            >
                                Chat Now
                            </Button>
                        </Col>
                        <Col 
                            className='pl-1 public-profile-btn' 
                            span={12}
                            >
                            <Button 
                                block
                                onClick={showModal}
                                size='large'
                                style={{ color: theme.colorShade5, borderColor: theme.colorShade5 }}
                                icon={<StarIcon fontSize='1rem' />}
                            >
                                Rate & Review
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal className='chef-review-modal' open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                <Row className='w-full flex-col items-center justify-center my-2 gap-y-3'>
                    <Col>
                        <Typography
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            Write your review
                        </Typography>   
                    </Col>

                    <Col>
                        <Rating 
                            name="half-rating" 
                            size="large" 
                            // defaultValue={chefRating} 
                            precision={1}
                            value={chefRating}
                            onChange={(event, value) => { setChefRating(value)}} 
                            sx={{ fontSize: 40 }} />
                    </Col>

                    <Col className='w-11/12'>
                        <Form
                            name="create_dish_form_1"
                            form={form}
                            className='auth-form'
                                             
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handleReviewBtn}
                            autoComplete="off"
                        >
                            <Form.Item
                                    className=''
                                    name="chef_review"
                                >
                                <TextArea className='onboard-form-input' placeholder='Write you review' rows={3} />
                            </Form.Item>

                            <Form.Item>
                                <Button 
                                    block
                                    htmlType="submit" 
                                    key="submit" 
                                    style={{ backgroundColor: theme.primaryColor, fontFamily: 'OnestRegular'}} 
                                    type="primary" 
                                    size='large'
                                >
                                    Post Review
                                </Button>             
                            </Form.Item> 
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ChefBasicInfoUserView;