/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, message } from 'antd';
import { Typography } from '@mui/material';

import theme from '../../../config/theme/themeVariable';
import UserLoginForm from '../../../components/forms/UserLoginForm';
import { LeftOutlined } from '@ant-design/icons';
import { setAuthFormType, setAuthError } from '../../../redux/slices/authSlice';

const BottomInputSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const authFormType = useSelector(state => state.auth.authFormType);
    const error = useSelector(state => state.auth.error);


    const errorMessageAlert = (errMsg) => {
        messageApi.open({
          type: 'error',
          content: errMsg,
        });
    };

    useEffect(() => {
        if(error !== null && error !== ""){
            errorMessageAlert(error);
            dispatch(setAuthError(null));
        }
    },[error]);

    const handleLoginBack = () => {
        dispatch(setAuthFormType('Login'));
    }

    
    const handleHomeBack = () => {
        navigate('/');
    }

    return (
        <>
        {contextHolder}
        <Row className='w-full flex justify-center items-center my-4 sm:my-2 py-2 gap-y-5'>

            <Col className='w-full flex flex-col justify-center items-center gap-y-2'>

                {/* <Col className='w-full flex flex-col justify-center items-center gap-y-3'> */}

                {
                    authFormType === 'Otp' ? 
                        (
                            <Col className='w-10/12 flex flex-col  gap-y-3'>
                                <Button 
                                    type="primary" 
                                    icon={<LeftOutlined />} 
                                    style={{ color: theme.textColor, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', background: '#fff' }}
                                    onClick={handleLoginBack} 
                                    size="large" />
                            </Col>    
                        )
                    :
                        (<>
                            <Col className='w-10/12 flex flex-col  gap-y-3'>
                                <Button 
                                    type="primary" 
                                    icon={<LeftOutlined />} 
                                    style={{ color: theme.textColor, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', background: '#fff' }}
                                    onClick={handleHomeBack} 
                                    size="large" />
                            </Col> 
                            <Col className='w-full flex flex-col justify-center items-center gap-y-3'>
                                <Typography variant='h6' fontWeight={600} fontSize={20} fontFamily={'OnestRegular'}>
                                    Begin Your Flavourful Journey
                                </Typography>
                                <Typography variant='p' fontWeight={500} fontSize={12} fontFamily={'OnestRegular'}>
                                    Enter your phone number to continue
                                </Typography>
                            </Col>
                        </>)
                }


                {/* </Col> */}

                <Col className='w-full flex flex-col justify-center items-center'>
                    <UserLoginForm authFormType={authFormType} />
                </Col>
            </Col>

            <Col className='w-full flex justify-center items-center'>
                <Col className='w-10/12 flex justify-center items-center'>
                    <p className='font-normal text-xs' style={{ color: theme.paragraphColor, textAlign: 'center' }}>
                        By clicking on Continue, I confirm that I have read and accepted the T&Cs and Privacy Policy of the app.
                    </p> 
                </Col>
            </Col>
        </Row>
        </>
    )
}

export default BottomInputSection;