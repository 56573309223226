/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Row, Image } from 'antd';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { isIOS , isTablet } from 'react-device-detect';

import { setSearchedKeyword } from '../../../redux/slices/userSearchSlice';
import { 
    setInitialSelectedDishReviewsCountReset, 
    setSelectedDishReviewsData, 
    setInitialLoadedTopRatedDishCountReset, 
    setInitialLoadedStartRatedChefCountReset 
} from '../../../redux/slices/foodSlice';
import { 
    setInitialLoadedChefReviewsCountReset, 
    setChefReviews ,
    setAboutRestaurantInfo
} from '../../../redux/slices/profileSlice';
import { setInitialLoadedReviewsCountReset } from '../../../redux/slices/userSlice';
import { setAuthFormType } from '../../../redux/slices/authSlice';


import home_gray from '../../../assets/imgs/icons/home_gray.png';
import home_colored from '../../../assets/imgs/icons/home_colored.png'

import search_gray from '../../../assets/imgs/icons/search_gray.png';
import search_colored from '../../../assets/imgs/icons/search_colored.png'

import chat_gray from '../../../assets/imgs/icons/chat_gray.png';
import chat_colored from '../../../assets/imgs/icons/chat_colored.png'

import profile_gray from '../../../assets/imgs/icons/profile_gray.png';
import profile_colored from '../../../assets/imgs/icons/profile_colored.png'


const BottomNav = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const userLocation = useSelector(state => state.user.userLocation);
    const chatScreenActive = useSelector(state => state.user.chatScreenActive);
    const user = useSelector(state => state.auth.user);
    // const chef_access_token = useSelector(state => state.user.chef_access_token);

    const [value, setValue] = useState('home');

    useEffect(() => {
        // console.log('url= ',location)
        // if (location.pathname.includes('home')) {
        //     setValue('home');
        // }
        if (location.pathname.includes('search')) {
            setValue('search');
        }
        if (location.pathname.includes('chat')) {
            setValue('chat');
        }
        if (location.pathname.includes('user-profile') || location.pathname.includes('user-reviews')) {
            // console.log(chef_access_token)
            if(user?.access_token === null || user?.access_token  === undefined){
                navigate('/user-login');
            }else{
                setValue('profile');
            }
        }

        if(location.pathname === '/'){
            // console.log('--------pathname-----------',location.pathname)
            dispatch(setInitialLoadedTopRatedDishCountReset());
            dispatch(setInitialLoadedStartRatedChefCountReset());

        }

        
        if (location.pathname.includes("food-details")) {
            dispatch(setAboutRestaurantInfo(null));
        }

        if (!location.pathname.includes("food-details")) {
          dispatch(setInitialSelectedDishReviewsCountReset());
          dispatch(setSelectedDishReviewsData([]))
        }

        if (!location.pathname.includes("chef-details")) {
            dispatch(setInitialLoadedChefReviewsCountReset());
            dispatch(setChefReviews([]))
        }

        if (location.pathname.includes('user-reviews')) {
            dispatch(setInitialLoadedReviewsCountReset());
        }

    }, [location.pathname]);

    const handleNav = (route) => {
        if(route === '/search'){
            dispatch(setSearchedKeyword(null))
        }
        navigate(route);
    }

    return(
        <>
            <Row style={{ boxShadow: '0px -2px 10px rgb(0 0 0 / 0.1)', display: chatScreenActive !== 1 ? 'flex': 'none' , zIndex:9999}} className='footer-container fixed bottom-0 left-0 right-0 bg-white border-b-gray-300 sm:border-neutral-100 w-full flex items-center justify-center py-3.5 sm:py-0 sm:bg-neutral-100'>
                <Box className="w-full bg-white 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12" style={{ paddingBottom: isTablet && isIOS && process.env.REACT_APP_BUILDFOR==='bundle'? '30px':0  }}>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction 
                            onClick={() => handleNav('/')} 
                            label="Home" 
                            value="home" 
                            icon={<Image preview={false} width={value === 'home' ? 22 : 24 } src={value === 'home' ? home_colored : home_gray} />} 
                            />

                        <BottomNavigationAction 
                            onClick={() => handleNav('/search')} 
                            label="Search"
                            value="search" 
                            icon={<Image preview={false} width={value === 'search' ? 22 : 25 } src={value === 'search' ? search_colored : search_gray} />} 
                        />

                        <BottomNavigationAction 
                            onClick={() => handleNav('/chat')} 
                            label="Chat" 
                            value="chat" 
                            icon={<Image preview={false} width={value === 'chat' ? 22 : 23 } src={value === 'chat' ? chat_colored : chat_gray} />} 
                        />

                        <BottomNavigationAction 
                            onClick={() => {dispatch(setAuthFormType('Login')); handleNav((user?.is_chef && user?.step > 2) ? '/profile' : '/user-profile')}} 
                            label="Profile" 
                            value="profile" 
                            icon={<Image preview={false} width={value === 'profile' ? 24 : 28 } src={value === 'profile' ? profile_colored : profile_gray} />} 
                        />

                    </BottomNavigation>
                </Box>
            </Row>
        </>
    );
}

export default BottomNav;
