import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import TopNav from './sections/TopNav';
import BottomNav from './sections/BottomNav';
// import { isIOS } from 'react-device-detect';


const formLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

  return (
    <>
        <Row className='flex flex-col min-h-screen'>
            <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                <TopNav />
            </Col>
            <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto'}}>
                <ChildComponent {...props} />
            </Col>
            <Col className='footer w-full' style={{ display: 'none', flex: '0 0 auto'}}>
                <BottomNav />
            </Col>
        </Row>
    </>
  )
}

export default formLayout;