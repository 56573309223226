import { Navigate, Outlet } from "react-router-dom";
import store from "../redux/store";

const chefProtectedRoutes = () => {
    const isLoggedIn = store.getState()?.auth?.user?.access_token;
    const isChef = store.getState()?.auth?.user?.is_chef
    // const isLoggedIn = true;

    return(
        (isLoggedIn !== null && isLoggedIn !== undefined && isChef === true ) ? <Outlet/> : <Navigate to="/user-profile"/>
    )
};

export default chefProtectedRoutes;