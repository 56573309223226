import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Image } from 'antd';
import { Typography } from '@mui/material';
import { AspectRatio, Chip } from '@mui/joy';
import { RWebShare } from "react-web-share";

import theme from '../../../config/theme/themeVariable';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import IosShareIcon from '@mui/icons-material/IosShare';

import maps from '../../../assets/imgs/maps.png';
import onion_crossed from '../../../assets/imgs/onion_crossed.png'


const serviceNames = {
    cooking_workshop: 'Cooking Workshop',
    home_delivery: 'Home Delivery',
    meal_subscription: 'Meal Subscription',
    party_catering: 'Party Catering',
    tiffin_service: 'Tiffin Service'
};

const ChefBasicDetails = () => {
    // const navigate = useNavigate();

    const [ isMoreActive, setIsMoreActive ] = useState(false);
    const [ foodSymbol, setFoodSymbol ] = useState([]);
    const [ establishmentType, setEstablishmentType ] = useState([]);

    const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    const chefImage = useSelector(state => state.profile.chefImage);
    // console.log('data------', aboutRestaurantInfo)

    useEffect(() => {

        if(aboutRestaurantInfo && aboutRestaurantInfo?.dietary_preference.length > 0){
            let foodSymbols = [];

            aboutRestaurantInfo?.dietary_preference.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"   key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1"  key={data.name} sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 
            })

            setFoodSymbol(foodSymbols)
        }

    },[aboutRestaurantInfo]);

    useEffect(() => {
        if(aboutRestaurantInfo && aboutRestaurantInfo?.establishment_type.length > 0){
            const services = [];
            for (const key in aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ]) {
                if (aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ][key] === true) {
                    services.push(serviceNames[key]);
                }
            }

            if (aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other !== 'none' && aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other !== '') {
                services.push(aboutRestaurantInfo?.establishment_type[aboutRestaurantInfo?.establishment_type.length - 1 ].other);
            }

            setEstablishmentType(services);
        }
    },[aboutRestaurantInfo]);


    // const handleChatBtn = () => {
    //     navigate('/chat');
    // }

    const handleMoreLess = () => {
        setIsMoreActive(prevState => !prevState);
    }

    return (
        <>
            <Row className='w-full my-4 py-2'>
                <Col className='w-full'>
                    <Row className='w-full flex flex-row gap-x-4' >
                        <Col span={8} className='w-full'>
                            <AspectRatio ratio="1" sx={{ width: 110, borderRadius: 10 }}>
                                <img
                                    src={chefImage.length !== 0 ? chefImage[0]?.url : "https://images.unsplash.com/photo-1507833423370-a126b89d394b"}
                                    srcSet={chefImage.length !== 0 ? chefImage[0]?.url : "https://images.unsplash.com/photo-1507833423370-a126b89d394b"}
                                    loading="lazy"
                                    alt="img"
                                />
                            </AspectRatio>
                        </Col>
                        <Col span={14} className='flex flex-col gap-y-1.5'>
                            <Typography 
                                variant='h6' 
                                fontSize={18} 
                                fontWeight={500} 
                                fontFamily={'OnestSemibold'} 
                                color={theme.textColor}
                                sx={{ textTransform: 'capitalize'}}
                                id="card-description"
                            >
                                Chef {aboutRestaurantInfo?.restaurant_name || ''}
                            </Typography>

                            <Row className='w-full flex gap-x-0.5'>
                                {foodSymbol}
                            </Row>

                            <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                {(aboutRestaurantInfo && aboutRestaurantInfo?.tags.length !== 0 && aboutRestaurantInfo?.tags.map(item => item.name).join(" | ")) || ''}
                            </Typography>

                            <Row className='flex gap-x-2 gap-y-1.5'>
                                <Chip
                                    variant="solid"
                                    size="sm"
                                    sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5,  padding: '2px 9px' }}
                                >
                                    <StarIcon fontSize='sm' /> {aboutRestaurantInfo?.rating ? parseFloat(aboutRestaurantInfo?.rating).toFixed(1) : '0'}
                                </Chip>

                                <Chip
                                    variant="solid"
                                    size="sm"
                                    sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5,  padding: '2px 9px' }}
                                >
                                    <ChatIcon fontSize='sm' /> {aboutRestaurantInfo?.reviews || '0'} {aboutRestaurantInfo?.reviews === 1 ? 'Review' : 'Reviews'}
                                </Chip>
                            </Row>

                            <Row className='flex'>
                                <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                    {aboutRestaurantInfo?.delivery.length > 0 && aboutRestaurantInfo?.delivery[ aboutRestaurantInfo?.delivery.length - 1].name}
                                </Typography>
                            </Row>

                            <Row className='flex'>
                                <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                    {establishmentType && establishmentType.join(", ")}
                                </Typography>
                            </Row>

                            <RWebShare
                                    data={{
                                        text: "Share Chef Profile",
                                        url: `${process.env.REACT_APP_FRONT_URL}/search/chef-details/${aboutRestaurantInfo?.restaurant_id}`,
                                        title: "ChefNet",
                                    }}
                                    // onClick={() => console.log("shared successfully!")}
                            >
                            <Button
                                className="chef-share-btn"
                                icon={<IosShareIcon fontSize='sm' />}
                            >
                                Share
                                </Button>
                            </RWebShare>

                        </Col>
                    </Row>

                    <Row className='w-full flex flex-col gap-y-2 my-2 py-2'>
                        {/* <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                            id="card-description"
                        >
                            About
                        </Typography> */}

                        <Typography variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor, textAlign: 'justify'}}>
                            {aboutRestaurantInfo?.description?.length > 150 ? ( !isMoreActive ? aboutRestaurantInfo?.description.slice(0,150) : aboutRestaurantInfo?.description) : aboutRestaurantInfo?.description }
                            <span style={{ color: theme.colorShade1 }} onClick={handleMoreLess}>  {aboutRestaurantInfo?.description?.length > 150 ? !isMoreActive ? ' ...More' : ' ...Less' : ''}</span>
                        </Typography>

                    </Row>

                    <Row className='w-full flex flex-col gap-y-2 my-4 py-2'>
                        <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                            id="card-description"
                        >
                            Address
                        </Typography>

                        <Row className='w-full flex items-center gap-x-2'>
                            <Image width={35} src={maps} preview={false} />
                            <Typography 
                                variant='h6' 
                                fontSize={16} 
                                fontWeight={500} 
                                fontFamily={'OnestRegular'} 
                                color={theme.textColor}
                                id="card-description"
                                sx={{ textTransform: 'capitalize'}}
                            >
                                {`${aboutRestaurantInfo?.location?.area ? aboutRestaurantInfo?.location?.area+',' : ''}, ${aboutRestaurantInfo?.location?.city || ''}`}
                            </Typography>
                        </Row>
                    </Row>

                    <Row className='w-full flex gap-y-2 my-2 py-2'>
                        <Col className='pr-1 public-profile-btn' span={12} >
                            <Button 
                                block
                                style={{ backgroundColor: theme.primaryColor}} 
                                type="primary" 
                                size='large'
                                // onClick={handleChatBtn}
                                icon={<ChatIcon fontSize='1rem' />}
                            >
                                Chat Now
                            </Button>
                        </Col>
                        <Col className='pl-1 public-profile-btn' span={12}>
                            <Button 
                                block
                                size='large'
                                style={{ color: theme.colorShade5, borderColor: theme.colorShade5 }}
                                icon={<StarIcon fontSize='1rem' />}
                            >
                                Rate & Review
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default ChefBasicDetails;