import { Row, Col } from 'antd';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import SafeArea from 'react-safe-area-component'

import TopNav from './sections/TopNav';
import BottomNav from './sections/BottomNav';
// import { isIOS } from 'react-device-detect';


const layout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();
    const [ bottomPadding, setBottomPadding ] = useState(0);
    const hideBottomOnChatScreen = useSelector((state)=>state.notification.hideBottomOnChatScreen);

    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    useEffect(()=>{
        const footer = document.querySelector('.footer-container');
        if(footer){
            setBottomPadding(footer.clientHeight)
            // console.log(document.querySelector('.footer-container').clientHeight);
        }
    },[]);


    return (
        <>
            <SafeArea>
                <Row className='flex flex-col min-h-screen ' style={{paddingBottom:hideBottomOnChatScreen?0:"56px"}} >
                    <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                        <TopNav />
                    </Col>
                    <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto' }}>
                        <ChildComponent {...props} style={{ paddingBottom: bottomPadding }} />
                    </Col>
                    <Col className='footer w-full' style={{ flex: '0 0 auto' }}>
                        <BottomNav />
                    </Col>
                </Row>
            </SafeArea>
        </>
    )
}

export default layout;