import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "../../config/apiService";

export const initialState = {
  notificationHistoryData: [],
  formattedNotificationData: [],
  updateHistorySuccess: false,
  loading: false,
  error: null,
  serverError: null,
  hideBottomOnChatScreen: "false"
};

export const getNotificationHistory = createAsyncThunk(
  "notification/getNotificationHistory",
  async (_, { rejectWithValue }) => {
    try {
      const result = await ApiService.get("/user-notification");

      if (result.data.error) {
        return rejectWithValue(result.data.message);
      }

      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateNotificationHistory = createAsyncThunk(
  "notification/updateNotificationHistory",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const result = await ApiService.put(`/user-notification/${id}`);

      if (result.data.error) {
        return rejectWithValue(result.data.message);
      }

      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addUserNotificationHistory = createAsyncThunk(
  "notification/addUserNotificationHistory",
  async (data, { rejectWithValue }) => {
    const data_info = data;
    try {
      const result = await ApiService.post("/user-notification", data_info);

      if (result.data.error) {
        return rejectWithValue(result.data.message);
      }

      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const clearAllNotifications = createAsyncThunk(
  "notification/clearAllNotifications",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const result = await ApiService.post("/mark-notifications-as-read");

      if (result.data.error) {
        return rejectWithValue(result.data.message);
      }

      dispatch(getNotificationHistory())
      return result.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setUpdateHistorySuccess: (state, action) => {
      state.updateHistorySuccess = false;
    },
    setFormattedNotificationData: (state, action) => {
      state.formattedNotificationData = action.payload;
    },
    setHideBottomOnChatScreen: (state,action) => {
      state.hideBottomOnChatScreen = action.payload;
    },
    setLogoutOpsNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateNotificationHistory.pending, (state) => {
        state.loading = true;
        state.updateHistorySuccess = true;
      })
      .addCase(updateNotificationHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.updateHistorySuccess = false;
      })
      .addCase(updateNotificationHistory.rejected, (state, action) => {
        state.loading = false;
        state.updateHistorySuccess = false;
        state.error = action.payload;
      })
      .addCase(getNotificationHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationHistoryData = action.payload;
      })
      .addCase(getNotificationHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUserNotificationHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUserNotificationHistory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addUserNotificationHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(clearAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(clearAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        // state.notificationHistoryData = action.payload;
      })
      .addCase(clearAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { 
  setUpdateHistorySuccess, 
  setFormattedNotificationData, 
  setHideBottomOnChatScreen, 
  setLogoutOpsNotification 
} = notificationSlice.actions;
export default notificationSlice.reducer;
