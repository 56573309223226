import {useState,useEffect} from 'react';
import {useNavigate} from 'react-router';
import { Row, Col } from "antd";
import moment from "moment";
import {setHideBottomOnChatScreen } from "../../redux/slices/notificationSlice";

import {
  setChannel,
  setChatScreenActive,
  setSelectedChefChatInfo,
} from "../../redux/slices/userSlice";
import { Typography, Avatar } from "@mui/joy";



const RenderChannelList = (props) => {
    const navigate = useNavigate();
    const handleChannelSwitch = (data) => {
      props.dispatch(setHideBottomOnChatScreen(true));
      props.dispatch(setChannel(data?.id));
      props.dispatch(setSelectedChefChatInfo(data));
      props.dispatch(setChatScreenActive(1));
    };
    const [chatpath,setChatpath]=useState("/chat");
    useEffect(()=>{
      setChatpath("/chat/"+props.channel.id)
    },[props.channel]);

    return (
      <>
        <Row
          key={props.channel.id}
          onClick={() =>{
            navigate(chatpath);
          }}
          className="friend w-full flex py-4 border-b-2 border-slate-200"
          style={{ overflowX: "hidden" }}
        >
          <Col className="flex justify-center items-center" span={19}>
            <Col span={6}>
              <Avatar
                src={props?.channel?.profileUrl}
                size="lg"
              />
            </Col>
            <Col span={18}>
              <Typography
                variant="h3"
                fontSize={16}
                fontWeight={600}
                fontFamily={"OnestRegular"}
                sx={{ textTransform: "capitalize" }}
              >
                {props.channel?.name} 
              </Typography>
              <Typography
                variant="h6"
                fontSize={14}
                fontWeight={500}
                fontFamily={"OnestRegular"}
              >
                {props.channel?.custom?.lastMessage}
              </Typography>
            </Col>
          </Col>
  
          <Col className="flex items-center" span={5}>
            <Typography
              variant="h6"
              fontSize={12}
              fontWeight={500}
              fontFamily={"OnestRegular"}
            >
              {props.channel?.custom?.timestamp
                ? moment(parseInt(props.channel?.custom?.timestamp)).fromNow()
                : moment(props.channel?.updated).fromNow()}
            </Typography>
          </Col>
        </Row>
      </>
    );
};

export default RenderChannelList;