/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Collapse, Row, Col,message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { createUserProfile, getUserProfile, updateUserProfile,setProfileOnBoardError } from '../../redux/slices/userSlice';

import theme from '../../config/theme/themeVariable';
import DetailsForm from './ChefDetails';
import LocationForm from './ChefLocation'
import ContactForm from './ChefContactForm';
import SubmitSpinner from '../SubmitSpinner';
// import { getItem } from '../../utility/localStorageControl';



const UserOnboardInfoForm = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("hee")
    const [form] = Form.useForm();

    const loading = useSelector(state => state.profile.loading);
    const createdUserData = useSelector(state => state.user.createdUserData);
    const currentUserStep = useSelector(state => state.profile.currentUserStep);
    const userAddress = useSelector(state => state.user.userAddress);
    const userError=useSelector(state => state.user.error);
    console.log("stateInfo",userError)
    const errorMessageAlert = (errMsg) => {
        messageApi.open({
          type: 'error',
          content: errMsg,
        });
    };

    useEffect(() => {
        if(userError !== null){
            errorMessageAlert(userError);
            dispatch(setProfileOnBoardError(null));
        }
    },[userError]);
    const [location, setLocation] = useState({
        address: '',
        lat: 13.019144,
        lng: 77.646454,
    });
    const [ userFullLocData, setuserFullLocData ] = useState(null);

    console.log('userFullLoc----', location)

    useEffect(() => {
        if(currentUserStep && currentUserStep > 0){
            dispatch(getUserProfile());
        }
    },[])


    if(createdUserData){

        form.setFieldsValue({
            chef_name: createdUserData?.full_name,
            email: createdUserData?.email,
            alternate_phone: createdUserData?.mobile_number.slice(3),

            address: createdUserData?.address,
            house_number:  createdUserData?.house_number,
            area: createdUserData?.area,
            pincode: createdUserData?.zipcode,
            country: createdUserData?.country,
            city: createdUserData?.city,
            state: createdUserData?.state,
        });
    }

    useEffect(() => {
        if(userFullLocData !== null){
            console.log('userFullLoc----',userFullLocData)
            const resPostal = userFullLocData.find(item => item.hasOwnProperty('postal_code'));
            const resState = userFullLocData.find(item => item.hasOwnProperty('state'));
            const resCity = userFullLocData.find(item => item.hasOwnProperty('locality'));
            const resCountry = userFullLocData.find(item => item.hasOwnProperty('country'));

            form.setFieldsValue({
                pincode: resPostal?.postal_code,
                country: resCountry?.country,
                city: resCity?.locality,
                state: resState?.state
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFullLocData]);


    const handleProfileData = (values) => {
        console.log('clicked----------', userAddress)
          
        if(!createdUserData || createdUserData.isEdit === false ){
          dispatch(createUserProfile({ requestData: {...values, userAddress, location_info: location}, callback: () => navigate('/user-profile') }));
          // console.log('create info call= ',{...values, location_info: location});
        }else{

          dispatch(updateUserProfile({ 
            requestData: {
              ...values, 
              userAddress,
              location_info: (location?.lat !== 13.019144 && location?.lng !==  77.646454) ? 
                  location : 
                  { 
                      lat: createdUserData?.lat, 
                      lng: createdUserData?.long, 
                      address: createdUserData?.search_address
                  } ,
            },
            callback: () => navigate('/user-profile')
        }));
        }
          
    }

    const updateLocation = (loc, fullLocData) => {
        setLocation(loc);
        setuserFullLocData(fullLocData)
    }

    const panelStyle = {
      marginBottom: 20,
      background: '#fff',
      border: 'none',
    };

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Name',
          children: <DetailsForm />,
          style: panelStyle,
        },
        {
          key: '2',
          label: 'Address',
          children: 
          <>
            {
                (currentUserStep === 0 ) ? 
                    (<>
                        <LocationForm 
                            locationAddressData={{
                                place_name: location.address, 
                                latitude: location.lat, 
                                longitude: location.lng  
                            }} 
                            location={location} 
                            updateLocation={updateLocation} />
                    </>)
                :   

                (currentUserStep > 0 && createdUserData !== null) ? 
                    (<>
                        <LocationForm 
                            locationAddressData={{
                                // place_name: createdUserData?.address || location.address, 
                                // latitude: createdUserData?.lat || location.lat, 
                                // longitude: createdUserData?.long || location.lng 
                                place_name: (currentUserStep > 0 && location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location?.address : createdUserData?.search_address,
                                latitude: (currentUserStep > 0 && location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location?.lat : createdUserData?.lat, 
                                longitude: (currentUserStep > 0 && location?.lat !== 13.019144 && location?.lng !==  77.646454) ? location?.lng : createdUserData?.long, 
                            }} 
                            location={location} 
                            updateLocation={updateLocation} />
                    </>)  
                :
                    (<></>)
            }
            
        </>,
          style: panelStyle,
        },
        {
          key: '3',
          label: 'Contact',
          children: <ContactForm />,
          style: panelStyle,
        },
    ];

    const handleBack = () => {
        navigate(-1);
    }

    return(
        <>
            <Form
                name="onboard_form_1"
                form={form}
                className='auth-form'
                                    
                initialValues={{
                    remember: true,
                }}
                onFinish={handleProfileData}
                autoComplete="off"
            >

                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2', '3']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    items={getItems(panelStyle)}
                />

                <Form.Item>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12} style={{ display: !createdUserData || createdUserData.isEdit === false ? 'none' : 'block'}}>
                        <Button 
                            block
                            size='large'
                            onClick={handleBack}
                            disabled={loading ? true : false}
                        >
                           Go Back
                        </Button>
                    </Col>
                    <Col className='px-4' span={!createdUserData || createdUserData.isEdit === false ? 24 : 12}>
                        <Button 
                            block 
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                            // onClick={handleProfileSubmit}
                        >
                            { loading ? <SubmitSpinner /> : createdUserData && createdUserData.isEdit ? 'Update': 'Create'}
                        </Button>
                    </Col>
                </Row>

                </Form.Item>    
            </Form>            
        </>
    );
}

export default UserOnboardInfoForm;
