import { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import layout from "../Layout/layout";
// import ProfileBannerSection from './sections/ProfileBannerSection';
// import ProfileSettingSection from './sections/ProfileSettingSection';
import { getChefRestaurantId, getRestaurantImageInfo, getAboutRestaurant, updateChefAvailability } from '../../redux/slices/profileSlice';
import { getAllDish } from '../../redux/slices/foodSlice';
import LoadingSpinner from '../../components/LoadingSpinner';

const ProfileBannerSection = lazy(() => import('./sections/ProfileBannerSection'));
const ProfileSettingSection = lazy(() => import('./sections/ProfileSettingSection'));

const Profile = ({ style }) => {
    const dispatch = useDispatch();
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const chefName = useSelector(state => state.profile.chefName);
    const chefImage = useSelector(state => state.profile.chefImage);
    const bannerImage = useSelector(state => state.profile.bannerImage);
    const aboutRestaurantInfo = useSelector(state => state.profile.aboutRestaurantInfo);
    const allDishResponseData = useSelector(state => state.food.allDishResponseData);

    // console.log('imgs------',chefImage, bannerImage)

    console.log(bannerImage)

    useEffect(() => {
            dispatch(getAllDish());
            dispatch(getChefRestaurantId());
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(aboutRestaurantInfo?.is_available && allDishResponseData?.data.length === 0 && restaurantId){
            const reqData = {
                restaurant_id: restaurantId,
                is_available: false
            }
            dispatch(updateChefAvailability(reqData));
        }
    },[aboutRestaurantInfo?.is_available, allDishResponseData, restaurantId, dispatch]);

    useEffect(() => {
        if(restaurantId !== null){
            dispatch(getRestaurantImageInfo());
            dispatch(getAboutRestaurant(restaurantId));
        }
        // eslint-disable-next-line
    },[restaurantId]);

    return (
        <Suspense
            fallback={
                <LoadingSpinner />
            }
        >
            <Row className='w-full h-full 2xs:h-full sm:h-auto sm:min-h-full sm:bg-neutral-100 flex justify-center' style={style}>  
                <Col className='flex flex-col items-center w-full bg-white w-10/12 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12 sm:p-5' style={{    minHeight: "100vh"}} >
                    <Col className='2xs:w-10/12 sm:w-11/12 2xs:my-5 sm:my-0'>
                        <ProfileBannerSection 
                            aboutRestaurantInfo={aboutRestaurantInfo} 
                            chefName={chefName} 
                            chefImage={chefImage.length !== 0 ? chefImage[0].url : ''} 
                            bannerImage={bannerImage.length !== 0 ? bannerImage[0].url : ''} />
                    </Col> 
                    <Col className='2xs:w-10/12 sm:w-11/12'>
                        <ProfileSettingSection 
                            chef_availability={aboutRestaurantInfo?.is_available} 
                            chef_notification={aboutRestaurantInfo?.is_notification_on} />
                    </Col> 
                </Col>    
            </Row>
        </Suspense>
    )
}

export default layout(Profile);