/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'antd';

import layout from "../Layout/layout";
import { getAllDish } from '../../redux/slices/foodSlice';
import FoodItemDetails from './sections/FoodItemDetails';

const FoodDetails = ({style}) => {
    const dispatch = useDispatch();
    const allDishData = useSelector(state => state.food.allDishData);

    useEffect(() => {
        if(allDishData.length === 0){
            dispatch(getAllDish());
        }
    },[allDishData])

    return (
        <Row className='w-full sm:h-auto sm:min-h-screen sm:bg-neutral-100 flex justify-center py-2' style={style}>
            <FoodItemDetails key="FoodDetails" />
        </Row>
    )
}

export default layout(FoodDetails);