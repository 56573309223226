/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row ,Col} from 'antd';
import searchHeadLayout from "../UserLayout/searchHeadLayout";
import { getSearchedResult, setSelectedTab, setInitialLoadedSearchCountReset } from '../../redux/slices/userSearchSlice';
import { getDietaryPreference } from '../../redux/slices/foodSlice';
import ChefTabSection from './sections/ChefTabSection';
import DishTabSection from './sections/DishTabSection';

const UserSearch = () => {
    const dispatch = useDispatch();
    const [ isSearchStarted, setIsSearchStarted ] = useState(false);

    const userLocation = useSelector(state => state.user.userLocation);
    const selectedTab = useSelector(state => state.userSearch.selectedTab);
    const searchedKeyword = useSelector(state => state.userSearch.searchedKeyword);
    const dietaryPreferenseFilterData = useSelector(state => state.userSearch.dietaryPreferenseFilterData);
    const initialLoadedSearchCount = useSelector(state => state.userSearch.initialLoadedSearchCount);

    const searchedResultDataResponse = useSelector(state => state.userSearch.searchedResultDataResponse);
    

    useEffect(() => {
        dispatch(setInitialLoadedSearchCountReset());
        dispatch(getDietaryPreference());
    },[])

    useEffect(() => {
        // console.log('--------search effect----------',searchedKeyword, dietaryPreferenseFilterData)
        dispatch(getSearchedResult({ lat: userLocation.lat, lng: userLocation.lng, search: searchedKeyword , dietaryPrefFilter: dietaryPreferenseFilterData}));
    },[searchedKeyword, dietaryPreferenseFilterData, initialLoadedSearchCount])

    useEffect(() => {
        if(searchedKeyword !== null && searchedKeyword.trim() !== ""){

            if(selectedTab === 0 && searchedResultDataResponse && searchedResultDataResponse?.total_restaurants === 0 && searchedResultDataResponse?.total_dishes > 0){
                
                dispatch(setSelectedTab(1));
                setIsSearchStarted(true)
            }
            console.log("selectedTab",selectedTab,isSearchStarted ,searchedResultDataResponse,searchedResultDataResponse?.total_restaurants > 0,searchedResultDataResponse?.total_dishes === 0)
            if(selectedTab === 1 && searchedResultDataResponse && searchedResultDataResponse?.total_dishes === 0 && searchedResultDataResponse?.total_restaurants > 0){
                
                dispatch(setSelectedTab(0));
                setIsSearchStarted(true)
            }

        }
    },[searchedKeyword, searchedResultDataResponse])

    return (
        <>
            <Row>
            <Col className='w-full 2xs:h-full sm:h-auto sm:min-h-full sm:bg-neutral-100 flex flex-col justify-start sm:items-center py-2' style={{ flexFlow: 'column'}}>
                <Row className='w-full flex flex-col justify-start bg-white 2xl:w-3/12 xl:w-4/12 lg:w-4/12 md:w-5/12 sm:w-6/12' style={{    minHeight: "100vh"}} >
                    {
                        selectedTab === 0 ? 
                            (<> <ChefTabSection /></>)
                        :
                            (<> <DishTabSection /></>)
                    }
                </Row>
            </Col>
            </Row>
        </>
    )
}

export default searchHeadLayout(UserSearch);