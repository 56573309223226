/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Collapse, Button, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import theme from '../../config/theme/themeVariable';
import FoodCulinaryInfoSection from './FoodCulinaryInfoSection';
import SubmitSpinner from '../SubmitSpinner';
import { setFoodCreateStep, CreateDish, UpdateDish, setCuisinesList, setTagList, setDietaryPreferenceList } from '../../redux/slices/foodSlice'; 

const FoodCreateDietaryInfoForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const loading = useSelector(state => state.food.loading);
    const restaurantId = useSelector(state => state.profile.restaurantId);
    const cuisinesData = useSelector(state => state.food.cuisinesData);
    const tagData = useSelector(state => state.food.tagData);
    const dietaryPreferenceData = useSelector(state => state.food.dietaryPreferenceData);

    const foodBasicFormData = useSelector(state => state.food.foodBasicFormData);
    const foodPhotosFormData = useSelector(state => state.food.foodPhotosFormData);
    const selectedItemForEdit = useSelector(state => state.food.selectedItemForEdit);

    const cuisinesList = useSelector(state => state.food.cuisinesList);
    const tagList = useSelector(state => state.food.tagList);
    const dietaryPreferenceList = useSelector(state => state.food.dietaryPreferenceList);

    // const [ cuisinesList, setCuisinesList ] = useState(null);
    // const [ tagList, setTagList ] = useState(null);
    // const [ dietaryPreferenceList, setDietaryPreferenceList ] = useState(null);

    const updateCuisinesList = (value) => {
        dispatch(setCuisinesList(value));
    }

    const updateTagsList = (value) => {
        dispatch(setTagList(value));
    }

    const updateDietaryPreferenceList = (value) => {
        dispatch(setDietaryPreferenceList(value));
    }

    // console.log('cuisines list-------------', cuisinesList);
    if(cuisinesList){
        form.setFieldsValue({
            dish_culinary_category: cuisinesList
        });
    }
    if(tagList){
        form.setFieldsValue({
            dish_culinary_preference: tagList
        });
    }
    if(dietaryPreferenceList){
        form.setFieldsValue({
            dish_culinary_type: dietaryPreferenceList
        });
    }

    const handleFoodCreateSubmit = () => {
        if(!selectedItemForEdit){
            dispatch(CreateDish({ 
                reqData: { 
                    restaurant_id: restaurantId, 
                    foodBasicFormData, 
                    foodPhotosFormData, 
                    cuisinesList, 
                    tagList, 
                    dietaryPreferenceList 
                },
                callback: () => navigate('/food')
            })).then((res)=>{
                if(!res?.error?.message === 'Rejected'){
                    navigate('/food');
                }
            });
            // dispatch(setFoodCreateStep(3));
        }else{
            dispatch(UpdateDish({
                reqData:{ 
                    restaurant_id: restaurantId, 
                    dish_id: selectedItemForEdit.id,
                    foodBasicFormData, 
                    foodPhotosFormData, 
                    // cuisinesList,
                    cuisinesList: cuisinesList || selectedItemForEdit?.culinary_categories.map(item => item.id), 
                    tagList: tagList || selectedItemForEdit?.culinary_preferences.map(item => item.id), 
                    dietaryPreferenceList: dietaryPreferenceList ||  selectedItemForEdit?.dietary_types.map(item => item.id)
                }
            }))

        }
    };

    // form.setFieldsValue({
    //     dish_culinary_category: [1, 2]
    // });

    const panelStyle = {
        marginBottom: 20,
        background: '#fff',
        border: 'none',
    };

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Culinary Information',
            children: <FoodCulinaryInfoSection 
                        selectedItemForEdit={selectedItemForEdit} 
                        cuisinesData={cuisinesData} 
                        tagData={tagData} 
                        dietaryPreferenceData={dietaryPreferenceData} 
                        updateCuisinesList={updateCuisinesList} 
                        updateTagsList={updateTagsList} 
                        updateDietaryPreferenceList={updateDietaryPreferenceList} />,
            style: panelStyle,
        },
    ];

    const handleBackBtn = () => {
        dispatch(setFoodCreateStep(1));
    }

  return (
    <>
        <Form
            name="create_dish_form_3"
            form={form}
            className='auth-form'
            style={{ height: '410px'}}                
            initialValues={{
                remember: true,
            }}
            onFinish={handleFoodCreateSubmit}
            autoComplete="off"
        >

            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2', '3', '4']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={getItems(panelStyle)}
            />

            <Form.Item>
                <Row className='w-full flex'>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            size='large'
                            disabled={loading ? true : false}
                            onClick={handleBackBtn}
                        >
                           Go Back
                        </Button>
                    </Col>
                    <Col className='px-4' span={12}>
                        <Button 
                            block
                            htmlType="submit" 
                            key="submit" 
                            style={{ backgroundColor: theme.primaryColor}} 
                            type="primary" 
                            size='large'
                        >
                            { loading ? <SubmitSpinner /> : 'Submit'}
                        </Button>
                    </Col>
                </Row> 
            </Form.Item>    
        </Form>            
    </>
  )
}

export default FoodCreateDietaryInfoForm;