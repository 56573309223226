/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Image } from "antd";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getReverseGeocode } from "../../../redux/slices/userSlice";
import theme from "../../../config/theme/themeVariable";
import bellImg from "../../../assets/imgs/bell.png";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { isIOS , isMobile } from 'react-device-detect';

const TopLocationHead = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);
  const userAddress = useSelector((state) => state.user.userAddress);
  const user = useSelector((state) => state.auth.user);
  const { notificationHistoryData, formattedNotificationData } = useSelector(
    (state) => state.notification
  );
  // useEffect(() => {
  //   console.log("user loc");
  //   if (userLocation !== null) {
  //     // dispatch(getReverseGeocode(userLocation));
  //   }
  // }, [userLocation]);

  // console.log(userLocation);
  // console.log("userAddress",userAddress)
  return (
    <>
      <Row
        className="border-b-gray-300 sm:border-neutral-100 border-2  w-full flex items-center justify-center py-2 sm:py-0"
        style={{ boxShadow: "0px 2px 10px rgb(0 0 0 / 0.1)","paddingTop":(process.env.REACT_APP_BUILDFOR==='bundle' && isIOS && isMobile )?'30px':'0px' }}
      >
        <Row className="w-full 2xs:hidden sm:flex py-2 px-5 gap-y-2">
          <Col
            span={8}
            className=" flex items-center px-4 chef-logo-desktop-box"
          >
            <Image
              className="chef-logo-desktop"
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>

          <Col span={8} className=" flex justify-center items-center px-4">
            <Col>
              <PlaceOutlinedIcon sx={{ color: "#e24040" }} />
            </Col>

            <Col>
              <Typography
                className="user-location-address"
                variant="p"
                fontSize={14}
                fontWeight={500}
                fontFamily={"OnestRegular"}
              >
                {/* {userAddress} */}
                Bangalore
              </Typography>
            </Col>
          </Col>

          <Col
            span={8}
            className=" ml-auto flex items-center justify-end px-4"
            onClick={() => navigate("/notifications")}
            style={{ display: user ? 'flex' : 'none'}}
          >
            <Image width={25} height={25} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-3 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="w-full sm:hidden 2xs:flex pb-2 pt-2">
          {/* <Row className="flex justify-center items-center">
            <Col className="w-4/5 px-4 flex items-center gap-x-1">
              <Image
                width={60}
                preview={false}
                src={theme.chefLogoColored}
                alt="logo"
              />
            </Col>
            <Col
              className="w-1/5 flex items-center justify-end gap-x-2 px-4"
              onClick={() => navigate("/notifications")}
            >
              <Image width={25} height={25} preview={false} src={bellImg} />
              {notificationHistoryData.length > 0 ? (
                <div
                  className="relative text-white -left-5 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                  style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
                >
                  {notificationHistoryData.length}
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="px-3 flex">
            <Col>
              <PlaceOutlinedIcon sx={{ color: "#e24040" }} />
            </Col>

            <Col>
              <Typography
                className="user-location-address"
                variant="p"
                fontSize={14}
                fontWeight={500}
                fontFamily={"OnestRegular"}
              >
                {userAddress}
              </Typography>
            </Col>
          </Row> */}

          <Col
            span={8}
            className=" flex items-center px-4 chef-logo-desktop-box"
          >
            <Image
              className="chef-logo-desktop"
              width={'85%'}
              preview={false}
              src={theme.chefLogoColored}
              onClick={() => navigate('/')}
            />
          </Col>

          <Col span={8} className=" flex justify-center items-center px-4">
            <Col>
              <PlaceOutlinedIcon sx={{ color: "#e24040" }} />
            </Col>

            <Col>
              <Typography
                className="user-location-address"
                variant="p"
                fontSize={14}
                fontWeight={500}
                fontFamily={"OnestRegular"}
              >
                {/* {userAddress} */}
                Bangalore
              </Typography>
            </Col>
          </Col>

          <Col
            span={8}
            className=" ml-auto flex items-center justify-end px-4"
            onClick={() => navigate("/notifications")}
            style={{ display: user ? 'flex' : 'none'}}
          >
            <Image width={25} height={25} preview={false} src={bellImg} />
            {notificationHistoryData.length > 0 ? (
              <div
                className="relative text-white -left-3 mt-4 w-5 h-5 items-center justify-center text-center rounded-full"
                style={{ backgroundColor: "#F04A4C", textAlign: "center" }}
              >
                {notificationHistoryData.length}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default TopLocationHead;
