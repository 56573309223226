import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';

import TopTitleHead from './sections/TopTitleHead';
import BottomNav from './sections/BottomNav';
import SafeArea from 'react-safe-area-component'


const titleHeadLayout = (ChildComponent) => (props) => {
    const { pathname } = useLocation();
    const hideBottomOnChatScreen = useSelector((state)=>state.notification.hideBottomOnChatScreen);
    const [ bottomPadding, setBottomPadding ] = useState(0);
    
    useEffect(() => {
        if(!pathname.includes('chat')){
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    useEffect(()=>{
        const footer = document.querySelector('.footer-container');
        if(footer){
            setBottomPadding(footer.clientHeight)
            // console.log(document.querySelector('.footer-container').clientHeight);
        }
    },[]);

    return (
        <>
            <SafeArea>
                <Row className='flex flex-col min-h-screen' style={{paddingBottom:hideBottomOnChatScreen?0:"56px"}} >
                    <Col className='header w-full' style={{ flex: '0 0 auto'}}>
                        <TopTitleHead />
                    </Col>
                    <Col className='main-content w-full' style={{ flex: '1', overflowY: 'auto'}}>
                        <ChildComponent {...props} style={{ paddingBottom: (bottomPadding - 30) }} />
                    </Col>
                    <Col className='bg-white footer w-full' style={{ flex: '0 0 auto' }}>
                        <BottomNav />
                    </Col>
                </Row>
            </SafeArea>
        </>
    )
}

export default titleHeadLayout;