/* eslint-disable no-fallthrough */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Row , Button } from 'antd';
import { AspectRatio, Card, CardContent, Typography } from '@mui/joy';
import { setAboutRestaurantInfo } from '../../redux/slices/profileSlice';
import { setSelectedItemForView, setSelectedDishReviewsResponseReset } from '../../redux/slices/foodSlice';
import onion_crossed from '../../assets/imgs/onion_crossed.png'

import StarIcon from '@mui/icons-material/Star';
import theme from '../../config/theme/themeVariable';
import defaultFood from '../../assets/imgs/defaultdish.png';

const DishListCard = ({ food }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ foodSymbol, setFoodSymbol ] = useState([]);
    
    useEffect(() => {

    
        if(food && food?.dietary_types.length > 0){
            let foodSymbols = [];

            food?.dietary_types.forEach( data => {
                if(data.name === 'Non-Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/non-vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegetarian'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="non-veg-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Egg-less'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-eggs.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="egg-less-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Vegan'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/natural-food.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="vegan-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Gluten-Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/color/48/no-gluten.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="gluten-free-symbol"
                            />
                        </AspectRatio>
                    )
                }  else if(data.name === 'Dairy Free'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/no-milk.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="dairy-free-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Keto'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/external-those-icons-lineal-color-those-icons/24/external-Avocado-fruits-and-vegetables-those-icons-lineal-color-those-icons.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="keto-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Paleo'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src="https://img.icons8.com/pulsar-color/48/paleo-diet.png"
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt="paleo-symbol"
                            />
                        </AspectRatio>
                    )
                } else if(data.name === 'Jain'){
                    foodSymbols.push(
                        <AspectRatio ratio="1" sx={{ width: 15, borderRadius: '10px' }}>
                            <img
                                src={onion_crossed}
                                loading="lazy"
                                width="48" 
                                height="48"
                                alt=""
                            />
                        </AspectRatio>
                    )
                } 

            })
            setFoodSymbol(foodSymbols)
        }

    },[food])


    const handleDishDetailsDisplay = () => {
        if(location.pathname.includes('public')){
            dispatch(setSelectedItemForView(food));
            // dispatch(setSelectedDishReviewsResponseReset());
            navigate(`/food/${food?.id}`, { state: { foodItemData: food }})
        }else{
            dispatch(setAboutRestaurantInfo(null));
            dispatch(setSelectedItemForView(null));
            dispatch(setSelectedDishReviewsResponseReset());
            navigate('/search/food-details', { state: { selectedDishData: food }})
        }
    }

    return (
        <>
            <Col 
                className='w-full py-2'
                onClick={handleDishDetailsDisplay}
                >
                <Card
                    variant="plain"
                    orientation="horizontal"
                    sx={{
                        width: '100%',
                        flexDirection: 'row-reverse',
                        borderBottom: '1px solid #dadada',
                        padding: '10px 0',
                    }}
                >
                    <Col>
                        <AspectRatio ratio="1" sx={{ width: 130, borderRadius: '10px' }}>
                            <img
                                src={food?.main_image_url || defaultFood}
                                srcSet={food?.main_image_url || defaultFood}
                                loading="lazy"
                                alt="img"
                            />
                        </AspectRatio>
                        <div className='flex flex-col items-center -mt-4'>
                            <Button ghost={false} className="px-5" shape="round"  style={{color:'#9CBC66',borderColor:'#9CBC66', fontWeight:900}} >RATE</Button>
                        </div>
                    </Col>
                    <CardContent sx={{ rowGap: 0 }}>
                        <Row className='gap-x-1'>
                            {foodSymbol}
                        </Row>

                        <Typography 
                            variant='h6' 
                            fontSize={18} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            {food?.name?.length < 17 ? food?.name : food?.name?.slice(0,14)+"..." || ''}
                        </Typography>

                        <Typography 
                            variant='h6' 
                            fontSize={16} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            sx={{ color: theme.colorShade3 }}
                        >
                            ₹ {food?.prices[0].price} ( {food?.prices[0].quantity} )
                        </Typography>

                        <Typography
                            variant='p' 
                            fontSize={14} 
                            fontWeight={500} 
                            fontFamily={'OnestSemibold'} 
                            color={theme.textColor}
                        >
                            <StarIcon fontSize='sm' /> {food?.average_rating ? parseFloat(food?.average_rating).toFixed(1) : '0'} ({food?.total_reviews})
                        </Typography>

                        <Typography
                            variant='p' 
                            fontSize={14} 
                            fontWeight={400} 
                            fontFamily={'OnestRegular'} 
                            color={theme.textColor}
                        >
                            {(food?.description.length < 21 ? food?.description : `${food?.description.slice(0, 17)}...` ) || ''}
                        </Typography>
                    </CardContent>
                </Card>
            </Col>
        </>
    )
}

export default DishListCard;