import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../../config/apiService';



export const initialState = {
    initialFeedLoadedCount: 1,
    allFeedData: [],
    allFeedResponse: null,

    isPostImageEmpty: false,
    feedUploadedImage: [],
    createdFeed: null,

    nearByPostResponse: null,
    nearByPostsData: [],
    initialNearbyPostsCount: 1,

    loading: false,
    error: null,
};

export const getAllFeeds = createAsyncThunk('food/getAllFeeds', async (_, { rejectWithValue, getState }) => {
    const state = getState();
    try {
  
      const result = await ApiService.get(`/post?page=${state.feed.initialFeedLoadedCount}&per_page=10`);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
  
      return result.data;
      
    } catch (err) {
      return rejectWithValue(err);
    }
});


export const feedFormImageUpload = createAsyncThunk('feed/feedFormImageUpload', async (data, { rejectWithValue, dispatch }) => {
  
    try {
      const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 

      dispatch(setIsPostImageEmpty(false));
      // console.log(result.data);
      return {...result.data, originalName: data.file.name};
      // return result.data.url;
      
    } catch (err) {
      return rejectWithValue(err);
    }
  
});

export const createFeed = createAsyncThunk('feed/createFeed', async (data, { rejectWithValue }) => {
  console.log('---------feed data-----------', data)
    const totalImages = []
    if(data.reqData.imageList.length !== 0){
      data.reqData.imageList.forEach(item => {
        totalImages.push(item.id);
      })
    }

    const reqData = {
        title: data.reqData.title ? data.reqData.title.slice(0,10) : '',
        image_id: totalImages[0],
        text: data.reqData.title ? data.reqData.title : ''
    }

    try {
      const result = await ApiService.post('/post', reqData);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
  
    //   return result.data.data;
      return {resData: result.data.data, callback: data};
      
    } catch (err) {
      return rejectWithValue(err);
    }
});

export const deleteFeed = createAsyncThunk('feed/deleteFeed', async (data, { rejectWithValue, getState }) => {
    const state = getState();
    const restFeedData = state.feed.allFeedData.filter( item => item.post_id !== data.post_id);

    try {
      const result = await ApiService.delete(`/post/${data.post_id}`);
  
      if (result.data.error) {
        return rejectWithValue(result.data.message);
      } 
  
      return { resData: result.data, restFeed: restFeedData };
      
    } catch (err) {
      return rejectWithValue(err);
    }
});

export const getNearbyPosts = createAsyncThunk('feed/getNearbyPosts', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/posts/nearby?lat=${data.lat}&long=${data.lng}&page=${state.feed.initialNearbyPostsCount}&per_page=5`);

    if (result.data.error || result.status >= 400) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getNearbyUserPosts = createAsyncThunk('feed/getNearbyUserPosts', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {

    const result = await ApiService.get(`/posts/nearby-user?lat=${data.lat}&long=${data.lng}&page=${state.feed.initialNearbyPostsCount}&per_page=5`);

    if (result.data.error || result.status >= 400 ) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const feedLike = createAsyncThunk('feed/feedLike', async (data, { rejectWithValue, dispatch }) => {
  
  try {
    const result = await ApiService.post(`/post-like/${data?.id}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // dispatch(getNearbyUserPosts(data?.loc));
    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const feedDisLike = createAsyncThunk('feed/feedDisLike', async (data, { rejectWithValue, dispatch }) => {
  
  try {
    const result = await ApiService.put(`/post-dislike/${data?.id}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // dispatch(getNearbyUserPosts(data?.loc));
    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});


const feedSlice = createSlice({
    name: 'feed',
    initialState,
    reducers: {
      setFeedError: (state, action) => {
        state.error = action.payload;
      },
      setFeedUploadedImage: (state, action) => {
        state.feedUploadedImage = action.payload;
      },
      setInitialNearbyPostsCount: (state, action) => {
        state.initialNearbyPostsCount += action.payload;
      },
      setInitialFeedLoadedCount: (state, action) => {
        state.initialFeedLoadedCount += action.payload;
      },

      setInitialFeedLoadedCountReset: (state) => {
        state.initialFeedLoadedCount = 1;
      },

      setNearByPostResponseReset : (state) => {
        state.nearByPostResponse = null;
        state.nearByPostsData = [];
      },

      setInitialNearbyPostsCountReset: (state) => {
        state.initialNearbyPostsCount = 1;
      },

      setIsPostImageEmpty: ( state, action ) => {
        state.isPostImageEmpty = action.payload;
      },

      setLogoutOpsFeed: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFeeds.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllFeeds.fulfilled, (state, action) => {
                state.loading = false;
                state.allFeedResponse = action.payload;
                state.allFeedData = state.initialFeedLoadedCount > 1 ? [...state.allFeedData, ...action.payload.data] : action.payload.data;
            })
            .addCase(getAllFeeds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(feedFormImageUpload.pending, (state) => {
                state.loading = true;
            })
            .addCase(feedFormImageUpload.fulfilled, (state, action) => {
                state.loading = false;
                state.feedUploadedImage = [action.payload];
            })
            .addCase(feedFormImageUpload.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(createFeed.pending, (state) => {
                state.loading = true;
            })
            .addCase(createFeed.fulfilled, (state, action) => {
                state.loading = false;
                state.feedUploadedImage = [];
                state.createdFeed = action.payload.resData;
                if (action.payload.callback) {
                  action.payload.callback.callback();
                }
            })
            .addCase(createFeed.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(deleteFeed.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFeed.fulfilled, (state, action) => {
                state.loading = false;
                state.allFeedData = action.payload.restFeed;
            })
            .addCase(deleteFeed.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(getNearbyPosts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNearbyPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.nearByPostResponse = action.payload;
                state.nearByPostsData = state.initialNearbyPostsCount > 1 ? [...state.nearByPostsData, ...action.payload.data] : action.payload.data;
            })
            .addCase(getNearbyPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(feedLike.pending, (state) => {
                state.loading = true;
            })
            .addCase(feedLike.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(feedLike.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(feedDisLike.pending, (state) => {
                state.loading = true;
            })
            .addCase(feedDisLike.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(feedDisLike.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            .addCase(getNearbyUserPosts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNearbyUserPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.nearByPostResponse = action.payload;
                state.nearByPostsData = state.initialNearbyPostsCount > 1 ? [...state.nearByPostsData, ...action.payload.data] : action.payload.data;
            })
            .addCase(getNearbyUserPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })
    }
});

export const { 
    setFeedError, 
    setFeedUploadedImage,
    setInitialFeedLoadedCount,
    setInitialNearbyPostsCount,
    setLogoutOpsFeed,
    setInitialFeedLoadedCountReset,
    setIsPostImageEmpty,
    setNearByPostResponseReset,
    setInitialNearbyPostsCountReset
} = feedSlice.actions;
  
export default feedSlice.reducer;