import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../../config/apiService';
import { getItem } from '../../utility/localStorageControl';
import { setActiveOnboardForm, setUser , setIdentity } from './authSlice';
import { setCurrentChefStep, setCurrentUserStep, setChefName } from './profileSlice';
import { setItem } from '../../utility/localStorageControl';

export const initialState = {
    userLocation: getItem('userLoc'),
    userAddress: getItem('userAddress'),
    userReverseLoc: null,
    locationFetchLoader: false,

    chefProfileDishReviewTab: 0,
    chefAllDishData: null,

    userProfileImage: [],
    isUserProfileImageEmpty: false,
    userImage: null,
    userImageData: null,

    initialLoadedReviewsCount: 1,
    userReviews: [],
    userReviewsResponse: null,

    createdUserData: null,

    publicViewFilterValue: null,
    searchChefViewFilterValue: null,
    chefDishesUserViewFilterValue: null,

    channel: '',
    selectedChefChatInfo: null,
    chatScreenActive: 0,
    isChatStarted: true,

    pubnub: null,
    channelUsersList: [],
    channelFullList: [],
    formattedChannelDataListLoading: false,
    formattedChannelDataList: [],
    userPicIdentity: null,
    userPicIdentityPending: false,
    iOS:()=>{
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },

    cityList: [],
    stateList: [],

    loading: false,
    error: null,
    serverError: null,
}

export const getChefAllDish = createAsyncThunk('user/getChefAllDish', async (_, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/dish');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});


export const uploadUserProfilePic = createAsyncThunk('user/uploadUserProfilePic', async (data, { rejectWithValue, dispatch }) => {
  
  // const formdata = new FormData();
  // formdata.append('file', data);

  try {
    const result = await ApiService.post('/image-uploads', data, { 'Content-Type': 'multipart/form-data'});

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 
    // console.log(result.data);
    dispatch(setIsUserProfileImageEmpty(false));
    return {...result.data, originalName: data.file.name};
    // return result.data.url;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getProfileImage = createAsyncThunk('user/getProfileImage', async (data, { rejectWithValue }) => {
  try {
    const result = await ApiService.get('/profile-pic');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const postUserProfilePic = createAsyncThunk('user/postUserProfilePic', async (data, { rejectWithValue }) => {
  
  const totalImages = []
  if(data.userProfilePic.length !== 0){
    data.userProfilePic.forEach(item => {
      totalImages.push(item.id);
    })
  }

  try {

    const reqData = {
      "image_id": totalImages.length > 0 ? totalImages[0] : null
    };

    const result = await ApiService.post('/profile-pic', reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return {callback: data};
    // return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getUserAllReviews = createAsyncThunk('user/getUserAllReviews', async (data, { rejectWithValue, getState }) => {
  const state = getState();
  try {
    const result = await ApiService.get(`/my-all-review?page=${state.user.initialLoadedReviewsCount}&per_page=10`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserProfile = createAsyncThunk('user/getUserProfile', async (data, { rejectWithValue, dispatch }) => {
  try {

    const result = await ApiService.get('/profile');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    }

    // console.log("res details= ", result.data.identity);
    setItem('userLoc', JSON.stringify({lat : result.data.lat, lng : result.data.long }));
    dispatch(setChefName(result.data?.full_name))
    if (result.data.identity){
      // console.log("result.data",result.data);
      dispatch(setIdentity(result.data.identity));
    }
    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUserProfile = createAsyncThunk('user/createUserProfile', async (data, { rejectWithValue, dispatch }) => {

  console.log('create info call= ', data.requestData);

  const regex = /\+91/;
  // const checkNumber = (number) => {
  //   if(regex.test(data.alternate_phone)){
  //     return data.alternate_phone ;
  //   }else{
  //     return `+91${data.alternate_phone}`;
  //   }
  // }

  const reqData = {
    full_name:  data.requestData.chef_name,
    email: data.requestData.email,
    mobile_number: regex.test(data.requestData.phone) ? data.requestData.phone :  `+91${data.requestData.phone}`,
    image_id: null,
    lat: `${data.requestData.location_info.lat}`,
    long:`${data.requestData.location_info.lng}`,
    address: data.requestData.address,
    search_address: data.requestData.location_info.address,
    country:  data.requestData.country,
    zipcode: data.requestData.pincode,
    city: data.requestData.city,
    state: data.requestData.state,
    house_number: data.requestData.house_number,
    area: data.requestData.area
  }
  

  console.log("create final= ", reqData);
  try {

    const result = await ApiService.post('/profile', JSON.stringify(reqData));

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 


    dispatch(setUserLocation({lat : data.requestData.location_info.lat, lng : data.requestData.location_info.lng}));
    // dispatch(setUserAddress(data.requestData.location_info.address));
    dispatch(setUserAddress(data.requestData.city));

    setItem('userLoc', JSON.stringify({lat : data.requestData.location_info.lat, lng : data.requestData.location_info.lng}));
    // setItem('userAddress', data.requestData.location_info.address);
    setItem('userAddress', data.requestData.city);
    dispatch(setCurrentUserStep(1));

    console.log("res chef details= ", result.data.data);
    return { resData: result.data.data, callback: data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (data, { rejectWithValue, dispatch }) => {

  console.log('update info call= ', data.requestData);

  const regex = /\+91/;

  const reqData = {
    full_name:  data.requestData.chef_name,
    email: data.requestData.email,
    mobile_number: regex.test(data.requestData.phone) ? data.requestData.phone :  `+91${data.requestData.phone}`,
    image_id: null,
    lat: `${data.requestData.location_info.lat}`,
    long:`${data.requestData.location_info.lng}`,
    address: data.requestData.address,
    search_address: data.requestData.location_info.address,
    country:  data.requestData.country,
    zipcode: data.requestData.pincode,
    city: data.requestData.city,
    state: data.requestData.state,
    house_number: data.requestData.house_number,
    area: data.requestData.area
  }
  

  console.log("update final= ", reqData);
  try {

    const result = await ApiService.put('/profile', JSON.stringify(reqData));

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    dispatch(setUserLocation({lat : data.requestData.location_info.lat, lng : data.requestData.location_info.lng}));
    dispatch(setUserAddress(data.requestData.city));
    setItem('userLoc', JSON.stringify({lat : data.requestData.location_info.lat, lng : data.requestData.location_info.lng}));
    setItem('userAddress', data.requestData.city);

    console.log("res chef details= ", result.data.data);
    return { resData: result.data.data, callback: data};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateUserRole = createAsyncThunk('user/updateUserRole', async (data, { rejectWithValue, dispatch, getState }) => {

  const state = getState();


  console.log('update info call= ', data.requestData, state.auth.user);

  const reqData = {
    is_chef: data.requestData.is_chef,
  }
  

  console.log("update final= ", reqData);
  try {

    const result = await ApiService.put('/update-role', reqData);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    // setItem('access_token', result.data.access_token);
    // setItem('refresh_token', result.data.refresh_token);
    // setItem('phone_number', result.data.phone_number);
    // setItem('is_chef', result.data.is_chef);
    // setItem('onboard_step', result.data.step);

    dispatch(setUser(result.data))
    dispatch(setActiveOnboardForm(result.data.step));
    dispatch(setCurrentChefStep(result.data.step));
    dispatch(setCurrentUserStep(result.data.user_step ? result.data.user_step : 0));

    console.log("res chef details= ", result.data);
    return { resData: result.data.data, callback: data, chefStep: result.data.step};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getFilteredChefDishList = createAsyncThunk('user/getFilteredChefDishList', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/dish?filter_by=dietary-preference&filter_value=${data}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getAllDishesByChefId = createAsyncThunk('user/getAllDishesByChefId', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/chef-dish/${data}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getFilteredDishesListByChefId = createAsyncThunk('user/getFilteredDishesListByChefId', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get(`/chef-dish/${data?.chefId}?filter_by=dietary-preference&filter_value=${data?.filterVal}`);

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const updateUserIdentity = createAsyncThunk('user/updateUserIdentity', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.put('/update-identity');

    if (result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getCity = createAsyncThunk('user/getCity', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/city');

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const getState = createAsyncThunk('user/getState', async (data, { rejectWithValue }) => {
  
  try {

    const result = await ApiService.get('/profile-pics');

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data.message);
    } 

    return result.data;
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

export const enrichChannelDataList = createAsyncThunk('user/enrichChannelDataList',async(data,{rejectWithValue}) => {
  try {
    let identities = data.map(d=>d.identity)
    const result = await ApiService.post('/profile-pics',{"identities":identities});

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data);
    } 
    // console.log("enrichChannelDataList",)
    return { channels:data.map(c=>{
      const channel = result.data.data.find((r)=>r.identity===c.identity);
      // console.log("c",channel);
      if(channel){
        c.profileUrl=channel.url;
        c.name=channel.name;
      }
      return c;
    }).filter(c=>{return undefined !== c.profileUrl ;}) };
    
  } catch (err) {
    return rejectWithValue({"error":err,channels:data});
  }
})

export const userVerificationForNewChat = createAsyncThunk('user/userVerificationForNewChat',async(data,{rejectWithValue}) => {
  try {
    let identities = [data]
    const result = await ApiService.post('/profile-pics',{"identities":identities});

    if (result.status !== 200 || result.data.error) {
      return rejectWithValue(result.data);
    } 
    return result.data;
    
  } catch (err) {
    return rejectWithValue({"error":err,channels:data});
  }
})



const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    setProfileOnBoardError: (state, action) => {
      state.error = action.payload;
    },

    setChefProfileDishReviewTab: (state, action) => {
      state.chefProfileDishReviewTab = action.payload;
    },

    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },

    setLocationFetchLoader: (state, action) => {
      state.locationFetchLoader = action.payload;
    },

    setUserAddress: (state, action) => {
      state.userAddress = action.payload;
    },

    setUserProfileImage: (state, action) => {
      state.userProfileImage = action.payload && action.payload.length > 0 ? action.payload : [];
    },

    setInitialLoadedReviewsCount: (state, action) => {
      state.initialLoadedReviewsCount += action.payload;
    },

    setInitialLoadedReviewsCountReset: (state) => {
      state.initialLoadedReviewsCount = 1;
    },

    setPublicViewFilterValue: (state, action) => {
      state.publicViewFilterValue = action.payload;
    },

    setChannel: (state, action) => {
      state.channel = action.payload;
    },

    setSelectedChefChatInfo: (state, action) => {
      state.selectedChefChatInfo = action.payload;
    },

    setSearchChefViewFilterValue: (state, action) => {
      state.searchChefViewFilterValue = action.payload;
    },

    setLogoutOpsUser: () => initialState,

    // setLogoutOpsUser: (state) => {
    //   state.createdUserData = null;
    //   state.userImage = null;
    //   state.userImageData = null;
    //   state.pubnub = null;
    //   state.channel = '';
    //   state.channelUsersList = [];
    //   state.channelFullList = [];
    // },

    setChatScreenActive: (state, action) => {
      state.chatScreenActive = action.payload;
    },

    setIsChatStarted: (state, action) => {
      state.isChatStarted = action.payload;
    },

    setChefDishesUserViewFilterValue: (state, action) => {
      state.chefDishesUserViewFilterValue = action.payload;
    },

    setPubnub: (state, action) => {
      state.pubnub = action.payload;
    },

    setChannelUsersList: (state, action) => {
      state.channelUsersList = action.payload;
    },

    setChannelFullList: (state, action) => {
      state.channelFullList = action.payload;
    },

    setFormattedChannelDataList: (state, action) => {
    //   state.formattedChannelDataList = action.payload;
    },

    setUserImageData: (state, action) => {
      state.userImageData = action.payload;
    },

    setIsUserProfileImageEmpty: (state, action) => {
      state.isUserProfileImageEmpty = action.payload;
    },

    setChefAllDishData: (state, action) => {
      state.chefAllDishData = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getChefAllDish.pending, (state) => {
          state.loading = true;
      })
      .addCase(getChefAllDish.fulfilled, (state, action) => {
          state.loading = false;
          state.chefAllDishData = action.payload;
      })
      .addCase(getChefAllDish.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(uploadUserProfilePic.pending, (state) => {
          state.loading = true;
      })
      .addCase(uploadUserProfilePic.fulfilled, (state, action) => {
          state.loading = false;
          state.userProfileImage = [action.payload];
      })
      .addCase(uploadUserProfilePic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(postUserProfilePic.pending, (state) => {
          state.loading = true;
      })
      .addCase(postUserProfilePic.fulfilled, (state, action) => {
          state.loading = false;
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(postUserProfilePic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getProfileImage.pending, (state) => {
          state.loading = true;
      })
      .addCase(getProfileImage.fulfilled, (state, action) => {
          state.loading = false;
          state.userImageData = action.payload;
          state.userProfileImage = [action.payload];
          // console.log("getProfileImage.fulfilled",action.payload)
          state.userImage = action.payload.profile_pic_url;
      })
      .addCase(getProfileImage.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getUserAllReviews.pending, (state) => {
          state.loading = true;
      })
      .addCase(getUserAllReviews.fulfilled, (state, action) => {
          state.loading = false;
          state.userReviews = state.initialLoadedReviewsCount > 1 ? [...state.userReviews, ...action.payload.data] : action.payload.data;
          state.userReviewsResponse = action.payload;
      })
      .addCase(getUserAllReviews.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(createUserProfile.pending, (state) => {
          state.loading = true;
      })
      .addCase(createUserProfile.fulfilled, (state, action) => {
          state.loading = false;
          state.createdUserData = action.payload.resData;
          setItem('user_step', 1);
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(createUserProfile.rejected, (state, action) => {
          
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getUserProfile.pending, (state) => {
          state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
          state.loading = false;
          state.createdUserData = action.payload;
          state.userLocation = {lat : action.payload.lat, lng : action.payload.long }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(updateUserProfile.pending, (state) => {
          state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
          state.loading = false;
          state.createdUserData = action.payload.resData;
          if (action.payload.callback) {
            action.payload.callback.callback();
          }
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(updateUserRole.pending, (state) => {
          state.loading = true;
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
          state.loading = false;
          // state.createdUserData = action.payload.resData;
          if (action.payload.callback) {
            action.payload.chefStep === 3 ? action.payload.callback.foodCallback() : action.payload.callback.chefOnboardCallback(); 
          }
      })
      .addCase(updateUserRole.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getFilteredChefDishList.pending, (state) => {
          state.loading = true;
      })
      .addCase(getFilteredChefDishList.fulfilled, (state, action) => {
          state.loading = false;
          state.chefAllDishData = action.payload;
      })
      .addCase(getFilteredChefDishList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getAllDishesByChefId.pending, (state) => {
          state.loading = true;
      })
      .addCase(getAllDishesByChefId.fulfilled, (state, action) => {
          state.loading = false;
          state.chefAllDishData = action.payload;
      })
      .addCase(getAllDishesByChefId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getFilteredDishesListByChefId.pending, (state) => {
          state.loading = true;
      })
      .addCase(getFilteredDishesListByChefId.fulfilled, (state, action) => {
          state.loading = false;
          state.chefAllDishData = action.payload;
      })
      .addCase(getFilteredDishesListByChefId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getCity.pending, (state) => {
          state.loading = true;
      })
      .addCase(getCity.fulfilled, (state, action) => {
          state.loading = false;
          state.cityList = action.payload;
      })
      .addCase(getCity.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })

      .addCase(getState.pending, (state) => {
          state.loading = true;
      })
      .addCase(getState.fulfilled, (state, action) => {
          state.loading = false;
          state.stateList = action.payload;
      })
      .addCase(getState.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload
      })
      .addCase(enrichChannelDataList.pending, (state) => {
          state.formattedChannelDataListLoading = true;
          state.loading = true;
          // console.log("enrichChannelDataList started");

      })
      .addCase(enrichChannelDataList.fulfilled, (state, action) => {
          state.loading = false;
          // console.log("enrichChannelDataList success",action.payload.channels);
          state.formattedChannelDataList = action.payload.channels;
          state.formattedChannelDataListLoading = false;
      })
      .addCase(enrichChannelDataList.rejected, (state, action) => {
          state.loading = false;
          state.formattedChannelDataList = [];
          state.formattedChannelDataListLoading = false;
          // console.log("enrichChannelDataList errored",action.payload);

      })
      .addCase(userVerificationForNewChat.pending, (state) => {
          state.loading = true;
          state.userPicIdentityPending =true
          // console.log("userVerificationForNewChat started");

      })
      .addCase(userVerificationForNewChat.fulfilled, (state, action) => {
          state.loading = false;
          state.userPicIdentityPending =false
          // console.log("userVerificationForNewChat success",action.payload.data);
          if(action.payload.data.length && action.payload.data.length>0 ){
            state.userPicIdentity = action.payload.data[0];
          }else{
            state.userPicIdentity = null;
          }
      })
      .addCase(userVerificationForNewChat.rejected, (state, action) => {
          state.loading = false;
          state.userPicIdentityPending =false
          state.userPicIdentity = null;
          // console.log("userVerificationForNewChat errored",action.payload);

      })

  }
});

export const {  
  setProfileOnBoardError,
  setAuthError, 
  setChefProfileDishReviewTab,
  setUserLocation,
  setLocationFetchLoader,
  setUserAddress,
  setUserProfileImage,
  setInitialLoadedReviewsCount,
  setPublicViewFilterValue,
  setChannel,
  setSearchChefViewFilterValue,
  setSelectedChefChatInfo,
  setLogoutOpsUser,
  setChatScreenActive,
  setIsChatStarted,
  setChefDishesUserViewFilterValue,
  setPubnub,
  setChannelUsersList,
  setChannelFullList,
  setFormattedChannelDataList,
  setInitialLoadedReviewsCountReset,
  setUserImageData,
  setIsUserProfileImageEmpty,
  setChefAllDishData
} = userSlice.actions;

export default userSlice.reducer;
