import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import { AspectRatio, Card, CardContent, Chip, Typography } from '@mui/joy';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import theme from '../../config/theme/themeVariable';
import defaultChef from '../../assets/imgs/defaultchef.jpg';

const ChefSuggestionCard = ({ chef }) => {
    const navigate = useNavigate();
    // console.log('search chef data ---------', chef);

    const handleChefDetailsDisplay = () => {
        navigate(`/search/chef-details/${chef?.restaurant_id}`, { state: { selectedChefData: chef }});
    }

    return (
        <>
            <Col className='w-full my-4' onClick={handleChefDetailsDisplay}>
                <Card
                    variant="plain"
                    orientation="horizontal"
                    sx={{
                        width: '100%',
                        padding: '16px 5px',
                        backgroundColor: '#fff'
                    }}
                >
                <Row className="w-full flex flex-row justify-start gap-4" style={{ cursor: 'pointer', flexFlow: 'nowrap'}} >
                    <Col className="pr-2">
                        <AspectRatio ratio="1" sx={{ width: 90, borderRadius: '10px' }}>
                            <img
                                src={chef?.profile_image_url || defaultChef}
                                srcSet={chef?.profile_image_url || defaultChef}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </Col>
                    <Col className="flex pl-2" style={{flexGrow:1}}>
                        <CardContent className="search-chef-res__content">
                            <Row className='w-full flex flex-row justify-between ' style={{ flexFlow: 'row'}}>
                                <Col className='flex flex-col gap-y-0.5'>
                                    <Typography 
                                        variant='h6' 
                                        fontSize={18} 
                                        fontWeight={500} 
                                        fontFamily={'OnestSemibold'} 
                                        color={theme.textColor}
                                        sx={{ textTransform: 'capitalize'}}
                                    >
                                        Chef {chef?.restaurant_name}
                                    </Typography>

                                    <Typography style={{maxWidth:'200px'}} variant='p' fontSize={14} fontWeight={500} fontFamily={'OnestRegular'} sx={{ color: theme.textSecondaryColor}}>
                                        {(chef?.tags.length !== 0 && chef?.tags.map(item => item.name).join(" | ")) || ''}
                                    </Typography>

                                    <Row className='flex gap-x-2 gap-y-1.5'>

                                        <Chip
                                            variant="solid"
                                            size="sm"
                                            sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5 }}
                                            className="px-1 mx-1"
                                        >
                                            <StarIcon fontSize='sm' /> {chef?.rating ? parseFloat(chef?.rating).toFixed(1) : '0.0' }
                                        </Chip>

                                        <Chip
                                            variant="solid"
                                            size="sm"
                                            sx={{ pointerEvents: 'none', backgroundColor: theme.colorShade5 }}
                                            className="px-1 mx-1"

                                        >
                                            <ChatIcon fontSize='sm' /> {chef?.reviews} {chef?.reviews === 1 ? 'Review' : 'Reviews'}
                                        </Chip>
                                    </Row>
                                </Col>
                                <Col>
                                    <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                                        <EastOutlinedIcon />
                                    </Typography>
                                </Col>
                            </Row>
                            
                        </CardContent>
                    </Col>
                </Row>
                </Card> 
            </Col>           
        </>
    )
}

export default ChefSuggestionCard;